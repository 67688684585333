import React from "react";
import "materialize-css/dist/css/materialize.min.css";
import { Card } from "react-materialize";

export default function CustomerCareCard(props) {
  return (
    <Card
      className="customer-care-card"
      header={
        <div className="card-image">
          <div
            className={`icon-container bg-light-${props.customerCardIconbg}`}
          >
            <i className={props.customerCardIcon}></i>
          </div>
        </div>
      }
      horizontal
    >
      <h5 className="card-title">{props.customerCardTitle}</h5>
      <p className="card-text">{props.customerCardText}</p>
    </Card>
  );
}
