import React, { useState, useEffect } from "react";
import Header from "../components/layout/header";
import {
  Container,
  Row,
  Col,
  TextInput,
  Button,
} from "react-materialize";
import { authAxios } from "../config/constant";
import { useNavigate } from "react-router-dom";
import Error from "../components/error";
import ModalCentered from "../components/modal-centered";
import logoFirstCitizenClub from "../assets/img/logo_ss-first-citizen-club.svg";

export default function FirstCitizenEnrollment() {
  const navigate = useNavigate();
  const [mobileNumber] = useState(localStorage.getItem("mobile_no"));
  const [becomeAMember, setBecomeAMember] = useState(false);
  // const [memberId] = useState(localStorage.getItem("memberId"));
  // const [tier] = useState(localStorage.getItem("tier"));
  // const [userTier, setUserTier] = useState(localStorage.getItem("tier"));
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  // const [firstName, setFirstName] = useState("");
  const [memberSince, setMamberSince] = useState("");
  const [dob, setDob] = useState("");
  const [loaderPopup, setLoaderPopup] = useState(false);

  const [userData, setUserData] = useState("");
  const nameError = "";
  const mobileError = "";
  const [emailError, setEmailError] = useState("");
  const tierError = "";
  const [dobError, setDobError] = useState("");
  const memberError = "";

  useEffect(() => {
    // debugger;
    // console.log(currentDateTime);
    setLoaderPopup(true);
    const payload = {
      // member_id: memberId
    };
    const headers = {
      AuthToken: localStorage.getItem("auth_key"),
      Otp: localStorage.getItem("otp")
    }
    if (headers.AuthToken) {
      authAxios
        .post("/get_gravty_profile", payload, { headers: headers })
        .then((res) => {
          if (res.data.code == 403) {
            localStorage.clear();
            navigate("/landing-page");
          }
          // debugger;
          else {
            // debugger;
            if (res.data.demographicGetResponse_v1) {
              console.log(res.data);
              setUserData(res.data.demographicGetResponse_v1.GetData);
              setDob(res.data.demographicGetResponse_v1.GetData?.dateOfBirth);
              setEmail(res.data.demographicGetResponse_v1.GetData?.email);
              const update = {
                "UpdateFirstName": res.data.demographicGetResponse_v1.GetData?.firstName,
                "UpdateLastName": res.data.demographicGetResponse_v1.GetData?.lastName,
                "UpdateEmail": res.data.demographicGetResponse_v1.GetData?.email,
                "UpdateMemberSince": res.data.demographicGetResponse_v1.GetData?.tierStartDate?.substring(0, 10),
                "UpdateDob": res.data.demographicGetResponse_v1.GetData?.dateOfBirth,
                "gender": res.data.demographicGetResponse_v1.GetData?.gender
              }
              localStorage.setItem("update", JSON.stringify(update));
              setLoaderPopup(false);
              // const update = {
              //   "UpdateFirstName": 
              // }
              // $("#firstname").val(res.data.demographicGetResponse_v1.GetData.cardName)
              // if (res.data != null) {
              //   SetOffers(res.data);
              //   setAvailable(true);
              // }
            }
            else{
              if (res.data.CustomerProfileResponse.errorMsg === "Member Not Found"){
                // setLoaderPopup(true);
                setBecomeAMember(true);
                setLoaderPopup(false);
              }
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
    else {
      navigate("/landing-page")
    }
  }, [mobileNumber]);

  // const handleFirstName = (e) => {
  //   setFirstName(e.target.value);
  //   localStorage.setItem("UpdateFirstName", e.target.value);
  //   if (e.target.value) {
  //     setNameError("");
  //   }
  // }

  // const handleLastName = (e) => {
  //   setLastName(e.target.value);
  //   localStorage.setItem("UpdateLastName", e.target.value);
  // }

  const handleEmail = (e) => {
    setEmail(e.target.value);
    localStorage.setItem("UpdateEmail", e.target.value);
    if (e.target.value) {
      setEmailError("");
    }
  }

  const handleMemberSince = (e) => {
    setMamberSince(e.target.value);
    localStorage.setItem("UpdateMemberSince", e.target.value);
  }
  const nextPage = () => {
    if (email && dob) {
      navigate("/profile-update")
    }

    if (!email) {
      setEmailError("Please fill the email")
    }
    if (!dob) {
      setDobError("Please select DOB")
    }
  }

  const handleDob = (e) => {
    setDob(e.target.value);
    localStorage.setItem("UpdateDob", e.target.value);
    if (e.target.value) {
      setDobError("");
    }
  }
console.log(userData)
  return (
    <div className="screen-wrap">
      {/* Header Section */}
      <Header
        className="app-header"
        headertype={0}
        logoShow={1}
        redirectLink={'/home'}
      />
      {/* Main Section */}
      {/* {tier != null ? ( */}
      <div className="main-wrap has-no-footer">
        <Container className="w-100 h-100">
          <div className="form-enroll">
            <div className="profile-header --is-sticky">
              <p className="text-center">
                Looks like you are not enrolled in
                <br />Shoppers Stop's <span>First Citizen</span>.
                <br />Please share some details to get started.
              </p>
            </div>
            <Row>
              <Col s={6}>
                <div className="form-group">
                  <label for="firstname" className="text-danger">
                    First Name<span>*</span>
                  </label>

                  <TextInput
                    id="firstname"
                    className="browser-default"
                    // placeholder={userData ? userData?.firstName : 'First Name'}
                    value={userData ? userData?.firstName : 'First Name'}
                    disabled
                  // onChange={handleFirstName}
                  />
                  <Error>{nameError}</Error>
                </div>
              </Col>
              <Col s={6}>
                <div className="form-group">
                  <label for="lastname" className="text-danger">
                    Last Name
                  </label>
                  <TextInput
                    id="lastname"
                    className="browser-default"
                    // placeholder={userData ? userData?.lastName : 'Last Name'}
                    value={userData ? userData?.lastName : 'Last Name'}
                    disabled
                  // onChange={handleLastName}
                  />
                </div>
              </Col>
              <Col s={12}>
                <div className="form-group" >
                  <label for="phnumber" className="text-danger">Mobile<span>*</span></label>
                  <TextInput
                    id="phnumber"
                    className="browser-default"
                    // placeholder={userData ? userData?.mobile : 'Enter your mobile no'}
                    value={userData ? userData?.mobile_number : 'Enter your mobile no'}
                    disabled
                    typemode="numeric"
                  />
                  <Error>{mobileError}</Error>
                </div>
              </Col>
              <Col s={12}>
                <div className="form-group">
                  <label for="email">
                    Email ID
                  </label>
                  <TextInput
                    id="email"
                    className="browser-default"
                    placeholder={userData ? userData?.email : "Enter your email id"}
                    value={email}
                    onChange={handleEmail}
                  />
                  <Error>{emailError}</Error>
                </div>
              </Col>
              <Col s={6}>
                <div className="form-group">
                  <label for="firstname">
                    Tier<span>*</span>
                  </label>

                  <TextInput
                    id="firstname"
                    className="browser-default"
                    // placeholder={userData ? userData?.firstName : 'First Name'}
                    placeholder={userData ? userData?.cardDescription : 'Tier'}
                    disabled
                  // onChange={handleFirstName}
                  />
                  <Error>{nameError}</Error>
                </div>
              </Col>
              {/* <Col s={12}>
                <div className="form-group">
                  <label for="lastname">
                    Tier<span>*</span>
                  </label>
                  <select
                    browserDefault
                    id="mother_tongue"
                    // defaultValue={userTier ? userTier : "Select"}
                    // placeholder={userTier ? userTier : "Select"}
                    options={{
                      classes: "",
                      dropdownOptions: {
                        alignment: "left",
                        autoTrigger: true,
                        closeOnClick: true,
                        constrainWidth: true,
                        coverTrigger: true,
                        hover: false,
                        inDuration: 150,
                        onCloseEnd: null,
                        onCloseStart: null,
                        onOpenEnd: null,
                        onOpenStart: null,
                        outDuration: 250,
                      },
                    }}
                    value=""
                  > */}
                    {/* <option disabled selected>
                      {userTier ? userTier : "Select"}
                    </option> */}
                    {/* <option selected>
                      {userData ? userData?.cardDescription : "Select"}
                    </option>
                    <option value="1">SILVER EDGE</option>
                    <option value="2">GOLDEN GLOW</option>
                    <option value="3">PLATINUM AURA</option>
                    <option value="4">FIRST CITIZEN BLACK</option>
                  </select>
                  <Error>{tierError}</Error>
                </div>
              </Col> */}
              <Col s={6}>
                <div className="form-group">
                  <label for="firstname" className="text-danger">
                    Date Of Birth<span>*</span>
                  </label>
                  <TextInput
                    type="date"
                    id="firstname"
                    className="browser-default"
                    // defaultValue={userData ? formatDate(userData?.dateOfBirth) : ""}
                    // placeholder= {userData ? userData?.dateOfBirth : ""}
                    value={dob}
                    // onChange={handleDob}
                  />
                  <Error>{dobError}</Error>
                </div>
              </Col>
              <Col s={6}>
                <div className="form-group">
                  <label for="firstname">
                    Member Since<span>*</span>
                  </label>
                  <TextInput
                    id="firstname"
                    className="browser-default"
                    placeholder={userData ? userData?.tierStartDate?.substring(0, 10) : "Member Since"}
                    // value={memberSince}
                    disabled
                    // onChange={handleMemberSince}
                  />
                  <Error>{memberError}</Error>
                </div>
              </Col>
            </Row>
            <Button node="button" className="btn-primary btn-fluid" onClick={() => nextPage()}>
              Continue <i className="icon-arrow-right ml-10"></i>
            </Button>
            <div className="user-guide">
              <ul>
                <li>Fields Marked In * Are Mandatory</li>
                <li>Fields <strong>Highlighted</strong> Cannot Be Edited</li>
                <li>Fields In Update Profile Box Can Be Editable</li>
                <li>Fields In Profile Box To Be Displayed</li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
      {/* ) : (
        <div className="main-wrap">
          <div className="d-table w-100 h-100">
            <div className="d-table-cell h-100 align-middle">
              <div className="coming-soon-block">
                <h6 className="coming-soon-text">
                  Coming <br />
                  Soon
                </h6>
              </div>
            </div>
          </div>
        </div>
      )} */}
      {becomeAMember && <ModalCentered
        id="modal__user-enroll"
        className="modal__user-enroll"
        closeIcon={
          <a
            href="javascript:void(0)"
            waves="light"
            className="modal-close icon-cross icon-cross-dark"
            onClick=""
          ></a>
        }
        header={
          <div className="modal-header">
            <img className="brand-logo" src={logoFirstCitizenClub} />
          </div>
        }
        contentBody={
          <div className="modal-body">
            <h4>Hi There,</h4>
            <p>You are not a Shoppers Stop First Citizen Club member. Kindly contact store person</p>
          </div>
        }
        footer={
          <div className="modal-footer">
            <Button
              node="button"
              waves="light"
              className="btn-primary btn-fluid"
              onClick=""
            >
              Done
              {/* <i className="icon-arrow-right ml-10"></i> */}
            </Button>
          </div>
        }
      />}
      {loaderPopup ? (<div id="loading" class="loadme-overlay">
        <div class="loadme-circular"></div>
        <p class="loadme-text" id="st"></p>
      </div>
      ) : (
        ""
      )}
    </div>
  );
}
