import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "materialize-css/dist/css/materialize.min.css";
import Header from "../components/layout/header.js";
import Footer from "../components/layout/footer.js";
// import LoyaltyRewardCard from "../components/loyalty-points-card";
// import IconStarGold from "../assets/img/icons/icon_star-gold.svg";
// import BannerSilver from "../assets/img/banner_silver.png";
// import BannerGold from "../assets/img/banner_golden.png";
// import BannerPlatinum from "../assets/img/banner_platinum.png";
// import BannerBlack from "../assets/img/banner_black.png";
// import { useNavigate } from "react-router-dom";
// import moment from "moment";
export default function LoyaltyPoints() {
  const navigate = useNavigate();
  useEffect(() => {
    const headers = {
      AuthToken: localStorage.getItem("auth_key"),
      Otp: localStorage.getItem("otp")
    }
    if (headers.AuthToken) {
      console.log("enter")
    }
    else {
      navigate("/landing-page")
    }
  });
  // const navigate = useNavigate();

  // const [tier] = useState(localStorage.getItem("tier"));
  // const [points] = useState(localStorage.getItem("points"));
  // const [userName] = useState(localStorage.getItem("firstname"));
  // const [tierExpeiry] = useState(
  //   moment(new Date(localStorage.getItem("tier_expeiry"))).format(
  //     "DD/MM/YYYY HH:mm:ss"
  //   )
  // );
  // const [tierClass, setTierClass] = useState("");
  // const [tierImg, setTierImage] = useState("");
  // useEffect(() => {
  //   if (tier === "FIRST CITIZEN BLACK") {
  //     setTierImage(BannerBlack);
  //     setTierClass("tier-badge-black");
  //   }
  //   if (tier === "PLATINUM AURA") {
  //     setTierImage(BannerPlatinum);

  //     setTierClass("tier-badge-platinum");
  //   }
  //   if (tier === "GOLDEN GLOW") {
  //     setTierImage(BannerGold);

  //     setTierClass("tier-badge-gold");
  //   }
  //   if (tier === "SILVER EDGE") {
  //     setTierClass("tier-badge-silver");
  //     setTierImage(BannerSilver);
  //   }
  // });

  return (
    <div className="screen-wrap">
      {/* Header Section */}
      <Header className="app-header" headertype={1} />
      {/* Main Content */}
      <div className="main-wrap">
        {/* Coming Soon */}
        <div className="d-table w-100 h-100">
          <div className="d-table-cell h-100 align-middle">
            <div className="coming-soon-block">
              <h6 className="coming-soon-text">Coming <br />Soon</h6>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="main-wrap">
          <Container>
            <div className="heading-section">
              <h2 className="heading-title">Loyalty Rewards 🏆</h2>
              <p className="heading-desc">
                Here, you can see all the points you’ve collected from Shoppers
                Stop.
              </p>
            </div>
            <div className="text-center">
              <span className={`tier-badge ${tierClass} mb-10 `}>
                <img src={IconStarGold} alt={"img"} /> {tier}
              </span>
            </div>
            <div className="tier-banner">
              <img className="tier-banner-img" src={tierImg} alt="" />
              <div className="tier-points-details">
                <h6 className="tier-points__label">Available Points Value</h6>
                <p className="tier-points__value">
                  <span>{`${points}`}</span>
                </p>
                <p className="tier-points__value">
                <span>{points}</span> = ₹ 100.14
              </p>
              </div>
              <div className="tier-user-info">
                <h5 className="user-name">{userName}</h5>
                <small className="expiry-text">
                  Expiry Date: <span> {tierExpeiry}</span>
                </small>
              </div>
            </div>
            <div className="tier-banner">
            <img className="tier-banner-img" src={BannerGold} alt="" />
            <div className="tier-points-details">
              <h6 className="tier-points__label">Available Points</h6>
              <p className="tier-points__value">
                <span>1014</span> = ₹ 100.14
              </p>
            </div>
            <div className="tier-user-info">
              <h5 className="user-name">Sasikumar Rajendran</h5>
              <small className="expiry-text">
                Expiring this month: <span>40 Points</span>
              </small>
            </div>
          </div>
          </Container>
          <Container>
          <LoyaltyRewardCard
            LoyaltyPointsIcon="icon-card__tour"
            LoyaltyPointsTitle="You earned 54 points"
            LoyaltyPointsText="For visiting the “Take a Tour” section"
            LoyaltyPoints="+54"
          />
          <LoyaltyRewardCard
            LoyaltyPointsIcon="icon-card__parking"
            LoyaltyPointsTitle="You earned 150 points"
            LoyaltyPointsText="As your Parking Benefit"
            LoyaltyPoints="+150"
          />
        </Container>
        </div> */}

      {/* Footer Section */}
      <Footer />
    </div>
  );
}
