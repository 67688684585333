import React, { useState, useEffect } from "react";
import Header from "../components/layout/header";
import { Container, TextInput, Button } from "react-materialize";
import { ClientJS } from "clientjs";
import axios from "axios";
import { API_BASE_URL } from "../config/constant";
import { useNavigate } from "react-router-dom";
import Error from "../components/error";
import { decryptData } from "./common-func";



export default function Verification() {
  const navigate = useNavigate();
  const client = new ClientJS();

  const [mobileNumber] = useState(localStorage.getItem("mobile_no"));
  const [recivedOTP, setOTP] = useState("");

  // const [sessionId, setSessionId] = useState(
  //   localStorage.getItem("session_id")
  // );

  const [formErrors, setFormErros] = useState("");

  const [incorrectOTP, setIncorrectOTP] = useState(false);

  const [resendCount, setResendCound] = useState(0);

  const [tryCount, setTryCount] = useState(0);

  const [retTryCount] = useState(3);
  const [loaderInButton, setLoaderInButton] = useState(false);

  // otp resend timer
  const [time, setTime] = useState(60);

  // disable resend otp
  const [diableResend, setDiableResend] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("timer") !== null) {
      if (localStorage.getItem("timer") > 0) {
        setTime(localStorage.getItem("timer"));
      }
    }
  }, []);
  useEffect(() => {
    let timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          localStorage.removeItem("timer");
          setDiableResend(false);
          return 0;
        } else {
          setTime(time - 1);
          localStorage.setItem("timer", time - 1);
          return time - 1;
        }
      });
    }, 1000);
  }, [resendCount]);

  const formatTime = (time) =>
    `${String(Math.floor(time / 60)).padStart(2, 0)}:${String(
      time % 60
    ).padStart(2, "0")}`;

  // resend otp
  function otpResendHandler() {
    setResendCound(resendCount + 1);
    // console.log(resendCount);
    setDiableResend(true);
    setTime(60);
    if (resendCount >= 3) {
      setResendCound(0);
      localStorage.setItem("requestTimeout", true);
      navigate("/landing-page");
    } else {
      const data = {
        mobile_number: decryptData(mobileNumber, 1),
      };

      axios
        .post(API_BASE_URL + "/verification/otp", data)
        .then((res) => {
          localStorage.setItem("session_id", res.data.session_id);
          console.log(res.data.session_id);
        })
        .catch((err) => {
          alert(err);
        });
    }
  }

  // otp auto load
  // useEffect(() => {
  //   // console.log("auto read");
  //   if ("OTPCredential" in window) {
  //     const ac = new AbortController();

  //     navigator.credentials
  //       .get({
  //         otp: { transport: ["sms"] },
  //         signal: ac.signal,
  //       })
  //       .then((otp) => {
  //         console.log(otp.code);
  //         setOTP(otp.code);

  //         ac.abort();
  //       })
  //       .catch((err) => {
  //         const effect = setTimeout(() => {
  //           ac.abort();
  //           console.log(err);
  //         }, 1000);
  //       });
  //   }
  // }, []);

  // handle change in otp input
  function handleChange(e) {
    // debugger;
    // const { value, name } = e.target;

    // setValue({
    //   ...ssnValues,
    //   [name]: value,
    // });
    setOTP(e.target.value);
    localStorage.setItem('otp', e.target.value);
  }

  // verfication

  function verificationHandler() {
    // console.log(recivedOTP);
    // console.log(typeof recivedOTP);
    if (recivedOTP !== "") {
      if (recivedOTP.length < 4 || recivedOTP.length > 4) {
      } else {
        if (isNaN(parseInt(recivedOTP)) === true) {
          setIncorrectOTP(true);
          
          setFormErros("Enter Valid OTP");
          setLoaderInButton(false);
          return;
        }
        const data = {
          session_id: localStorage.getItem("session_id"),
          otp: recivedOTP,
        };
        const headers = {
          AuthToken: localStorage.getItem("auth_key"),
          Otp: localStorage.getItem("otp")
        }
        // console.log(data);
        if (headers.AuthToken) {
          setLoaderInButton(true);
          axios
            .post(API_BASE_URL + "/verification/otp/status", data, { headers: headers })
            .then((res) => {
              if (res.data.code == 403) {
                localStorage.clear();
                navigate("/landing-page");
              }
              // console.log(res);
              // debugger;
              // otp invalid
              else {
                if (res.data.verified_status !== "True") {
                  setTryCount(tryCount + 1);
                  if (tryCount >= 3) {
                    setTryCount(0);
                    localStorage.setItem("requestTimeout", true);
                    navigate("/landing-page");
                  } else {
                    setIncorrectOTP(true);
                    // debugger;
                    
                    setFormErros(
                      "The OTP entered is incorrect. Please try again. You have " +
                      (retTryCount - tryCount) +
                      " more attempt."
                    );
                    setLoaderInButton(false);
                    // console.log(retTryCount - tryCount);
                    if (retTryCount - tryCount === 0) {
                      setTryCount(0);
                      localStorage.setItem("requestTimeout", true);
                      navigate("/landing-page");
                    }
                  }
                } else {
                  // localStorage.setItem("tour", "false");
                  // localStorage.setItem("access_token", res.data.access_token);
                  // localStorage.setItem("AuthToken", res.data.AuthToken);
                  // localStorage.setItem("refresh_token", res.data.refresh_token);
                  // localStorage.setItem("user_id", res.data.user_id);
                  localStorage.setItem("newLogin", true);
                  // localStorage.removeItem("mobile_no");

                  mobileDetails();
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
        else {
          navigate("/landing-page")
        }
      }
    } else {
      setIncorrectOTP(true);
      setFormErros("Enter Valid OTP");
      setLoaderInButton(false);
    }
  }

  function setAuthToken(authToken) {
    var date_ = new Date();
    date_.setTime(date_.getTime() + 2 * 24 * 60 * 60 * 1000);
    // var expires = "expires=" + date_.toGMTString();
    // document.cookie = "authToken = " + authToken + ";##SEPERATOR###" + expires;
    // document.cookie = 'authToken=authToken + "##SEPERATOR###"+`expires`; path=/; secure; max-age=3600'
    document.cookie = 'authToken=authToken + "##SEPERATOR###"+`expires`; path=/; secure; max-age=3600; SameSite=true; HttpOnly=true';

    localStorage.setItem("Verification", true);
  }

  function mobileDetails() {
    var keyId = localStorage.getItem("key_id");
    setAuthToken(mobileNumber);
    var mobileUrl = API_BASE_URL + "/mobiledetails";
    var date = new Date();
    var current_date = date.getDate();
    var current_month = date.getMonth() + 1;
    var current_year = date.getFullYear();
    // var hour = date.getHours();
    // var minutes = date.getMinutes();
    // var seconds = date.getSeconds();
    // var time = hour + ":" + minutes + ":" + seconds;
    date = current_date + "-" + current_month + "-" + current_year + ",";
    var postData = {
      browser: client.getBrowser(),
      browserVersion: client.getBrowserVersion(),
      os: client.getOS(),
      osVersion: client.getOSVersion(),
      keyId: keyId,
    };
    const headers = {
      AuthToken: localStorage.getItem("auth_key"),
      Otp: localStorage.getItem("otp")
    }
    // debugger;
    // if (keyId === null || keyId === "null") {
    //   localStorage.setItem("first_login", "missed_call");
    // } else {
    //   localStorage.setItem("first_login", true);
    // }
    // console.log(postData);
    if (headers.AuthToken) {
      axios
        .post(mobileUrl, postData, { headers: headers })
        .then((res) => {
          console.log(res)
          if (res.data.code === 403) {
            navigate("/landing-page");
          }
          else {
            if (res.data !== "") {
              localStorage.removeItem("timer");
              setLoaderInButton(false);
              navigate("/home");
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    else {
      navigate("/landing-page")
    }
  }

  function onChangeNUmberClickHandler() {
    localStorage.removeItem("timer");
    navigate("/landing-page");
  }

  return (
    <div className="screen-wrap">
      {/* Header Section */}
      <Header
        className="app-header"
        headertype={0}
        logoShow={1}
        redirectLink={'/home'}
      />

      <div className="main-wrap has-no-footer position-relative">
        <Container>
          <div className="app-unlock">
            <div className="app-unlock__header">
              <h1 className="app-unlock__title">Phone Verification</h1>
              <p className="app-unlock__desc">
                We have sent an OTP on your number to
              </p>
              <p className="user-mobile-no">
                (+91) {decryptData(mobileNumber, 1)}{" "}
                <Button onClick={onChangeNUmberClickHandler} flat>
                  Change
                </Button>
              </p>
            </div>
            <div className="app-unlock__body">
              <div className="mobile-no-block">
                <div className="mobile-no">
                  <TextInput
                    id="mobile-no"
                    label="Enter OTP"
                    value={recivedOTP}
                    onChange={handleChange}
                    maxLength={4}
                  ></TextInput>
                </div>
                {incorrectOTP ? <Error>{formErrors}</Error> : ""}
              </div>
              <Button
                node="button"
                waves="light"
                className="btn-primary btn-fluid text-uppercase"
                onClick={verificationHandler}
              >
                {loaderInButton && <div className="spinner-border mr-10"></div>}Verify Now <i className="icon-arrow-right ml-10"></i>
              </Button>

              <h6 className="otp-countdown-timer">
                <strong>{formatTime(time)}</strong> Sec left
              </h6>
              <div className="otp-resend">
                <p>Didn’t receive a code?</p>
                <Button
                  node="button"
                  flat
                  disabled={diableResend}
                  onClick={otpResendHandler}
                >
                  Resend a new code
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
