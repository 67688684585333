import React, { useEffect, useState } from "react";
import logoFirstCitizenClub from "../assets/img/logo_ss-first-citizen-club.svg";
import { Container } from "react-materialize";
import { useSearchParams, useNavigate } from "react-router-dom";
import { ClientJS } from "clientjs";
import { API_BASE_URL } from "../config/constant";
import axios from "axios";


export default function Splash() {
  const client = new ClientJS();
  const navigate = useNavigate();

  const [mobileNumber] = useState(localStorage.getItem("mobile_no"));
  const [verificationStatus] = useState(localStorage.getItem("Verification"));
  const [searchParams] = useSearchParams();
  let storekey = searchParams.get("id");
  if (storekey !== null) {
    if (storekey.startsWith("Ss")) {
      localStorage.setItem("key_id", storekey);
    }
  }
  useEffect(() => {
    if (verificationStatus || verificationStatus === "true") {
      var keyId = localStorage.getItem("key_id");
      var mobileUrl = API_BASE_URL + "/mobiledetails";
      var date = new Date();
      var current_date = date.getDate();
      var current_month = date.getMonth() + 1;
      var current_year = date.getFullYear();
      date = current_date + "-" + current_month + "-" + current_year + ",";
      var postData = {
        browser: client.getBrowser(),
        browserVersion: client.getBrowserVersion(),
        os: client.getOS(),
        osVersion: client.getOSVersion(),
        keyId: keyId,
      };
      const headers = {
        AuthToken: localStorage.getItem("auth_key"),
        Otp: localStorage.getItem("otp")
      }
      // console.log(postData);
      if (headers.AuthToken) {
        axios
          .post(mobileUrl, postData, { headers: headers })
          .then((res) => {
            // console.log(res);
            if (res.data.code == 403) {
              localStorage.clear();
              navigate("/landing-page");
            }
            else {
              if (res.data !== "") {
                localStorage.setItem("user_details", JSON.stringify(res.data));
                navigate("/home");
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      else {
        navigate("/landing-page")
      }
    } else {
      setTimeout(() => {
        navigate("/landing-page");
      }, 3000);
    }
  });
  return (
    <div className="screen-wrap splash-screen">
      <Container className="splash-screen-container">
        <div className="app-intro">
          <img className="app-logo" src={logoFirstCitizenClub} alt="Shoppersstop" />
          <p className="lead">
            Hi there!
            <br />
            We’ve got exclusive offers, exciting
            <br />
            benefits and fun, new ways to
            <br />
            redeem your reward points.
          </p>
          <h6>Let’s get started.</h6>
        </div>
      </Container>
    </div>
  );
}
