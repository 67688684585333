import { useSearchParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import CryptoJS from 'crypto-js';



// export default function NavigatePage() {
// const [encrptedData] = useState("");
// const [decrptedData] = useState("");
//     // const navigate = useNavigate();

//     // navigate("/landing-page");
const secretPass = "XkhZG4fW2t2W";

export function encryptData(text) {
  const data = CryptoJS.AES.encrypt(
    text,
    secretPass
  ).toString();
  // setEncrptedData(data);
  return data;
};
// const encryptedData = CryptoJS.AES.encrypt("Data", "Secret Passphrase")
export function decryptData(text, type) {
  // debugger
  if (type == 1) {
    const bytes = CryptoJS.AES.decrypt(text, secretPass);
    const data = bytes.toString(CryptoJS.enc.Utf8);
    // const data = bytes.toString(CryptoJS.enc.Utf8);
    // setDecrptedData(data);
    return data;
  }
  else {
    const bytes = CryptoJS.AES.decrypt(text, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    // const data = bytes.toString(CryptoJS.enc.Utf8);
    // setDecrptedData(data);
    return data;
  }
};
  // }
