import React from "react";
import "materialize-css/dist/css/materialize.min.css";
import { Card } from "react-materialize";
import IconVoucher from "../assets/img/icons/icon_voucher.svg";

export default function ExclusiveDealCard(props) {
  return (
    <Card
      className={"exclusive-deal-card"}
      header={
        <div className="card-header">
          <div className="card-icon">
            <img src={IconVoucher} alt="" />
          </div>
        </div>
      }
    >
      <div className="brand-logo">
        <img src={props.ExclusiveDealBrandLogo} alt="" />
      </div>
      <small>Valid till: <strong>{props.expiryDate}</strong></small>
      {props.isRedeemed ? <div className="card-overlay">
        <div className="badge-redeem">Redeemed</div>
      </div>
      : ""}
    </Card>
  );
}
