import React from "react";

export default function TransactionCard(props) {
    return (
        <div className="transaction-card">
            <div className="card-body">
                <ul>
                    <li>
                        <label>Date</label>
                        <small>{props.expiryDate}</small>
                    </li>
                    <li>
                        <label>Total Bill Amount -</label>
                        <span className="text-primary">`{props.billAmount}</span>
                    </li>
                </ul>
                <div className="transaction-points">
                    <div className="transaction-points__earned">Points Earned - <span className="text-success">{props.earnedPoints}</span></div>
                    <div className="transaction-points__redeemed">Points Redeemed - <span className="text-primary">{props.redeemedPoints}</span></div>
                </div>
            </div>
        </div>
    )
}