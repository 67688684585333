import React from "react";
import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";
// import ModalCloseIcon from "../assets/img/icons/icon_close.svg";

export default class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const options = {
      inDuration: 250,
      outDuration: 250,
      opacity: 0.75,
      dismissible: true,
      startingTop: "4%",
      endingTop: "0",
    };
    let modalin = M.Modal.init(this.Modal, options);
    modalin.open();
  }
  render() {
    return (
      <div
        ref={(Modal) => {
          this.Modal = Modal;
        }}
        id={this.props.id}
        className={`modal modal__centered ${this.props.className}`}
      >
        <div className="d-table w-100 h-100">
          <div className="d-table-cell align-middle">
            <div className="modal-content">
              {this.props.closeIcon}
              {this.props.header}
              {this.props.contentBody}
              {this.props.footer}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
