import React from "react";
import "materialize-css/dist/css/materialize.min.css";
import Header from "../components/layout/header.js";
import Footer from "../components/layout/footer.js";
import CustomerCareCard from "../components/customer-care-card";
import { Container } from "react-materialize";
import IconFacebook from "../assets/img/icons/icon_facebook.svg";
import IconInstagram from "../assets/img/icons/icon_instagram.svg";
import IconTwitter from "../assets/img/icons/icon_twitter.svg";

export default function CustomerCare() {
  return (
    <div className="screen-wrap">
      {/* Header Section */}
      <Header className="app-header" headertype={1} />
      {/* Main Content */}
      <div className="main-wrap">
        <Container>
          <div className="heading-section text-center">
            <h2 className="heading-title mb-5">Hello, How can we help you?</h2>
            <p className="heading-desc">
              Didn't find the answer you were looking for? <br />
              Contact Customer Care !
            </p>
          </div>

          {/* Customer Care Card */}
          <a href="tel:1800-419-6648">
            <CustomerCareCard
              customerCardIcon="icon-card_mobile"
              customerCardIconbg="yellow"
              customerCardTitle="Call us at Customer Care no."
              customerCardText="1800-419-6648"
            />
          </a>
          <a href="mailto:customercare@shoppersstop.com">
            <CustomerCareCard
              customerCardIcon="icon-card_envelope"
              customerCardIconbg="pink"
              customerCardTitle="Email us at"
              customerCardText="customercare@shoppersstop.com"
            />
          </a>
          <CustomerCareCard
            customerCardIcon="icon-card_shop"
            customerCardIconbg="ash"
            customerCardTitle="Store Operational Timings"
            customerCardText="11:00AM to 09:30PM"
          />

          {/* Footer Section */}
          <div className="contact-us-section">
            <h2>Follow Us!</h2>
            <p>Stay up to date with latest trends</p>
            <div className="social-icons">
              <a
                href="https://www.facebook.com/shoppersstopindia"
                target="_blank"
              >
                <img src={IconFacebook} alt="facebook-icon" />
              </a>
              <a href="https://instagram.com/shoppers_stop/" target="_blank">
                <img src={IconInstagram} alt="instagram-icon" />
              </a>
              <a href="https://twitter.com/shoppersstop" target="_blank">
                <img src={IconTwitter} alt="twitter-icon" />
              </a>
            </div>
          </div>
        </Container>
      </div>
      {/* Footer Section */}
      <Footer />
    </div>
  );
}
