import axios from "axios";

// export const API_BASE_URL = "https://shoppersstop.saturam.com/api";
export const API_BASE_URL = "https://metro.saturam.com/api";
// export const API_BASE_URL = "http://127.0.0.1:5000/api";



export const authAxios = axios.create({
  baseURL: API_BASE_URL,
});