import { useState } from "react";
import { Navigate } from "react-router-dom";

function AppRoute({ children }) {
  const [verify] = useState(localStorage.getItem("Verification"));
  // const [verify] = useState(true);
  // console.log(verify);
  return verify === "true" ? children : <Navigate to="/landing-page" />;
  // return children;
}
export default AppRoute;
