import React from "react";
import "materialize-css/dist/css/materialize.min.css";
import { Card } from "react-materialize";

export default function UserProfileCard(props) {
  return (
    <Card
      className={`user-profile-card ${props.className}`}
      header={<div className={`user-profile__icon ${props.profileIcon}`}></div>}
      horizontal
    >
      <h5 className="user-profile__label">{props.profileLabel}</h5>
      <p className="user-profile__value">{props.profileValue}</p>
    </Card>
  );
}
