import React from "react";
import "materialize-css/dist/css/materialize.min.css";
import { Card } from "react-materialize";
import OfferBadgeGray from "../assets/img/icons/icon_offer-badge-gray.svg";

export default function OfferCard(props) {
  return (
    <Card
      className={`offer-card ${props.offerCardColor}`}
      header={
        <div className="card-image">
          <div className="offer-badge-icon">
            <img src={OfferBadgeGray} alt="offer-percentage" />
          </div>
        </div>
      }
      horizontal
    >
      <h4 className="card-title">{props.offerCardTitle}</h4>
      <p className="card-text">{props.offerCardText}</p>
      <small>Valid for: {props.offerCardValidity}</small>
    </Card>
  );
}
