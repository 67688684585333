import React, { useEffect, useState, useRef } from "react";
import "materialize-css/dist/css/materialize.min.css";
import Header from "../components/layout/header.js";
import Footer from "../components/layout/footer.js";
import { Container, Button } from "react-materialize";
import UserProfileCard from "../components/user-profile-card.js";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { format } from 'date-fns';

export default function Profile() {

  const [userName, setUserName] = useState(localStorage.getItem("firstname"));
  const [firstName, setfirstName] = useState(localStorage.getItem("Fname"));
  const [lastName, setlastName] = useState(localStorage.getItem("Lname"));
  const [dateOfBirth, setdateOfBirth] = useState(localStorage.getItem("DOB"));
  const [location, setlocation] = useState(localStorage.getItem("location"));
  const [mobileNumber, setMobileNumber] = useState(localStorage.getItem("mobile_no"));
  // const [userData] = useState(localStorage.getItem("userData"));
  const [memberData, setMemberData] = useState(localStorage.getItem("Member"));

  // console.log("DATA",userData)

  const navigate = useNavigate();

  function updateProfileRedirect(){
    navigate('/first-citizen-enrollment');
  }


  const formatDate = (inputDate) => {
    debugger;
    if (inputDate != "undefined" && inputDate != null){
      return format(new Date(inputDate), 'dd MMMM yyyy');
    }
    else{
      return;
    }
  };
  // if (memberData == "Member Not Found"){
  //   navigate("/coming-soon")
  // }
  return (
    <div className="screen-wrap">
      {/* Header Section */}
      <Header className="app-header" headertype={1} />
      {/* Main Content */}
      {memberData != "Member Not Found" ? (
        <div className="main-wrap">
          <Container>
            <div className="profile-heading-section">
              <div className="profile-heading-caption">
                <h6>Basic Info</h6>
              </div>
              <div className="profile-heading-action hide">
                <Button
                  node="button"
                  waves="light"
                  className="btn btn-sm btn-secondary" 
                  onClick={updateProfileRedirect}
                >
                  Edit Profile
                </Button>
              </div>
            </div>
            <UserProfileCard
              profileIcon="icon-profile__user"
              profileLabel="First Name"
              profileValue={firstName}
            />
            <UserProfileCard
              profileIcon="icon-profile__user"
              profileLabel="Last Name"
              profileValue={lastName}
            />
            <UserProfileCard
              profileIcon="icon-profile__card"
              profileLabel="Name On Card"
              profileValue={userName}
            />
            {/* <UserProfileCard
              className="is-verified"
              profileIcon="icon-profile__email"
              profileLabel="Email"
              profileValue="sasi@saturam.com"
            /> */}
            <UserProfileCard
              profileIcon="icon-profile__phone"
              profileLabel="Phone"
              profileValue={`+91 ${mobileNumber}`}
            />
            <UserProfileCard
              profileIcon="icon-profile__calender"
              profileLabel="Date Of Birth"
              profileValue={formatDate(dateOfBirth)}
            />
            <UserProfileCard
              profileIcon="icon-profile__location"
              profileLabel="Location"
              profileValue={location}
            />
          </Container>
        </div>
        ): (
          <div className="main-wrap">
            <div className="d-table w-100 h-100">
              <div className="d-table-cell h-100 align-middle">
                <div className="coming-soon-block">
                  <h6 className="coming-soon-text">
                    Coming <br />
                    Soon
                  </h6>
                </div>
              </div>
            </div>
          </div>
        )}
      {/* Footer Section */}
      <Footer />
    </div>
  );
}
