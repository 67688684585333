import React from "react";

export default function HomeNavCard(props) {
    return (
        <div className={"card card-light-" + props.cardColor}>
            <div className="card-body">
                <div className="card-icon">
                    <img src={props.cardIcon} alt="" />
                </div>
                <h6 className="card-label" dangerouslySetInnerHTML={{ __html: props.cardLabel }} />
            </div>
        </div>
    )
}