import React, { useEffect, useState } from "react";
import "materialize-css/dist/css/materialize.min.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Header from "../components/layout/header";
import Footer from "../components/layout/footer";

import ModalAppTour from "../components/modal-app-tour";
import ModalCentered from "../components/modal-centered";
import Loader from "../components/loader";
// import CategoryCard from "../components/category-card";
// import CardSplOffer from "../components/spl-offer-card";
import slider1 from "../assets/img/slide/slider1.jpg";
import slider2 from "../assets/img/slide/slider2.jpg";
// import SplOffer1 from "../assets/img/spl-offer-1.svg";
import IconOffer from "../assets/img/icons/icon_offer-dark.svg";
import IconTrophy from "../assets/img/icons/icon_trophy.svg";
import { Row, Col, Button } from "react-materialize";
import logoFirstCitizenClub from "../assets/img/logo_ss-first-citizen-club.svg";

// import logoBrand from "../assets/img/logo_brand.svg";
import IconOfferBadgeBlue from "../assets/img/icons/icon_offer-badge-blue.svg";
import IconStarGold from "../assets/img/icons/icon_star-gold.svg";
import IconTrophyLightGray from "../assets/img/icons/icon_trophy-light-gray.svg";
import IconGift from "../assets/img/icons/icon_gift.svg";
import iconUserBlue from "../assets/img/icons/icon_user-blue.svg";
import iconSplRedeemss from "../assets/img/icons/icon_spl__redeem-ss.svg";
import iconSplRedeemExperience from "../assets/img/icons/icon_spl__redeem-experiences.svg";
import iconSplProfileUpdate from "../assets/img/icons/icon_spl__profile-update.svg";
import iconSplTransactions from "../assets/img/icons/icon_spl__transactions.svg";
import TierBannerGold from "../assets/img/tier-banner_gold.png";
import TierBannerBlack from "../assets/img/tier-banner_black.png";
import TierBannerPlatinum from "../assets/img/tier-banner_platinum.png";
import TierBannerSilver from "../assets/img/tier-banner_silver.png";
import $ from "jquery";
import moment from "moment";

import { authAxios } from "../config/constant";
import { Link, useNavigate } from "react-router-dom";
import HomeNavCard from "../components/home-nav-card";
import { decryptData, encryptData } from "../pages/common-func";
// import logoFirstCitizenClub from "../assets/img/logo_ss-first-citizen-club.svg";

export default function Home() {
  const navigate = useNavigate();

  // const [splcard] = useState(["card1"]);
  // const [show] = useState(false);

  const [userName, setUserName] = useState("");
  const [userData, setUserData] = useState(localStorage.getItem("userPersonal"));
  const [keyID, setKeyID] = useState(localStorage.getItem("key_id"));
  const [mobileNumber] = useState(localStorage.getItem("mobile_no"));
  const [welcomeModel, setWelcomeModel] = useState(false);
  const [becomeAMember, setBecomeAMember] = useState(false);
  const [modelReward, setModelReward] = useState(false);
  // const [appTour] = useState(localStorage.getItem("tour"));
  const [newLogin] = useState(localStorage.getItem("newLogin"));
  // const [appTour, setAppTour] = useState("true");
  const [couponCode, setCouponCode] = useState("");
  const [couponValidity, setValidity] = useState("");
  const [couponDescription, setDescription] = useState("");
  const [storeName, setStoreName] = useState(localStorage.getItem("storeName"));
  const [userTier, setUserTier] = useState(localStorage.getItem("tier"));
  const [userTierClass, setUserTierClass] = useState("");
  // const [memberId, setMemberID] = useState("");
  const [scanned] = useState(localStorage.getItem("scanned"));
  const [welcomePopupClose, setWelcomeClosePopup] = useState(false);
  const [smsKeyId, setSmskeyID] = useState(localStorage.getItem("key_id"));
  const [redeemPoints, setRedeemPoints] = useState(localStorage.getItem("points"));
  // const [rewardPopupClosed, setRewardPopupClosed] = useState(false);
  const [loaderPopup, setLoaderPopup] = useState(false);
  const [exclusiveOffer, setExclusiveOffer] = useState(false);
  const [multipleOffers, setMultipleOffers] = useState(false);

  /* Carousel Images */
  const images = [
    {
      src: slider1,
      alt: 'Banner 1',
      href: '/buy-partner-brands'
    },
    {
      src: slider2,
      alt: 'Banner 2',
      href: "/pujo-veteran",
    },
    // {
    //   src: slider3,
    //   alt: 'Banner 3',
    // },
    // {}
  ];

  // function addVisitedState() {
  //   $(".app-tour__pointer-btn").addClass("is-visited");
  // }

  function updateProfileRedirect() {
    if(localStorage.getItem('tier') == 'notMember'){
      // alert("hi")
      setBecomeAMember(true)
    }else{
      navigate('/first-citizen-enrollment');
    }
  }

  function redeemPointsRedirect() {
    navigate('/buy-partner-brands');
  }

  function lastTransactionRedirect() {
    navigate('/coming-soon');
  }

  const handleRedirect = () => {
    // console.log("dsfsfd")
    // window.location.href = 'https://www.shoppersstop.com/';
    window.open('https://www.shoppersstop.com/', '_blank')
  };

  // useEffect(() => {
  //   if (appTour === "true") {
  //     if (rewardPopupClosed) {
  //       $(".page-backdrop").addClass("show");
  //       $(".app-tour__pointer-btn").addClass("show");

  //       setRewardPopupClosed(false);
  //     }
  //   }
  // }, [rewardPopupClosed]);

  // get profile
  useEffect(() => {
    // if (localStorage.getItem("WelcomeModal") !== "true") {
    // console.log(localStorage.getItem("otpRedeemed"))
    if (userData === null || localStorage.getItem("otpRedeemed") == "success") {
      // debugger;
      setLoaderPopup(true);
      const data = {
        keyID: keyID,
        store_code: "",
      };
      const headers = {
        AuthToken: localStorage.getItem("auth_key"),
        Otp: localStorage.getItem("otp")
      }
      if (headers.AuthToken) {
        authAxios
          .post("/get_profile", data, { headers: headers })
          .then((res) => {
            // debugger;
            if (res.data.code == 403) {
              localStorage.clear();
              navigate("/landing-page");
            }
            else {
              // if (res?.data?.CustomerProfileResponse?.data?.balances[0]) {
              //   localStorage.setItem("userPoint", res?.data?.CustomerProfileResponse?.data?.balances[0]?.balance)
              // }
              // else {
              //   localStorage.setItem("userPoint", 0)
              // }
              if (res.data.CustomerProfileResponse !== undefined) {
                if (res.data.CustomerProfileResponse.CustomerProfileResponse.errorMsg !== "Success") {
                  // console.log("Not Success")
                  if (res.data.CustomerProfileResponse.CustomerProfileResponse.errorMsg === "Member Not Found") {
                    user = encryptData(JSON.stringify(res.data.CustomerProfileResponse));
                    localStorage.setItem("userPersonal", user);
                    localStorage.setItem("tier", 'notMember')
                    setBecomeAMember(true);
                    setLoaderPopup(false);
                  }
                  // localStorage.setItem("userData", res.data);
                  // if (res.data.store_details !== undefined) {
                  //   const storeData = res.data.store_details[0];

                  //   if (storeData !== undefined && storeData !== null) {
                  //     setStoreName(storeData.store_name);
                  //     localStorage.setItem("storeName", storeData.store_name);
                  //     localStorage.setItem("store_city", storeData.store_city);
                  //     localStorage.setItem("store_code", storeData.store_code);
                  //   }
                  // }

                  // setWelcomeModel(true);
                }
                else {
                  // debugger;
                  var userData = res.data.CustomerProfileResponse.CustomerProfileResponse;
                  if (res.data.store_details !== undefined) {
                    const storeData = res.data.store_details[0];

                    if (storeData !== undefined && storeData !== null) {
                      setStoreName(storeData.store_name);
                      localStorage.setItem("storeName", storeData.store_name);
                      localStorage.setItem("store_city", storeData.store_city);
                      localStorage.setItem("store_code", storeData.store_code);
                    }
                  }
                  // console.log(userData.cardName);
                  if (userData.user) {
                    // debugger;
                    var user = encryptData(JSON.stringify(userData));
                    localStorage.setItem("userPersonal", user);
                    setUserData(JSON.stringify(userData.user))
                  }
                  if (userData.user.first_name === '') {
                    // var cName = userData.firstName + " " + userData.lastName;
                    // localStorage.setItem("firstname", cName);
                    setUserName(userData.member_name);
                  } else {
                    // localStorage.setItem("firstname", userData.member_name);
                    setUserName(userData.user.first_name + " " + userData.user.last_name);
                  }
                  // if (userData.member_id === undefined) {
                  //   // localStorage.setItem("memberId", userData.member_id);
                  //   setMemberID(userData.memberNumber);
                  // } else {
                  //   // localStorage.setItem("memberId", userData.member_id);
                  //   setMemberID(userData.memberId);
                  // }

                  // localStorage.setItem("FCNumber", userData.FCNumber);
                  if (userData.balances === null) {
                    localStorage.setItem("userBalance", 0);
                  } else {
                    localStorage.setItem("userBalance", JSON.stringify(userData.balances));
                  }
                  // localStorage.setItem("tier_expeiry", userData.tierEndDate);

                  if (userData.tier_class === "Tier4") {
                    localStorage.setItem("tier", "FIRST CITIZEN BLACK");
                    setUserTier("FIRST CITIZEN BLACK");
                    setUserTierClass("tier__black");
                    setSmskeyID(keyID);
                  }
                  if (userData.tier_class === "Tier3") {
                    localStorage.setItem("tier", "PLATINUM AURA");
                    setUserTier("PLATINUM AURA");
                    setUserTierClass("tier__platinum");
                  }
                  if (userData.tier_class === "Tier2") {
                    localStorage.setItem("tier", "GOLDEN GLOW");
                    setUserTier("GOLDEN GLOW");
                    setUserTierClass("tier__gold");
                  }
                  if (userData.tier_class === "Tier1") {
                    localStorage.setItem("tier", "SILVER EDGE");
                    setUserTier("SILVER EDGE");
                    setUserTierClass("tier__silver");
                  }
                  if (
                    userData.tier_class === "Tier0" ||
                    userData.tier_class === null ||
                    userData.tier_class === "null"
                  ) {
                    setUserTierClass("");
                    localStorage.setItem("membernotfound", "true");

                  }

                  localStorage.setItem("WelcomeModal", "true");
                  // debugger;
                  if (localStorage.getItem("otpRedeemed") !== "success" && localStorage.getItem("membernotfound") !== "true"){
                    const timeoutId = setTimeout(() => {
                      setWelcomeModel(true);
                    }, 2000);
                    // return () => clearTimeout(timeoutId);
                    // setWelcomeModel(true);
                  }
                  if (localStorage.getItem("membernotfound") === "true"){
                    setBecomeAMember(true);
                  }
                  if (localStorage.getItem("otpRedeemed") == "success") {
                    localStorage.setItem("otpRedeemed", null)
                  }
                  setLoaderPopup(false);
                  // couponScan(userData.memberId, userData.tier, userData.cardName);

                  let primaryPoint = 0;
                  let secondaryPoint = 0;
                  let citiPoint = 0;
                  let hdfcPoint = 0;
                  let totalBalance = 0;
                  for (const balance of userData.balances) {
                    const { loyalty_account, balance: accountBalance } = balance;

                    // Check the loyalty_account and update the corresponding variable
                    if (loyalty_account === "FC Points") {
                      primaryPoint = parseFloat(accountBalance) || 0;
                    } else if (loyalty_account === "Citi Points") {
                      citiPoint = parseFloat(accountBalance) || 0;
                    } else if (loyalty_account === "FC Points-Secondary") {
                      secondaryPoint = parseFloat(accountBalance) || 0;
                    } else if (loyalty_account === "HDFC Points") {
                      hdfcPoint = parseFloat(accountBalance) || 0;
                    }
                  }
                  totalBalance = primaryPoint + secondaryPoint + citiPoint + hdfcPoint;
                  setRedeemPoints(totalBalance.toFixed(2));
                  localStorage.setItem('points', totalBalance.toFixed(2))
                }
              } else {
                if (res.data.errorMsg === "All are same tier") {
                  $(".call2action_multiple-membership").addClass("show");
                  $(".call2action-overlay").addClass("show");
                }
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      else {
        navigate("/landing-page")
      }
    }
    else {
      const userPersonal = decryptData(localStorage.getItem("userPersonal"), 0);
      if (userPersonal?.CustomerProfileResponse?.errorMsg === "Member Not Found") {
        setUserName("")
      }
      else {
        if (userPersonal?.user?.first_name === '') {
          setUserName(userPersonal?.member_name)
        }
        else {
          setUserName(userPersonal?.user?.first_name + " " + userPersonal?.user?.last_name)
        }
      }
    }
  }, []);
  // coupon fetch
  useEffect(() => {
    if (welcomePopupClose) {
      // debugger;
      localStorage.setItem("newLogin", false);
      if (keyID !== null) {
        // debugger;
        if (scanned === "true") {
          $(".call2action_reward-end").addClass("show");
          $(".call2action-overlay").addClass("show");
        } else {
          // debugger;
          const data = {
            store_code: "",
            keyID: keyID,
            name: userName,
            // memberID: memberId,
            tier: userTier,
          };
          const headers = {
            AuthToken: localStorage.getItem("auth_key"),
            Otp: localStorage.getItem("otp")
          }
          // debugger;
          // console.log("customer_coupon_data", data);
          // setLoaderPopup(true);
          if (headers.AuthToken) {
            authAxios
              .post("/customer_coupon", data)
              .then((res) => {
                // debugger;
                // console.log(res);
                if (res.data.code == 403) {
                  localStorage.clear();
                  navigate("/landing-page");
                }
                else {
                  setLoaderPopup(false);
                  if (res.data.Message === "Coupon already received") {
                    // console.log("welcomePopupClose", welcomePopupClose);
                    if (welcomePopupClose) {
                      $(".call2action_reward-end").addClass("show");
                      $(".call2action-overlay").addClass("show");
                    }
                    localStorage.removeItem("key_id");
                    localStorage.setItem("scanned", true);
                  } else if (res.data.Message === "No Coupon Found") {
                    if (welcomePopupClose) {
                      $(".call2action_coupon_notfound").addClass("show");
                      $(".call2action-overlay").addClass("show");
                    }
                    localStorage.removeItem("key_id");
                    localStorage.setItem("scanned", true);
                  } else {
                    // console.log("code", res.data[0].code);
                    // console.log("validity", res.data[0].validity);
                    // console.log("description", res.data[0].description);
                    if (res.data.length > 1) {
                      setMultipleOffers(true);
                    }
                    if (res.data[0].pin === "N/A") {
                      setCouponCode(res.data[0].code);
                      setValidity(res.data[0].validity);
                      setDescription(res.data[0].description);
                      setExclusiveOffer(true);
                      localStorage.removeItem("key_id");
                      localStorage.setItem("scanned", true);
                      setKeyID(null);
                    } else {
                      setCouponCode(res.data[0].code);
                      setValidity(res.data[0].validity);
                      setDescription(res.data[0].description);

                      setModelReward(true);

                      localStorage.removeItem("key_id");
                      localStorage.setItem("scanned", true);
                      localStorage.setItem("newLogin", false);
                      // setNewLogin("false");

                      setKeyID(null);
                    }
                  }
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
          else {
            navigate("/landing-page")
          }
        }
      }
    } else {
      if (newLogin !== "true") {
        // debugger;
        if (keyID !== null) {
          // debugger;
          if (scanned === "true") {
            $(".call2action_reward-end").addClass("show");
            $(".call2action-overlay").addClass("show");
          } else {
            // debugger;
            const data = {
              store_code: "",
              keyID: keyID,
              name: userName,
              // memberID: memberId,
              tier: userTier,
            };
            const headers = {
              AuthToken: localStorage.getItem("auth_key"),
              Otp: localStorage.getItem("otp")
            }
            // console.log("customer_coupon_data", data);
            // setLoaderPopup(true);
            if (headers.AuthToken) {
              authAxios
                .post("/customer_coupon", data)
                .then((res) => {
                  // debugger;
                  // console.log(res);
                  if (res.data.code == 403) {
                    localStorage.clear();
                    navigate("/landing-page");
                  }
                  else {
                    setLoaderPopup(false);
                    if (res.data.Message === "Coupon already received") {
                      // console.log("w1");

                      if (newLogin !== "true") {
                        $(".call2action_reward-end").addClass("show");
                        $(".call2action-overlay").addClass("show");
                      }
                      localStorage.removeItem("key_id");
                      localStorage.setItem("scanned", true);
                    } else if (res.data.Message === "No Coupon Found") {
                      if (newLogin !== "true") {
                        $(".call2action_coupon_notfound").addClass("show");
                        $(".call2action-overlay").addClass("show");
                      }
                      localStorage.removeItem("key_id");
                      localStorage.setItem("scanned", true);
                    } else {
                      // debugger;
                      // console.log(res.data[0].pin);
                      if (res.data[0].pin === "N/A") {
                        setCouponCode(res.data[0].code);
                        setValidity(res.data[0].validity);
                        setDescription(res.data[0].description);
                        setExclusiveOffer(true);
                        localStorage.removeItem("key_id");
                        localStorage.setItem("scanned", true);
                        setKeyID(null);
                      } else {
                        // console.log("working here");
                        setCouponCode(res.data[0].code);
                        setValidity(res.data[0].validity);
                        setDescription(res.data[0].description);
                        setModelReward(true);

                        localStorage.removeItem("key_id");
                        localStorage.setItem("scanned", true);

                        setKeyID(null);
                      }
                    }
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            else {
              navigate("/landing-page")
            }
          }
        }
        // else {
        //   if (appTour !== "true") {
        //     setRewardPopupClosed(true);
        // setWelcomeClosePopup(true);
        //   }
        // }
      }
    }
  }, [welcomePopupClose]);

  function welcomeClosePopup() {
    setWelcomeModel(false);
    setWelcomeClosePopup(true);
    setBecomeAMember(false);
    // const effect = setTimeout(() => {
    //   navigate("/pujo-veteran");
    // }, 10000);
  }
  // close reward popup
  function closeQRDailyLimitReached() {
    $(".call2action_reward-end").addClass("fade");
    $(".call2action-overlay").addClass("fade");
    setTimeout(() => {
      $(".call2action-overlay").removeClass("fade");
      $(".call2action-overlay").removeClass("show");
      $(".call2action_reward-end").removeClass("fade");
      $(".call2action_reward-end").removeClass("show");
      $(".page-backdrop").removeClass("show");
    }, 500);
    setModelReward(false);
    // setRewardPopupClosed(true);

    localStorage.removeItem("key_id");
    setKeyID("");
    if (scanned !== "true") {
      if (smsKeyId !== null) {
        if (userTier === "FIRST CITIZEN BLACK") {
          const smsData = {
            name: userName,
            keyID: smsKeyId,
          };
          authAxios
            .post("/manager_sms", smsData)
            .then((res) => {
              // console.log("sms send succesfully");
            })
            .catch((err) => {
              console.log(err);
            });
        }
        setSmskeyID(null);
      }
    }

    //   $(".call2action-overlay").removeClass("show");
  }

  function multipleMemberfoundClose() {
    $(".call2action_multiple-membership").addClass("fade");
    $(".call2action-overlay").addClass("fade");
    setTimeout(() => {
      $(".call2action-overlay").removeClass("fade");
      $(".call2action-overlay").removeClass("show");
      $(".call2action_multiple-membership").removeClass("fade");
      $(".call2action_multiple-membership").removeClass("show");
    }, 500);
  }

  function couponNotfoundclose() {
    $(".call2action_coupon_notfound").addClass("fade");
    $(".call2action-overlay").addClass("fade");
    setTimeout(() => {
      $(".call2action-overlay").removeClass("fade");
      $(".call2action-overlay").removeClass("show");
      $(".call2action_coupon_notfound").removeClass("fade");
      $(".call2action_coupon_notfound").removeClass("show");
    }, 500);
  }
  // setTimeout(() => {
  //   $(".page-backdrop").addClass("show");
  //   $(".app-tour__pointer-btn").addClass("show");
  // }, 500);
  // setTimeout(function () {
  //   $(".call2action").addClass("fade");
  // }, 5000);
  // setTimeout(function () {
  //   $(".call2action-overlay").addClass("fade");
  // }, 5250);
  // setTimeout(function () {
  //   $(".call2action-overlay").removeClass("fade");
  //   $(".call2action-overlay").removeClass("show");
  //   $(".call2action").removeClass("fade");
  //   $(".call2action").removeClass("show");
  // }, 5500);

  // functions for app tour

  function Skipmodel() {
    localStorage.setItem("tour", false);
    navigate("/landing-page");
  }
  function getstartedmodel() {
    // e.preventDefault();/
    localStorage.setItem("tour", false);

    navigate("/landing-page");
  }

  // function startScaningClickHandler() {
  //   navigate("/qr-scanner");
  // }

  const options = {
    items: 1,
    loop: false,
    margin: 15,
    stagePadding: 20,
    nav: false,
    dots: false,
    autoplay: false,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
  };

  return (
    <div className="screen-wrap has-sticky-footer">
      {/* Header Section */}
      <Header
        className="app-header has-border"
        headertype={1}
        userName={userName !== null ? userName : ""}
        redeemPoints={redeemPoints !== null ? redeemPoints : ""}
      />

      {/* Home Main Section */}
      <div className="main-wrap">
        {/* User Info */}
        <div className="user-details">
          <div className="user-info">
            <h6 className="__welcome-text">Hello</h6>
            {userName !== null ? (
              <h2 className="__user-name">{userName}</h2>
            ) : (
              <h2 className="__user-name">{"Welcome Back !"}</h2>
            )}
          </div>
          {/* <div className="user-loyalty-detail">
            <Icon className="icon-loyalty"></Icon>
            <div className="loyalty-content">
              <h3 className="loyalty-points">147</h3>
              <span>Points</span>
            </div>
            <a
              node="button"
              href="#modal__app-tour"
              className="app-tour__pointer-btn modal-trigger"
              onClick={addVisitedState}
            >
              <svg className="icon" viewBox="0 0 12 12" aria-hidden="true">
                <path d="M11,5H7V1A1,1,0,0,0,5,1V5H1A1,1,0,0,0,1,7H5v4a1,1,0,0,0,2,0V7h4a1,1,0,0,0,0-2Z"></path>
              </svg>
            </a>
          </div> */}
        </div>

        {/* Slider Banner */}
        <div>
          <OwlCarousel
            className="hero-carousel owl-theme"
            {...options}
          >
            {images.map((image, index) => (
              <div key={index} className="item">
                {/* <img src={image.src} alt={image.alt} /> */}
                <a href={image.href || ''}>
                  <img src={image.src} alt={image.alt} />
                </a>
              </div>
            ))}
          </OwlCarousel>
        </div>
        {/* Tier Banner Golden Glow */}
        {userTier === "GOLDEN GLOW" && <div className="tier-wrapper">
          <div className="tier-banner tier-banner--gold">
            <img className="tier-banner-img" src={TierBannerGold} alt="" />
            <div className="tier-banner__left">
              <div className="tier-points-details">
                <h6 className="tier-points__label">Available Points</h6>
                {redeemPoints ? <p className="tier-points__value">
                  <span>{redeemPoints}</span> 
                  {/* = ₹ {(redeemPoints * 0.25).toFixed(2)} */}
                </p> : <p className="tier-points__value">
                  <span>0</span> 
                  {/* = ₹ 0 */}
                </p>
                }
              </div>
              <div className="tier-user-info">
                <h5 className="user-name">{userName}</h5>
                {
                  redeemPoints ? <small className="expiry-text">
                    Expiring this month: <span>{redeemPoints}</span>
                  </small> : <small className="expiry-text">
                    Expiring this month: <span>0</span>
                  </small>
                }
              </div>
            </div>
            <div className="tier-banner__right">
              <label className="loyalty-reward-text">Loyalty <br />Rewards</label>
              <h6 className="tier-name">Golden <br /><span>GLOW</span></h6>
            </div>
          </div>
        </div>}
        {/* Tier Banner First Citizen */}
        {userTier === "FIRST CITIZEN BLACK" && <div className="tier-wrapper">
          <div className="tier-banner tier-banner--black">
            <img className="tier-banner-img" src={TierBannerBlack} alt="" />
            <div className="tier-banner__left">
              <div className="tier-points-details">
                <h6 className="tier-points__label">Available Points</h6>
                {redeemPoints ? <p className="tier-points__value">
                  <span>{redeemPoints}</span> 
                  {/* = ₹ {(redeemPoints * 0.25).toFixed(2)} */}
                </p> : <p className="tier-points__value">
                  <span>0</span> 
                  {/* = ₹ 0 */}
                </p>
                }
              </div>
              <div className="tier-user-info">
                <h5 className="user-name">{userName}</h5>
                {
                  redeemPoints ? <small className="expiry-text">
                    Expiring this month: <span>{redeemPoints}</span>
                  </small> : <small className="expiry-text">
                    Expiring this month: <span>0</span>
                  </small>
                }
              </div>
            </div>
            <div className="tier-banner__right">
              <label className="loyalty-reward-text">Loyalty <br />Rewards</label>
              <h6 className="tier-name">First Citizen <br /><span>Black</span></h6>
            </div>
          </div>
        </div>}
        {/* Tier Banner Platinum */}
        {userTier === "PLATINUM AURA" && <div className="tier-wrapper">
          <div className="tier-banner tier-banner--platinum">
            <img className="tier-banner-img" src={TierBannerPlatinum} alt="" />
            <div className="tier-banner__left">
              <div className="tier-points-details">
                <h6 className="tier-points__label">Available Points</h6>
                {redeemPoints ? <p className="tier-points__value">
                  <span>{redeemPoints}</span> 
                  {/* = ₹ {(redeemPoints * 0.25).toFixed(2)} */}
                </p> : <p className="tier-points__value">
                  <span>0</span> 
                  {/* = ₹ 0 */}
                </p>
                }
              </div>
              <div className="tier-user-info">
                <h5 className="user-name">{userName}</h5>
                {
                  redeemPoints ? <small className="expiry-text">
                    Expiring this month: <span>{redeemPoints}</span>
                  </small> : <small className="expiry-text">
                    Expiring this month: <span>0</span>
                  </small>
                }
              </div>
            </div>
            <div className="tier-banner__right">
              <label className="loyalty-reward-text">Loyalty <br />Rewards</label>
              <h6 className="tier-name">Platinum <br /><span>Aura</span></h6>
            </div>
          </div>
        </div>}
        {/* Tier Banner Silver */}
        {userTier === "SILVER EDGE" && <div className="tier-wrapper">
          <div className="tier-banner tier-banner--silver">
            <img className="tier-banner-img" src={TierBannerSilver} alt="" />
            <div className="tier-banner__left">
              <div className="tier-points-details">
                <h6 className="tier-points__label">Available Points</h6>
                {redeemPoints ? <p className="tier-points__value">
                  <span>{redeemPoints}</span> 
                  {/* = ₹ {(redeemPoints * 0.25).toFixed(2)} */}
                </p> : <p className="tier-points__value">
                  <span>0</span> 
                  {/* = ₹ 0 */}
                </p>
                }
              </div>
              <div className="tier-user-info">
                <h5 className="user-name">{userName}</h5>
                {
                  redeemPoints ? <small className="expiry-text">
                    Expiring this month: <span>{redeemPoints}</span>
                  </small> : <small className="expiry-text">
                    Expiring this month: <span>0</span>
                  </small>
                }
              </div>
            </div>
            <div className="tier-banner__right">
              <label className="loyalty-reward-text">Loyalty <br />Rewards</label>
              <h6 className="tier-name">Silver <br /><span>Edge</span></h6>
            </div>
          </div>
        </div>}

        {/* Home Nav Grid */}
        <div className="home-nav-grid">
          <Row>
            <Col s={6} onClick={() => handleRedirect()}>
              <HomeNavCard
                cardColor="danger"
                cardIcon={iconSplRedeemss}
                cardLabel="Redeem Points <br /> at ss.com"
              />
            </Col>
            <Col s={6} onClick={redeemPointsRedirect}>
              <HomeNavCard
                cardColor="purple"
                cardIcon={iconSplRedeemExperience}
                cardLabel="Redeem Points <br />For Experiences"
              />
            </Col>
            <Col s={6} onClick={updateProfileRedirect}>
              <HomeNavCard
                cardColor="blue"
                cardIcon={iconSplProfileUpdate}
                cardLabel="Update Profile <br />& Earn Points"
              />
            </Col>
            <Col s={6} onClick={lastTransactionRedirect}>
              <HomeNavCard
                cardColor="success"
                cardIcon={iconSplTransactions}
                cardLabel="Check Last <br />10 Transactions"
              />
            </Col>
          </Row>
        </div>
      </div>

      {/* App Tour Pointer Bottom */}
      <a
        node="button"
        href="#modal__app-tour"
        className="app-tour__pointer-btn app-tour__pointer-bottom modal-trigger"
      >
        <svg className="icon" viewBox="0 0 12 12" aria-hidden="true">
          <path d="M11,5H7V1A1,1,0,0,0,5,1V5H1A1,1,0,0,0,1,7H5v4a1,1,0,0,0,2,0V7h4a1,1,0,0,0,0-2Z"></path>
        </svg>
      </a>

      {/* Footer Section */}
      <Footer />

      {/* Call2Action */}
      {/* <div className="call2action show">
        <div className="call2action-content">
          <div className="loyalty-points-card">
            <div className="icon-box">
              <div className="loyalty-points__icon icon-card__tour"></div>
            </div>
            <div className="card-stacked">
              <div className="card-content">
                <h4 className="card-title">You earned 54 points</h4>
                <p className="card-text">For visiting the â€œTake a Tourâ€ section</p>
              </div>
              <div className="loyalty-points__wrapper">
                <span className="loyalty-points">+54</span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="call2action call2action_reward-end">
        <div className="call2action-content">
          <a
            href="javascript:void(0)"
            waves="light"
            className="call2action-cross-icon"
            onClick={closeQRDailyLimitReached}
          ></a>
          <div className="call2action-body">
            <div className="reward-end-info">
              <div className="reward-end-info__icon">
                <img src={IconOfferBadgeBlue} alt="icon offer badge" />
              </div>
              <div className="reward-end-info__content">
                <h5 className="reward-end-info__title">
                  {/* You are out of offers. */}
                </h5>
                <p className="reward-end-info__text">
                  You have already unlocked special offer. Refer your{" "}
                  <Link to={"/my-wallet-of-offers"}>wallet of offers.</Link>
                </p>
                <small>Redeem before the Offers expire!</small>
              </div>
            </div>
          </div>
          <div className="call2action-footer">
            <Button
              node="button"
              waves="light"
              className="btn-primary btn-fluid"
              onClick={() => {
                $(".call2action_reward-end").addClass("fade");
                $(".call2action-overlay").addClass("fade");
                setTimeout(() => {
                  $(".call2action-overlay").removeClass("fade");
                  $(".call2action-overlay").removeClass("show");
                  $(".call2action_reward-end").removeClass("fade");
                  $(".call2action_reward-end").removeClass("show");
                  // $(".page-backdrop").removeClass("show");
                }, 500);
                setModelReward(false);
                // setRewardPopupClosed(true);

                localStorage.removeItem("key_id");
                setKeyID("");
                if (scanned !== "true") {
                  if (smsKeyId !== null) {
                    if (userTier === "FIRST CITIZEN BLACK") {
                      const smsData = {
                        name: userName,
                        keyID: smsKeyId,
                      };
                      authAxios
                        .post("/manager_sms", smsData)
                        .then((res) => {
                          // console.log("sms send succesfully");
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }
                    setSmskeyID(null);
                  }
                }
                navigate("/my-wallet-of-offers");
              }}
            >
              See My Offers <i className="icon-arrow-right ml-10"></i>
            </Button>
          </div>
        </div>
      </div>
      <div className="call2action call2action_multiple-membership">
        <div className="call2action-content">
          <div className="call2action-body">
            <div className="call2action-info">
              <div className="call2action-info__icon">
                <img src={iconUserBlue} alt="" />
              </div>
              <div className="call2action-info__content">
                <p>
                  The same mobile number <strong>{mobileNumber}</strong> is
                  associated with many memberships. Please check with a store
                  member to resolve this.
                </p>
              </div>
            </div>
          </div>
          <div className="call2action-footer">
            <Button
              node="button"
              className="btn btn-blue btn-fluid modal-close"
              onClick={multipleMemberfoundClose}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
      <div className="call2action call2action_coupon_notfound">
        <div className="call2action-content">
          <div className="call2action-body">
            <div className="call2action-info">
              <div className="call2action-info__icon">
                <img src={iconUserBlue} alt="" />
              </div>
              <div className="call2action-info__content">
                <p>
                  Kindly use the existing E-GV in stores.Scan the Qr code you will get new offers.
                </p>
              </div>
            </div>
          </div>
          <div className="call2action-footer">
            <Button
              node="button"
              className="btn btn-blue btn-fluid modal-close"
              onClick={couponNotfoundclose}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
      <div className="call2action-overlay call2action-overlay-md "></div>

      <ModalAppTour
        id="modal__app-tour"
        skipFunction={Skipmodel}
        getStartedFunction={getstartedmodel}
      />
      {/* reward model */}
      {/* {console.log("modelReward", modelReward)}
      {console.log("modelWReward", welcomePopupClose)} */}
      {(modelReward && welcomePopupClose) ||
        (newLogin !== "true" && modelReward) ? (
        <ModalCentered
          id="modal__reward"
          className="modal__reward text-center"
          closeIcon={
            <a
              href="javascript:void(0)"
              waves="light"
              className="modal-close icon-cross"
              onClick={closeQRDailyLimitReached}
            ></a>
          }
          header={
            <div className="modal-header">
              <div className="modal-icon">
                <img src={IconTrophy} alt="IconTrophy" />
              </div>
            </div>
          }
          contentBody={
            <div className="modal-body">
              <h2>Congratulations!</h2>
              <h6>Here is your exclusive coupon.</h6>
              <div className="dashed-coupon-code">
                {/* <span>{couponCode}</span> */}
                <span>{couponCode}</span>
              </div>
              {/* <p className="reward-description">{couponDescription}</p> */}
              <p className="reward-description">{couponDescription}</p>
              {/* <small className="validity-text">
                Valid till: {couponValidity}
              </small> */}
              <small className="validity-text">
                Valid till:{" "}
                {moment(new Date(couponValidity)).format("DD/MM/YYYY HH:mm:ss")}
              </small>
              <div className="reward-info">
                <div className="reward-info__icon">
                  <img src={IconOffer} alt="IconOffer"></img>
                </div>
                <div className="reward-info__content">
                  <p>
                    To check the offers you have collected for the day.{" "}
                    <Link to={"/my-wallet-of-offers"}>My Wallet of Offers</Link>
                  </p>
                </div>
              </div>
            </div>
          }
          footer={
            <div className="modal-footer">
              <Link to={"/terms-conditions"}>
                <span className="terms-text">Terms & Conditions apply</span>
              </Link>
            </div>
          }
        />
      ) : (
        ""
      )}

      {/* Start: Welcome POpup */}
      {/* <ModalCentered
      id="modal__welcome-greeting"
      className="modal__welcome-greeting"
      closeIcon={
        <a
          href="javascript:void(0)"
          waves="light"
          className="modal-close icon-cross icon-cross-dark"
          onClick={welcomeClosePopup}
        ></a>
      }
      header={
        <div className="modal-header">
          <img className="brand-logo" src={logoFirstCitizenClub} />
        </div>
      }
      contentBody={
        <div className="modal-body">
          <h4>
            Welcome to Shoppers Stop
          </h4>
          <p>
            I'm Your Virtual shopping assistant Let's go Shopping and unlock surprise offers!
          </p>
        </div>
      }
      footer={
        <div className="modal-footer">
          <Button
            node="button"
            waves="light"
            className="btn-primary btn-fluid"
            onClick={startScaningClickHandler}
          >
            Get Started <i className="icon-arrow-right ml-10"></i>
          </Button>
        </div>
      }
    /> */}
      {/* End: Welcome POpup */}

      {/* Start: Become a Member */}
      {becomeAMember && <ModalCentered
        id="modal__user-enroll"
        className="modal__user-enroll"
        closeIcon={
          <a
            href="javascript:void(0)"
            waves="light"
            className="modal-close icon-cross icon-cross-dark"
            onClick={welcomeClosePopup}
          ></a>
        }
        header={
          <div className="modal-header">
            <img className="brand-logo" src={logoFirstCitizenClub} />
          </div>
        }
        contentBody={
          <div className="modal-body">
            <h4>Hi There,</h4>
            <p>You are not a Shoppers Stop First Citizen Club member. Kindly contact store person</p>
          </div>
        }
        footer={
          <div className="modal-footer">
            <Button
              node="button"
              waves="light"
              className="btn-primary btn-fluid modal-close"
              onClick={welcomeClosePopup}
            >
              Done
              {/* <i className="icon-arrow-right ml-10"></i> */}
            </Button>
          </div>
        }
      />}
      {/* End: Become a Member */}

      {/* {console.log(welcomeModel)} */}
      {welcomeModel && (
        <ModalCentered
          id="modal__welcome-greeting"
          className="modal__welcome-greeting"
          closeIcon={
            <a
              href="javascript:void(0)"
              waves="light"
              className="modal-close icon-cross icon-cross-dark"
              onClick={welcomeClosePopup}
            ></a>
          }
          header={
            <div className="modal-header">
              <img className="brand-logo" src={logoFirstCitizenClub} alt="" />
            </div>
          }
          contentBody={
            <div className="modal-body">
              {userTierClass !== "" ? (
                <div
                // className={`tier-block  ${userTierClass !== "" ? userTierClass : ""
                //   }`}
                >
                  {/* {userTierClass !== "" ? (
                    <div className="tier-symbol-wrap">
                      <i className="tier-symbol"></i>
                    </div>
                  ) : (
                    ""
                  )} */}
                  {/* {userTierClass !== "" ? (
                    <span className="tier-badge">
                      <img src={IconStarGold} alt="IconStarGold" /> {userTier}
                    </span>
                  ) : (
                    ""
                  )} */}

                  {userName !== null ? (
                    <div className="user-info">
                      <span className="__welcome-text">Hello, </span>
                      <span className="__user-name">{userName}</span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}

              <div className="store-details">
                <h6 className="store__welcome-text">
                  Welcome to Shoppers Stop
                </h6>
                {storeName !== null ? (
                  <p className="store__name">
                    <i className="store__icon"></i> <span>{storeName}</span>
                  </p>
                ) : (
                  ""
                )}
              </div>

              <p>
                I'm Your Virtual shopping assistant Let's go Shopping and unlock surprise offers!
              </p>
            </div>
          }
        // footer={
        //   <div className="modal-footer">
        //     <Button
        //       node="button"
        //       waves="light"
        //       className="btn-primary"
        //       onClick={startScaningClickHandler}
        //     >
        //       Let's Go! <i className="icon-arrow-right ml-10"></i>
        //     </Button>
        //   </div>
        // }
        />)
      }
      {/* <ModalCentered
        id="modal__user-enroll"
        className="modal__user-enroll text-center"
        closeIcon={
          <a href="javascript:void(0)" waves="light" className="modal-close icon-cross icon-cross-dark"></a>
        }
        contentBody={
          <div className="modal-body">
            <img src={IconScanning} alt="icon-scanning" />
            <h4>Ooops...!</h4>
            <p>You are ineligible for any special offers. To become a prime member, you must enrol in the Shoppers Stop store.</p>
          </div>
        }
        footer={
          <div className="modal-footer">
            <Button
              node="button"
              waves="light"
              className="btn-primary">Got It
            </Button>
            <small>Contact shop members if you want to know more about enrolling.</small>
          </div>
        }
      /> */}

      {/* Reward Loader */}
      {/* {loaderPopup ? (
        <Loader
          id="modal__reward"
          className="modal__reward text-center"
          contentBody={
            <div className="progresser-loader">
              <img src={IconTrophyLightGray} alt="icon trophy" />
              <div className="progresser">
                <span className="progresser-bar"></span>
              </div>
              <p className="progresser-text">Fetching your offer</p>
            </div>
          }
        />
      ) : (
        ""
      )} */}
      {loaderPopup ? (<div id="loading" class="loadme-overlay">
        <div class="loadme-circular"></div>
        <p class="loadme-text" id="st"></p>
      </div>
      ) : (
        ""
      )}

      {/* Exclusive Deal */}
      {/* {console.log("")} */}
      {(welcomePopupClose && exclusiveOffer) ||
        (exclusiveOffer && newLogin !== "true") ? (
        <ModalCentered
          id="modal__exclusive-deal"
          className="modal__exclusive-deal text-center"
          header={<div className="modal-header"></div>}
          contentBody={
            <div className="modal-body">
              <div className="modal-icon-wrap">
                <img src={IconGift} alt="IconGift" />
              </div>
              <h2 className="congrats-text">Congratulations!</h2>
              <p>
                {multipleOffers
                  ? `You got more than one offer, Check Wallet of Offers for More Details`
                  : `Here is your exclusive Deal of the day.`}
              </p>
              <div className="exclusive-deal-desc">{couponDescription}</div>
              <small className="validity-text">
                Valid till:{" "}
                {moment(new Date(couponValidity)).format("DD/MM/YYYY HH:mm:ss")}
              </small>
              <Link to={"/terms-conditions"}>
                <span className="terms-text">Terms & Conditions apply</span>
              </Link>
            </div>
          }
          footer={
            <div className="modal-footer">
              <Button
                node="button"
                className="btn btn-blue modal-close"
                onClick={closeQRDailyLimitReached}
              >
                Close
              </Button>
            </div>
          }
        />
      ) : (
        ""
      )}
    </div>
  );
}