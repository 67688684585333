import React, { useEffect, useState } from "react";
import "materialize-css/dist/css/materialize.min.css";
import Header from "../components/layout/header";
import Footer from "../components/layout/footer.js";
import OfferCard from "../components/offer-card.js";
import ExclusiveDealCard from "../components/exclusive-deal-card.js";
import ModalCta from "../components/modal-cta.js";
import { Container, Button, Row, Col } from "react-materialize";
// import $ from "jquery";
import { authAxios } from "../config/constant";
import IconGift from "../assets/img/icons/icon_gift.svg";
import IconVoucherGray from "../assets/img/icons/icon_voucher-gray.svg";
import IconOfferGray from "../assets/img/icons/icon_offer-gray.svg";
import LogoZomato from "../assets/img/partner-brand_zomato.png";
import LogoO2Spa from "../assets/img/partner-brand_o2spa.png";
import LogoMakeMyTrip from "../assets/img/partner-brand_makemytrip.png";
import LogoMarriott from "../assets/img/partner-barnd_marriott.png";
import LogoBookMyShow from "../assets/img/partner-brand_bookmyshow.png";
import LogoTaj from "../assets/img/partner-brand_taj.png";
import LogoUber from "../assets/img/partner-brand_uber.png";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ModalCentered from "../components/modal-centered";
import IconExternalLink from "../assets/img/icons/icon_external-link.svg";
// import IconClose from "../assets/img/icons/icon_close.svg";
import OfferBadgeGray from "../assets/img/icons/icon_offer-badge-gray.svg";
import { Link } from "react-router-dom";

export default function Offer() {
  localStorage.setItem("currentPage",'/my-wallet-of-offers')
  // const [memberId, SetmemberID] = useState(localStorage.getItem("memberID"));
  const [offers, SetOffers] = useState([]);
  // const [scanned] = useState(localStorage.getItem("scanned"));
  const [mobileNumber] = useState(localStorage.getItem("mobile_no"));
  const [offerCode, SetOfferCode] = useState("");
  const [offerpin, SetOfferPin] = useState("");
  const [offerDesc, SetOfferDesc] = useState("");
  const [offerDescription, setOfferDescription] = useState("");
  const [offerValidity, SetOfferValidity] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  // const [offerAvailable, setAvailable] = useState(false);
  const [pujoOffers, setPujoOffers] = useState([]);
  const [offerImage, SetOfferImage] = useState('');
  const [loaderPopup, setLoaderPopup] = useState(false);
  const [currentDateTime] = useState(
    moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
  );
  const navigate = useNavigate();

  var color = "";

  useEffect(() => {
    // debugger;
    // console.log(currentDateTime);
    const payload = {
    };
    const headers = {
      AuthToken: localStorage.getItem("auth_key"),
      Otp: localStorage.getItem("otp")
    }
    if (headers.AuthToken) {
      setLoaderPopup(true);
      authAxios
        .post("/offer_today", payload, { headers: headers })
        .then((res) => {
          // debugger;
          console.log(res.data);
          if (res?.data?.code == 403) {
            localStorage.clear();
            navigate("/landing-page");
          }
          else {
            if (res.data != null) {
              // SetOffers(res.data);
              // setAvailable(true);
              const arrayPujo = res.data.filter(item => item.coupon_code == 'PUJO');
              const arrayOffers = res.data.filter(item => item.coupon_code != 'PUJO');
              const newArrayOffers = arrayOffers.map(item => {
                let imageAdd;
                if (item.brand == 'o2spa') {
                  imageAdd = LogoO2Spa
                }
                else if (item.brand == 'taj') {
                  imageAdd = LogoTaj
                }
                else if (item.brand == 'makemytrip') {
                  imageAdd = LogoMakeMyTrip
                }
                else if (item.brand == 'zomato') {
                  imageAdd = LogoZomato
                }
                else if (item.brand == 'marriott') {
                  imageAdd = LogoMarriott
                }
                else if (item.brand == 'bookmyshow') {
                  imageAdd = LogoBookMyShow
                }
                else if (item.brand == 'uber') {
                  imageAdd = LogoUber
                }
                return {
                  ...item,
                  imageAdd,
                };
              })
              if (arrayPujo.length) {
                setPujoOffers(arrayPujo)
                setLoaderPopup(false);
              }
              if (arrayOffers.length) {
                SetOffers(newArrayOffers);
                setLoaderPopup(false);
              }
            }
            else{
              setLoaderPopup(false);
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
    else {
      navigate("/landing-page")
    }
  }, [mobileNumber]);

  function formatdate(date) {
    const inputDate = new Date(date);

    const options = {
      // day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };

    const formattedDate = inputDate.toLocaleString('en-US', options);

    console.log(formattedDate);
    // return formattedDate
    const dayWithSuffix = addSuffixToNumber(inputDate.getDate());
    console.log(`${dayWithSuffix} ${formattedDate}`);
    return `${dayWithSuffix} ${formattedDate}`
  }

  function addSuffixToNumber(number) {
    if (number >= 11 && number <= 13) {
      return `${number}th`;
    } else {
      const lastDigit = number % 10;
      switch (lastDigit) {
        case 1:
          return `${number}st`;
        case 2:
          return `${number}nd`;
        case 3:
          return `${number}rd`;
        default:
          return `${number}th`;
      }
    }
  }

  function reDirectToQR() {
    navigate("/pujo-veteran");
  }
  const setCardPopup = (offer) => {
    if (offer.coupon_code === 'PUJO') {
      SetOfferCode("")
      SetOfferDesc(offer.description)
    }
    else {
      SetOfferCode(offer.coupon_code)
      setOfferDescription(offer.description)
    }
    if (offer.pin === 'N/A') {
      SetOfferPin("")
    }
    else {
      SetOfferPin(offer.pin)
    }
    if (offer.validity) {
      SetOfferValidity(offer.validity)
    }
    if (offer.imageAdd) {
      SetOfferImage(offer.imageAdd)
    }
    setOpenPopup(true)
  }

  return (
    <div className="screen-wrap">
      {/* Header Section */}
      <Header className="app-header" headertype={1} />
      {/* Main Content */}
      <div className="main-wrap">
        <Container>
          <div className="heading-section">
            <h2 className="heading-title">Display Offers</h2>
            <p className="heading-desc">Exclusive offers for you !</p>
          </div>

          {/* <OfferCard
            offerCardTitle={"Flat 10% OFF"}
            offerCardText={
              "Use the coupon to get 20% cashback on Code and Ginger footwear"
            }
            offerCardValidity={"11th January 2022"}
          />
          <OfferCard
            offerCardColor="bg-dark"
            offerCardTitle={"Flat 10% OFF"}
            offerCardText={
              "Use the coupon to get 20% cashback on Code and Ginger footwear"
            }
            offerCardValidity={"11th January 2022"}
          />

          <div className="icon-divider">
            <div className="icon-divider-wrap">
              <img src={IconVoucherGray} alt="" />
            </div>
          </div>

          <div className="exclusive-deal-textarea">
            <h4>Your Voucher Wallet</h4>
            <p>If already redeemed the voucher kindly ignore it</p>
          </div>

          <Row className="exclusive-deals">
            <Col s={6}>
              <a href="#modal-wallet-deal" className="modal-trigger">
                <ExclusiveDealCard
                  ExclusiveDealBrandLogo={LogoO2Spa}
                  expiryDate={"13 January 2024"}
                />
              </a>
            </Col>
            <Col s={6}>
              <a href="#modal-wallet-deal" className="modal-trigger">
                <ExclusiveDealCard
                  ExclusiveDealBrandLogo={LogoZomato}
                  expiryDate={"13 January 2024"}
                  isRedeemed={true}
                />
              </a>
            </Col>
          </Row> */}

          {/* {console.log(offers)}

          {console.log(offers !== [])} */}
          {pujoOffers.length ? (
            pujoOffers?.map((offer, idx) => {
              // console.log(offer);
              if (idx % 1 === 0) {
                color = "bg-blue";
              }
              if (idx % 2 === 0) {
                color = "bg-danger";
              }
              if (idx % 3 === 0) {
                color = "bg-success";
              }
              if (idx % 4 === 0) {
                color = "bg-purple";
              }
              if (offer.validity >= currentDateTime) {
                return (
                  <div key={idx}>
                    <a
                      className="modal-trigger"
                      href={`#modal__reward`}
                      onClick={() => setCardPopup(offer)}
                    >
                      <OfferCard
                        offerCardColor={color}
                        offerCardTitle={"Your Exclusive Deal"}
                        offerCardText={
                          // "Get Rs.800 worth bonus points added to your account on a shopping of Rs.8000 in store."
                          offer.description !== ""
                            ? offer.description
                            : "Use the coupon to get Special Offers"
                        }
                        offerCardValidity={
                          // "1st Nov’23 to 10th Dec’23"}
                          moment(
                            new Date(offer.validity)
                          ).format("DD/MM/YYYY HH:mm:ss")
                        }
                      />
                    </a>
                    {/* {console.log(offer.pin !== "N/A")} */}
                  </div>
                );
              } else {
                return "";
              }
            })
          ) : (
            <div></div>
          )}
          {
            (!pujoOffers && !offers) && <div className="icon-divider">
              <div className="icon-divider-wrap">
                <img src={IconVoucherGray} alt="" />
              </div>
            </div>
          }
          {
            offers.length ? <div>
              <div className="exclusive-deal-textarea">
                <h4>Your Voucher Wallet</h4>
                <p>If already redeemed the voucher kindly ignore it</p>
              </div>

              <Row className="exclusive-deals">
                {offers?.map(offer => {
                  return (<Col s={6}>
                    <a href="#modal-wallet-deal" onClick={() => setCardPopup(offer)}>
                      <ExclusiveDealCard
                        ExclusiveDealBrandLogo={offer.imageAdd}
                        expiryDate={formatdate(offer.validity)}
                      />
                    </a>
                  </Col>)
                })}
                {/* <Col s={6}>
                  <a href="#modal-wallet-deal" className="modal-trigger">
                    <ExclusiveDealCard
                      ExclusiveDealBrandLogo={LogoO2Spa}
                      expiryDate={"13 January 2024"}
                    />
                  </a>
                </Col>
                <Col s={6}>
                  <a href="#modal-wallet-deal" className="modal-trigger">
                    <ExclusiveDealCard
                      ExclusiveDealBrandLogo={LogoZomato}
                      expiryDate={"13 January 2024"}
                      isRedeemed={true}
                    />
                  </a>
                </Col> */}
              </Row>
            </div>
              : <div></div>
          }
          {
            (offers?.length == 0 && pujoOffers?.length == 0) && <div className="placeholder-msg">
              <div className="placeholder-msg__icon">
                <img src={IconOfferGray} alt="" />
              </div>
              <p className="placeholder-msg__text">
                We could not find any offers for you at the moment.
              </p>
            </div>
          }
          {/* <a href="#modal__reward" className="modal-trigger">
          <OfferCard
            offerCardColor="bg-danger"
            offerCardTitle={"Buy 1 Get 1"}
            offerCardText={
              "Use the Voucher code to get Rs.500 offer. The offer is valid for a Minimum purchase of Rs.5000.Use the MPIN: 268482 at the cash counter and redeem the coupon."
            }
            offerCardValidity={"11th January 2024"}
          />
          </a> */}
          {/* {
          <OfferCard
            offerCardColor="bg-purple"
            offerCardTitle={"Flat 10% OFF"}
            offerCardText={
              "Use the coupon to get 20% cashback on Code and Ginger footwear"
            }
            offerCardValidity={"11th January 2022"}
          />
          } */}
          {/* <div className="icon-divider">
            <div className="icon-divider-wrap">
              <img src={IconRewardGray} alt="" />
            </div>
          </div> */}
          {/* <p className="exclusive-deal-congrats">
            Congratulations on getting exclusive deals in Shoppers Stop today.
          </p> */}
          {/* <Row className="exclusive-deals">
            <Col s={6}>
              <a href="#modal-wallet-deal" className="modal-trigger">
                <ExclusiveDealCard
                  className="--orange"
                  ExclusiveDealCardTitle={"Buy 1 <br /> Get 1"}
                  expiryDate={"13 January 2024"}
                />
              </a>
            </Col> */}
          {/* <Col s={6}>
              <ExclusiveDealCard
                className="--red"
                ExclusiveDealCardTitle={"Lakme"}
                expiryDate={"31 Aug 2022, 23:59"}
              />
            </Col>
            <Col s={6}>
              <ExclusiveDealCard
                className="--blue"
                ExclusiveDealCardTitle={"Colorbar"}
                expiryDate={"31 Aug 2022, 23:59"}
              />
            </Col>
            <Col s={6}>
              <ExclusiveDealCard
                className="--skyblue"
                ExclusiveDealCardTitle={"Colorbar"}
                expiryDate={"31 Aug 2022, 23:59"}
              />
            </Col> */}
          {/* </Row> */}
        </Container>
        {/* <ModalCta
          id="modal-wallet-deal"
          className="modal-wallet-exclusive-deal text-center"
          contentBody={
            <div className="modal-body">
              <div className="wallet-exclusive-deal__icon">
                <img src={IconGift} />
              </div>
              <h4 className="wallet-exclusive-deal__title">
                Your Exclusive Deal
              </h4>
              <p className="wallet-exclusive-deal__text">
                Colorbar buy worth 2500 and redeem 750 points
              </p>
              <span className="offer-expiry-info">
                Valid for: 11th - 13rd January 2022
              </span>
              <Button
                node="button"
                waves="light"
                className="btn-blue btn-fluid"
                // onClick={reDirectToQR}
              >
                Close
              </Button>
              <hr />
              <Link to={"/terms-conditions"}>
                <small className="text-terms-condition">
                  Terms & Conditions apply
                </small>
              </Link>
            </div>
          }
        ></ModalCta> */}

        {/* Start: Modal Offer */}
        {openPopup && <ModalCentered
          id="modal__reward"
          className="modal__wallet-offer text-center"
          closeIcon={
            <a className="modal-close icon-cross icon-cross-dark" onClick={() => setOpenPopup(false)}></a>
          }
          contentBody={
            <div className="modal-body">
              {/* <div className="wallet-offer__icon">
                <img src={offerImage} alt="offer-percentage" />
              </div> */}
              <div className="brand-logo">
                <img src={offerImage} alt="brand-logo" />
              </div>
              <h2>Your Exclusive Offer</h2>
              {offerCode != 'N/A' && <div className="coupon-code-block">
                <small>Coupon code</small>
                <div className="coupon-code-wrap">
                  <span className="coupon-code">{offerCode}</span>
                </div>
              </div>}
              {offerpin != 'N/A' && <div className="coupon-code-block">
                <p>{offerDescription ? offerDescription : "Redeem your offer using the pin"}</p>
                <div className="coupon-code-wrap">
                  <span className="coupon-code">{offerpin}</span>
                </div>
              </div>}
              {offerDesc && <p className="offer-description">{offerDesc}</p>}
              <small className="redeem-text">If already redeemed the voucher kindly ignore it</small>
              {/* <small className="validity-text">Bonus points earned to be redeemable from 1st Nov’23 to 10th Dec’23</small> */}
              {offerValidity && <small className="validity-text">Valid till: {formatdate(offerValidity)}</small>}
              <hr />
              <Link
                to={"/voucher-terms-conditions"}
                className="tc-link">
                Terms and conditions apply <img src={IconExternalLink} alt="" />
              </Link>
              <Button
                node="button"
                waves="light"
                className="btn-primary btn-fluid text-uppercase modal-close"
                onClick={() => setOpenPopup(false)}
              >Done</Button>
            </div>
          }
        />}

        {loaderPopup ? (<div id="loading" class="loadme-overlay">
          <div class="loadme-circular"></div>
          <p class="loadme-text" id="st"></p>
        </div>
        ) : (
          ""
        )}
        {/* End: Modal Offer */}

        {/* Start: Redeem Offer */}
        {/* <ModalCentered
            id="modal__exclusive-deal"
            className="modal__wallet-offer text-center"
            closeIcon = {
                <a className="modal-close icon-cross icon-cross-dark"></a>
            }
            contentBody={
              <div className="modal-body">
                <div className="brand-logo">
                  <img src={LogoZomato} alt="" />
                </div>
                <h2>Your Exclusive Deal</h2>
                <div className="coupon-code-block">
                    <small>Coupon code</small>
                    <div className="coupon-code-wrap">
                        <span className="coupon-code">1000731245255037</span>
                    </div>
                </div>
                <div className="coupon-code-block">
                    <p>Redeem your offer using the pin</p>
                    <div className="coupon-code-wrap">
                        <span className="coupon-code">110002</span>
                    </div>
                </div>
                <small className="validity-text">Valid till: 10 Oct 2024  23:59:59</small>
                <hr />
                <Link 
                  to={"/terms-conditions"}
                  className="tc-link">
                  Terms and conditions apply <img src={IconExternalLink} alt="" />
                </Link>
                <Button
                  node="button"
                  waves="light"
                  className="btn-primary btn-fluid text-uppercase"
                >Done</Button>
              </div>
            }
          /> */}
        {/* End: Redeem Offer */}
      </div>
      {/* Footer Section */}
      <Footer />
      {/* Modal Offer Copon */}
    </div>
  );
}
