import { useState } from "react";
import { Navigate } from "react-router-dom";

function PublicRoute({ children }) {
  const [verify] = useState(localStorage.getItem("Verification"));
  // const [verify] = useState(true);
  // console.log(verify);
  return verify !== "true" ? children : <Navigate to="/home" />;
  // return children;
}
export default PublicRoute;
