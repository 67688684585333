import React from "react";
import "materialize-css/dist/css/materialize.min.css";
// import { Button } from "react-materialize";
import { QrReader } from "react-qr-reader";
import Header from "../components/layout/header.js";
// import { useNavigate } from "react-router-dom";

export default function QRcode() {
  // const navigate = useNavigate();

  function handelscan(result, error) {
    // console.log(result);
    if (!!result) {
      // console.log(result?.text);

      // navigate(result?.text);
      // window.location.href = `${result?.text}`;
      window.location.replace(`${result?.text}`);
      // window.location.replace("http://www.w3schools.com");
    }

    if (!!error) {
      console.info(error);
    }
  }
  // function handleDismiss() {
  //   navigate("/app/home");
  // }
  return (
    <div className="screen-wrap mx-auto">
      {/* Header Section */}
      <Header
        className="app-header app-header__gray"
        headertype={0}
        logoShow={1}
        redirectLink={'/first-citizen-enrollment'}
      />
      {/* Main Content */}
      <div className="main-wrap position-relative has-no-footer">
        <QrReader
          scanDelay={2000}
          onResult={handelscan}
          constraints={{ facingMode: "environment" }}
          className="app-scanner-block"
        ></QrReader>
        <div className="app-scanner">
          <div className="app-scanner-border">
            <div className="app-scanner-border__topleft"></div>
            <div className="app-scanner-border__topright"></div>
            <div className="app-scanner-border__bottomleft"></div>
            <div className="app-scanner-border__bottomright"></div>
          </div>
        </div>
        <div className="scanner-footer-section">
          <h6>Scan any QR code to know product details</h6>
          <p>
            Position your phone to make sure the QR code <br />
            is within the frame.
          </p>
        </div>
      </div>
    </div>
  );
}
