import {React,useState, useEffect} from "react";
// import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";
import { NavLink } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import ModalCentered from "../../components/modal-centered";
import { Row, Col, Button } from "react-materialize";

export default function Footer() {
  const [becomeAMember, setBecomeAMember] = useState('');
  
  function welcomeClosePopup() {
    setBecomeAMember(false);
  }
  const notAmember = () => {
    if(localStorage.getItem('tier') == "notMember"){
      setBecomeAMember(true)
    }
  }
  return (
    <div className="app-footer">
      <NavLink to={"/home"} activeClassName={"active"}>
        <div className="app-footer__icon waves-effect waves-light-dark waves-ripple">
          <i className="icon-footer__home"></i>
        </div>
      </NavLink>
      {localStorage.getItem('tier') != "notMember" ? <NavLink to={"/first-citizen-enrollment"} activeClassName={"active"}>
        <div className="app-footer__icon waves-effect waves-light-dark waves-ripple">
          <i className="icon-footer__profile"></i>
        </div>
      </NavLink> : <div activeClassName={"active"} onClick={()=> notAmember()}>
        <div className="app-footer__icon waves-effect waves-light-dark waves-ripple">
          <i className="icon-footer__profile"></i>
        </div>
      </div>}
      <NavLink to={"/my-wallet-of-offers"} activeClassName={"active"}>
        <div className="app-footer__icon waves-effect waves-light-dark waves-ripple">
          <i className="icon-footer__offers"></i>
        </div>
      </NavLink>
      <NavLink to={"/customer-care"} activeClassName={"active"}>
        <div className="app-footer__icon waves-effect waves-light-dark waves-ripple">
          <i className="icon-footer__customer-care"></i>
        </div>
      </NavLink>
      {/* <NavLink to={""}>
        <div className="app-footer__icon waves-effect waves-orange waves-ripple">
          <i className="icon-footer__barcode"></i>
        </div>
      </NavLink> */}
      {becomeAMember && <ModalCentered
        id="modal__user-enroll"
        className="modal__user-enroll"
        closeIcon={
          <a
            href="javascript:void(0)"
            waves="light"
            className="modal-close icon-cross icon-cross-dark"
            onClick={welcomeClosePopup}
          ></a>
        }
        header={
          <div className="modal-header">
            {/* <img className="brand-logo" src={logoFirstCitizenClub} /> */}
          </div>
        }
        contentBody={
          <div className="modal-body">
            <h4>Hi There,</h4>
            <p>You are not a Shoppers Stop First Citizen Club member. Kindly contact store person</p>
          </div>
        }
        footer={
          <div className="modal-footer">
            <Button
              node="button"
              waves="light"
              className="btn-primary btn-fluid modal-close"
              onClick={welcomeClosePopup}
            >
              Done
              {/* <i className="icon-arrow-right ml-10"></i> */}
            </Button>
          </div>
        }
      />}
    </div>
  );
}
