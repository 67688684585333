import React, { useEffect, useState } from "react";
import Header from "../components/layout/header";
import { Container, TextInput, Button } from "react-materialize";
import Error from "../components/error";
import { useNavigate } from "react-router-dom";
import { encryptData } from "./common-func";

import axios from "axios";

import { API_BASE_URL } from "../config/constant";

export default function Landing() {
  const navigate = useNavigate();

  // ************** variable declerations Start ****************

  // mobile number
  const [mobileNumber, setMobileNumber] = useState("");

  // submit enable/disable
  const [isSubmitDisable, setSubmitDisable] = useState(true);

  // error showing
  const [hasError, setHasError] = useState(false);

  // error object for error messages
  const errors = {};
  // error messages
  const [formErrors, setFormErros] = useState({});
  const [inputDisabled, setInputDisabled] = useState(false);
  // const [timeOut, setReqTimeout] = useState(false);
  const [time, setTime] = useState(60);
  // const [getOtpCount, setGetOtpCount] = useState(0);
  const [requestTimeout] = useState(localStorage.getItem("requestTimeout"));
  const [loaderInButton, setLoaderInButton] = useState(false);
  // time out

  const formatTime = (time) =>
    `${String(Math.floor(time / 60)).padStart(2, "0")}:${String(
      time % 60
    ).padStart(2, "0")}`;

  // ************** variable declerations ends ****************

  // ******************* functions start **********************

  useEffect(() => {
    if (localStorage.getItem("req_timer") !== null) {
      if (localStorage.getItem("req_timer") > 0) {
        // setTime(localStorage.getItem("req_timer"));
      }
    }
  }, []);

  useEffect(() => {
    if (requestTimeout !== null) {
      if (requestTimeout === "true") {
        // setInputDisabled(true);
        // errors.timeout = "please try again in ";
        // setFormErros(errors);

        // let timer = setInterval(() => {
        //   setTime((time) => {
        //     if (time === 0) {
        //       clearInterval(timer);
        //       localStorage.removeItem("req_timer");
        //       setInputDisabled(false);
        //       localStorage.removeItem("requestTimeout");
        //       return 0;
        //     } else {
        //       setTime(time - 1);
        //       localStorage.setItem("req_timer", time - 1);
        //       return time - 1;
        //     }
        //   });
        // }, 1000);
      }
    }
  }, []);

  // function onMobileChangeHandler(e) {
  //   setMobileNumber(e.target.value);
  // }
  function onMobileChangeHandler(e) {
    if(!isNaN(e.target.value)){
      setMobileNumber(e.target.value);
    }
    // if (isNaN(e.target.value)) {
    //   errors.validationError = "Enter Valid Mobile number";
    //   setFormErros(errors);
    //   setHasError(true);
    // }else{
    //   setFormErros({})
    //   setHasError(false)
    // }
  }
  // validation for mobilenumber length
  useEffect(() => {
    // debugger;
    if (mobileNumber.length > 10 || mobileNumber.length < 10) {
      setSubmitDisable(true);
      // console.log("t");
    } else {
      setSubmitDisable(false);
      // console.log("f");
    }
  }, [mobileNumber]);

  // disable every activity for 3 mins
  // useEffect(() => {
  //   if (requestTimeout !== null) {
  //     // debugger;
  //     if (requestTimeout === "true") {
  //       setInputDisabled(true);
  //       errors.timeout = "please try again in ";
  //       setFormErros(errors);
  //       setReqTimeout(true);
  //       const startTime = new Date().getTime();
  //       const timerId = setInterval(() => {
  //         if (new Date().getTime() - startTime > 180000) {
  //           clearInterval(timerId);
  //           return;
  //         }
  //         setTime((t) => t + 1);
  //       }, 1000);

  //       const switchReq = setTimeout(() => {
  //         setReqTimeout(false);
  //         setInputDisabled(false);
  //         localStorage.setItem("requestTimeout", false);
  //       }, 180000);
  //     }
  //   }
  // }, [requestTimeout]);
  // on submit
  function onSubmit() {
    
    if (
      mobileNumber.length < 10 ||
      mobileNumber.length > 10 ||
      isNaN(mobileNumber)
    ) {
      errors.validationError = "Enter Valid Mobile number";
      setFormErros(errors);
      setHasError(true);
    } else {
      if (
        parseInt(mobileNumber[0]) === 9 ||
        parseInt(mobileNumber[0]) === 8 ||
        parseInt(mobileNumber[0]) === 7 ||
        parseInt(mobileNumber[0]) === 6
      ) {
        setLoaderInButton(true);
        setSubmitDisable(true);
        const data = {
          mobile_number: mobileNumber,
        };
        axios
          .post(API_BASE_URL + "/verification/otp", data)
          .then((res) => {
            localStorage.setItem("mobile_no", encryptData(mobileNumber));
            localStorage.setItem("session_id", res.data.session_id);
            localStorage.setItem("auth_key", res.data.auth_key)
            setLoaderInButton(false);
            navigate("/verification");
          })
          .catch((err) => {
            alert(err);
            setSubmitDisable(false);
          });
      } else {
        errors.validationError = "Enter Valid Mobile number";
        setFormErros(errors);
        setHasError(true);
        // console.log(hasError);
      }
    }
  }

  // ******************* functions ends **********************

  return (
    <div className="screen-wrap">
      {/* Header Section */}
      <Header
        className="app-header app-header--unlock"
        headertype={0}
        logoShow={1}
        redirectLink={'/home'}
        // headerArrowType={'/home'}
      />

      <div className="main-wrap has-no-footer position-relative">
        <Container>
          <div className="app-unlock">
            <div className="app-unlock__header">
              <h1 className="app-unlock__title">
                Verify your <br />
                phone number
              </h1>
              <p className="app-unlock__desc">
                To verify the mobile number you have entered, We will send you a
                4 digit verification code.
              </p>
            </div>

            <div className="app-unlock__body">
              <div className="mobile-no-block">
                <div className="mobile-no">
                  <div className="mobile-no__prepend">
                    <i className="icon-mobile"></i>+91
                  </div>
                  <TextInput
                    id="mobile-no"
                    label="Enter your mobile number"
                    onChange={onMobileChangeHandler}
                    value={mobileNumber}
                    maxLength="10"
                    autoFocus
                    disabled={inputDisabled}
                    autoComplete={"false"}
                  ></TextInput>
                </div>
                {hasError ? <Error>{formErrors.validationError}</Error> : ""}
                {inputDisabled ? (
                  <Error>
                    {formErrors.timeout} {formatTime(time)}
                  </Error>
                ) : (
                  ""
                )}
              </div>
              <Button
                node="button"
                waves="light"
                className="btn-primary btn-fluid text-uppercase"
                onClick={onSubmit}
                disabled={isSubmitDisable}
              >
              {loaderInButton && <div className="spinner-border mr-10"></div>}Send Otp <i className="icon-arrow-right ml-10"></i>
              </Button>
            </div>

            <div className="app-unlock__footer">
              <p>
                By continuing, you agree to our <br />
                <a href="https://www.shoppersstop.com/term-condition" target="_blank" rel="noreferrer">
                  <strong>Terms & Conditions</strong>
                </a>
              </p>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
