import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "materialize-css/dist/css/materialize.min.css";
// import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Header from "../components/layout/header.js";
import Footer from "../components/layout/footer.js";
// import { Tabs, Tab } from "react-materialize";
// import IconTabDress from "../assets/img/icons/icon-tab__dress.svg";
// import IconTabSkirt from "../assets/img/icons/icon-tab__skirt.svg";
// import IconTabJeans from "../assets/img/icons/icon-tab__jeans.svg";
// import IconTabHeels from "../assets/img/icons/icon-tab__heels.svg";
// import IconTabPartyDress from "../assets/img/icons/icon-tab__party-dress.svg";
// import BannerTitleImage1 from "../assets/img/slide/banner-title-1.png";
// import BannerTitleImage2 from "../assets/img/slide/banner-title-2.png";
// import Banner1 from "../assets/img/slide/promotion-banner-1.png";
// import Banner2 from "../assets/img/slide/promotion-banner-2.png";
// import Banner3 from "../assets/img/slide/promotion-banner-3.png";
// import Banner4 from "../assets/img/slide/promotion-banner-4.png";
// import PromotionBanner from "../assets/img/slide/promotion-banner.png";

export default function Promotions() {
  const navigate = useNavigate();

  useEffect(() => {
    const headers = {
      AuthToken: localStorage.getItem("auth_key"),
      Otp: localStorage.getItem("otp")
    }
    if (headers.AuthToken) {
      console.log("enter")
    }
    else {
      navigate("/landing-page")
    }
  });

  return (
    <div className="screen-wrap">
      {/* Header Section */}
      <Header className="app-header" headertype={1} />

      {/* Main Content */}
      <div className="main-wrap">
        {/* Coming Soon */}
        <div className="d-table w-100 h-100">
          <div className="d-table-cell h-100 align-middle">
            <div className="coming-soon-block">
              <h6 className="coming-soon-text">
                Coming <br />
                Soon
              </h6>
            </div>
          </div>
        </div>
        {/* <div className="promotion-page">
          <div className="heading-section">
            <h2 className="heading-title">Promotions 🎉</h2>
            <p className="heading-desc">
              Best selling products. Explore with offers.
            </p>
          </div>
          <Tabs className="app-nav-tabs" scope="tabs-22">
            <Tab
              active
              options={{
                duration: 300,
                onShow: null,
                responsiveThreshold: Infinity,
                swipeable: false,
              }}
              title={
                <div className="nav-tab-menu">
                  <div className="nt-menu__icon-wrap bg-extra-light-yellow">
                    <i className="nt-menu__icon">
                      <img src={IconTabDress} alt="" />
                    </i>
                  </div>
                  <span className="nt-menu__text">All</span>
                </div>
              }
            >
              <div className="tab-content">
                <figure>
                  <img src={BannerTitleImage1} alt="" />
                </figure>
                <OwlCarousel
                  className="promotion-carousel owl-theme"
                  items={1.25}
                  nav={false}
                  rtl={true}
                  loop={true}
                  stagePadding={10}
                  margin={10}
                  dots={false}
                  autoplay={true}
                  autoplayTimeout={3000}
                  autoplayHoverPause={true}
                >
                  <div className="item first-item">
                    <a href="javascript:void(0)">
                      <img src={Banner1} alt="" />
                    </a>
                  </div>
                  <div className="item">
                    <a href="javascript:void(0)">
                      <img src={Banner2} alt="" />
                    </a>
                  </div>
                </OwlCarousel>

                <OwlCarousel
                  className="promotion-carousel owl-theme"
                  items={1.25}
                  nav={false}
                  rtl={true}
                  loop={true}
                  stagePadding={10}
                  margin={10}
                  dots={false}
                  autoplay={true}
                  autoplayTimeout={3500}
                  autoplayHoverPause={true}
                >
                  <div className="item first-item">
                    <a href="javascript:void(0)">
                      <img src={Banner3} alt="" />
                    </a>
                  </div>
                  <div className="item">
                    <a href="javascript:void(0)">
                      <img src={Banner4} alt="" />
                    </a>
                  </div>
                </OwlCarousel>
                <figure>
                  <img src={BannerTitleImage2} alt="" />
                </figure>
                <figure className="promotion-banner">
                  <a href="javascript:void(0)">
                    <img src={PromotionBanner} alt="" />
                  </a>
                </figure>
                <figure className="promotion-banner">
                  <a href="javascript:void(0)">
                    <img src={Banner4} alt="" />
                  </a>
                </figure>
              </div>
            </Tab>
            <Tab
              options={{
                duration: 300,
                onShow: null,
                responsiveThreshold: Infinity,
                swipeable: false,
              }}
              title={
                <div className="nav-tab-menu">
                  <div className="nt-menu__icon-wrap bg-extra-light-green">
                    <i className="nt-menu__icon">
                      <img src={IconTabDress} alt="" />
                    </i>
                  </div>
                  <span className="nt-menu__text">Dress</span>
                </div>
              }
            >
              <div className="tab-content">
                <figure>
                  <img src={BannerTitleImage1} alt="" />
                </figure>
                <figure className="promotion-banner">
                  <a href="javascript:void(0)">
                    <img src={PromotionBanner} alt="" />
                  </a>
                </figure>
                <figure className="promotion-banner">
                  <a href="javascript:void(0)">
                    <img src={Banner4} alt="" />
                  </a>
                </figure>
              </div>
            </Tab>
            <Tab
              options={{
                duration: 300,
                onShow: null,
                responsiveThreshold: Infinity,
                swipeable: false,
              }}
              title={
                <div className="nav-tab-menu">
                  <div className="nt-menu__icon-wrap bg-extra-light-purple">
                    <i className="nt-menu__icon icon-tab__skirt">
                      <img src={IconTabSkirt} alt="" />
                    </i>
                  </div>
                  <span className="nt-menu__text">Skirt</span>
                </div>
              }
            >
              <div className="tab-content">
                <figure>
                  <img src={BannerTitleImage2} alt="" />
                </figure>
                <figure className="promotion-banner">
                  <a href="javascript:void(0)">
                    <img src={Banner4} alt="" />
                  </a>
                </figure>
              </div>
            </Tab>
            <Tab
              options={{
                duration: 300,
                onShow: null,
                responsiveThreshold: Infinity,
                swipeable: false,
              }}
              title={
                <div className="nav-tab-menu">
                  <div className="nt-menu__icon-wrap bg-extra-light-blue">
                    <i className="nt-menu__icon icon-tab__jeans">
                      <img src={IconTabJeans} alt="" />
                    </i>
                  </div>
                  <span className="nt-menu__text">Jeans</span>
                </div>
              }
            >
              <div className="tab-content">No Content Available</div>
            </Tab>
            <Tab
              options={{
                duration: 300,
                onShow: null,
                responsiveThreshold: Infinity,
                swipeable: false,
              }}
              title={
                <div className="nav-tab-menu">
                  <div className="nt-menu__icon-wrap bg-extra-light-pink">
                    <i className="nt-menu__icon icon-tab__heels">
                      <img src={IconTabHeels} alt="" />
                    </i>
                  </div>
                  <span className="nt-menu__text">High Heels</span>
                </div>
              }
            >
              <div className="tab-content">No Content Available</div>
            </Tab>
            <Tab
              options={{
                duration: 300,
                onShow: null,
                responsiveThreshold: Infinity,
                swipeable: false,
              }}
              title={
                <div className="nav-tab-menu">
                  <div className="nt-menu__icon-wrap bg-extra-light-yellow">
                    <i className="nt-menu__icon icon-tab__party-dress">
                      <img src={IconTabPartyDress} alt="" />
                    </i>
                  </div>
                  <span className="nt-menu__text">Party Dress</span>
                </div>
              }
            >
              <div className="tab-content">No Content Available</div>
            </Tab>
          </Tabs>
        </div> */}
      </div>

      {/* Footer Section */}
      <Footer />
    </div>
  );
}
