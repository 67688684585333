import React, { useEffect, useState } from "react";
import Header from "../components/layout/header";
import { Tabs, Tab } from "react-materialize";
// import LogoMakeMyTrip from "../assets/img/partner-brand_makemytrip.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../config/constant";

export default function VoucherTermsConditions() {
    const navigate = useNavigate();
    const [description, setDescription] = useState("");
    const [terms, setTerms] = useState("");
    useEffect(() => {
        const data = {
            sku: localStorage.getItem("selectedBrand") ? localStorage.getItem("selectedBrand") : "CNPIN"
        };
        const headers = {
            AuthToken: localStorage.getItem("auth_key"),
            Otp: localStorage.getItem("otp")
        }
        if (headers.AuthToken) {
            axios
                .post(API_BASE_URL + "/product_details", data, { headers: headers })
                .then((res) => {
                    // debugger;
                    // localStorage.setItem("mobile_no", mobileNumber);
                    if (res.data.code == 403) {
                        localStorage.clear();
                        navigate("/landing-page");
                    }
                    else {
                        // localStorage.setItem("session_id", res.data.session_id);
                        // navigate("/redeemVerification");
                        setDescription(res?.data?.description)
                        const termsString = res?.data?.tnc?.content;
                        // const urlRegex = /(https?:\/\/[^\s]+)/g;
                        const modifiedTerms = termsString
                            .replace(/<br\s*\/?>/g, '<li>')
                            .replace(/(<a\b[^>]*>.*?<\/a>|https?:\/\/[^\s]+|www\.[^\s]+)/g, (match, p1) => {
                                // If the match is an existing <a> tag, skip replacement
                                if (match.startsWith('<a')) {
                                  return match;
                                }
                                // If the match is a URL, replace it with an <a> tag
                                return `<a href="${match.startsWith('www') ? 'http://' + match : match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
                              })
                              .replace(/\S+@\S+\.\S+/, (match, p1) => {
                                if (match.startsWith('<a') || match.startsWith('href')) {
                                    return match;
                                  }
                                return `<a href="mailto:${match}">${match}</a>`;
                              })
                              .replace(/(?:[-+() ]*\d){10,13}/gm, (match, p1) => {
                                if (match.startsWith('<a') || match.startsWith('href')) {
                                    return match;
                                  }
                                  return `<a href="tel:${match}">${match}</a>`;
                              })
                        setTerms(modifiedTerms)
                    }
                })
                .catch((err) => {
                    alert(err);
                });
        }
        else {
            navigate("/landing-page")
        }
    }, [])
    // console.log(terms)
    return (
        <div className="screen-wrap">
            {/* Header Section */}
            <Header
                className="app-header has-border"
                headertype={0}
                logoShow={1}
                redirectLink={localStorage.getItem('currentPage') == '/redeem-your-points' ? '/redeem-your-points' : '/my-wallet-of-offers'}
            />
            {/* Main Section */}
            <div className="main-wrap has-no-footer">
                <div className="voucher-doc">
                    <div className="voucher-img">
                        <img src={localStorage.getItem('selectedPartners')} alt="" />
                    </div>
                    <h4 className="voucher-title">Details</h4>
                    <Tabs
                        // className="tabs-content"
                        // options={{
                        //     swipeable: true
                        // }}
                        scope="tabs-22"
                    >
                        <Tab
                            active
                            options={{
                                duration: 300,
                                onShow: null,
                                responsiveThreshold: Infinity,
                                swipeable: false
                            }}
                            title="Product Description"
                        >
                            <ul className="unordered-list">
                                {/* <li>Gift vouchers can be redeemed against all products only at Terrace Men stores.</li>
                                <li>If your purchase exceeds the value of the gift voucher, balance must be paid by cash, debit or credit card.</li>
                                <li>Gift vouchers can be redeemed against all products only at Terrace Men stores.</li>
                                <li>If your purchase exceeds the value of the gift voucher, balance must be paid by cash, debit or credit card.</li>
                                <li>Gift vouchers can be redeemed against all products only at Terrace Men stores.</li>
                                <li>If your purchase exceeds the value of the gift voucher, balance must be paid by cash, debit or credit card.</li> */}
                                <li>{description}</li>
                            </ul>
                        </Tab>
                        <Tab
                            options={{
                                duration: 300,
                                onShow: null,
                                responsiveThreshold: Infinity,
                                swipeable: false
                            }}
                            title="How to redeem"
                        >
                            <ul className="unordered-list">
                                <li>Gift vouchers can be redeemed against all products only at Terrace Men stores.</li>
                                <li>If your purchase exceeds the value of the gift voucher, balance must be paid by cash, debit or credit card.</li>
                                <li>Gift vouchers can be redeemed against all products only at Terrace Men stores.</li>
                            </ul>
                        </Tab>
                        <Tab
                            options={{
                                duration: 300,
                                onShow: null,
                                responsiveThreshold: Infinity,
                                swipeable: false
                            }}
                            title="T&C"
                        >
                            {/* <ul className="unordered-list">
                                <li>Gift vouchers can be redeemed against all products only at Terrace Men stores.</li>
                                <li>If your purchase exceeds the value of the gift voucher, balance must be paid by cash, debit or credit card.</li>
                                <li>Gift vouchers can be redeemed against all products only at Terrace Men stores.</li>
                                <li>If your purchase exceeds the value of the gift voucher, balance must be paid by cash, debit or credit card.</li>
                                <li>Gift vouchers can be redeemed against all products only at Terrace Men stores.</li>
                                <li>If your purchase exceeds the value of the gift voucher, balance must be paid by cash, debit or credit card.</li>
                            </ul> */}
                            {/* <div> */}
                            {/* {terms?.content} */}
                            <div dangerouslySetInnerHTML={{ __html: `<ul class="unordered-list"><li>${terms}</li></ul>` }} />
                            {/* </div> */}
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}