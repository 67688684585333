import React, { useState, useEffect } from "react";
import Header from "../components/layout/header";
import { Container, TextInput, Button } from "react-materialize";
import { ClientJS } from "clientjs";
import axios from "axios";
import { API_BASE_URL } from "../config/constant";
import { Link, useNavigate } from "react-router-dom";
import Error from "../components/error";
import ModalCentered from "../components/modal-centered.js";
import M from 'materialize-css';
import IconExternalLink from "../assets/img/icons/icon_external-link.svg";
import { decryptData } from "./common-func";


export default function RedeemOtpVerification() {
    localStorage.setItem("currentPage",'/redeemVerification')
    // debugger;

    // {/* Toast Function */}
    const showToast = (message) => {
        M.toast({
            html: message,
            classes: "justify-content-center",
            displayLength: 4000,
        });
    };

    const navigate = useNavigate();
    const client = new ClientJS();

    const [mobileNumber] = useState(
        localStorage.getItem("mobile_no")
    );
    const [recivedOTP, setOTP] = useState("");

    // const [sessionId, setSessionId] = useState(
    //     localStorage.getItem("session_id")
    // );

    const [formErrors, setFormErros] = useState("");

    const [incorrectOTP, setIncorrectOTP] = useState(false);

    const [resendCount, setResendCound] = useState(0);

    const [tryCount, setTryCount] = useState(0);

    const [retTryCount] = useState(3);

    // otp resend timer
    const [time, setTime] = useState(120);

    // disable resend otp
    const [diableResend, setDiableResend] = useState(true);

    const [redeemSuccess, setRedeemSuccess] = useState(false);
    const [cardDetail, setCardDetail] = useState(null);
    const [redeemUnsuccess, setRedeemUnsuccess] = useState(false);
    const [multiCards, setMultiCard] = useState(false);
    const [loaderInButton, setLoaderInButton] = useState(false);

    const pointsToRedeem = localStorage.getItem('totalFirstCitizenPoints');
    const userData = localStorage.getItem('userPersonal');
    // console.log(pointsToRedeem)

    useEffect(() => {
        if (localStorage.getItem("timer") !== null) {
            if (localStorage.getItem("timer") > 0) {
                setTime(localStorage.getItem("timer"));
            }
        }
    }, []);
    useEffect(() => {
        let timer = setInterval(() => {
            setTime((time) => {
                if (time === 0) {
                    clearInterval(timer);
                    localStorage.removeItem("timer");
                    setDiableResend(false);
                    return 0;
                } else {
                    setTime(time - 1);
                    localStorage.setItem("timer", time - 1);
                    return time - 1;
                }
            });
        }, 1000);
    }, [resendCount]);

    const formatTime = (time) =>
        `${String(Math.floor(time / 60)).padStart(2, 0)}:${String(
            time % 60
        ).padStart(2, "0")}`;

    // resend otp
    function otpResendHandler() {
        setResendCound(resendCount + 1);
        // console.log(resendCount);
        setDiableResend(true);
        setTime(120);
        if (resendCount >= 3) {
            setResendCound(0);
            localStorage.setItem("requestTimeout", true);
            navigate("/landing-page");
        } else {
            const data = {
            };

            axios
                .post(API_BASE_URL + "/verification/otp", data)
                .then((res) => {
                    localStorage.setItem("session_id", res.data.session_id);
                })
                .catch((err) => {
                    alert(err);
                });
        }
    }

    function formatdate(date) {
        // debugger;
        // console.log(date);
        const inputDate_1 = date;

        const data = inputDate_1.replace('T', ' ').split("+")[0];
        const inputDate = new Date(data);

        const options = {
            // day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };

        const formattedDate = inputDate.toLocaleString('en-US', options);

        // console.log(formattedDate);
        // return formattedDate
        const dayWithSuffix = addSuffixToNumber(inputDate.getDate());
        // console.log(`${dayWithSuffix} ${formattedDate}`);
        return `${dayWithSuffix} ${formattedDate}`
    }

    function addSuffixToNumber(number) {
        if (number >= 11 && number <= 13) {
            return `${number}th`;
        } else {
            const lastDigit = number % 10;
            switch (lastDigit) {
                case 1:
                    return `${number}st`;
                case 2:
                    return `${number}nd`;
                case 3:
                    return `${number}rd`;
                default:
                    return `${number}th`;
            }
        }
    }

    // otp auto load
    // useEffect(() => {
    //   // console.log("auto read");
    //   if ("OTPCredential" in window) {
    //     const ac = new AbortController();

    //     navigator.credentials
    //       .get({
    //         otp: { transport: ["sms"] },
    //         signal: ac.signal,
    //       })
    //       .then((otp) => {
    //         console.log(otp.code);
    //         setOTP(otp.code);

    //         ac.abort();
    //       })
    //       .catch((err) => {
    //         const effect = setTimeout(() => {
    //           ac.abort();
    //           console.log(err);
    //         }, 1000);
    //       });
    //   }
    // }, []);

    // handle change in otp input
    function handleChange(e) {
        // debugger;
        // const { value, name } = e.target;

        // setValue({
        //   ...ssnValues,
        //   [name]: value,
        // });
        setOTP(e.target.value);
    }

    // verfication

    function verificationHandler() {
        // console.log(recivedOTP);
        // console.log(typeof recivedOTP);
        setLoaderInButton(true);
        if (recivedOTP !== "") {
            if (recivedOTP.length < 4 || recivedOTP.length > 4) {
            } else {
                if (isNaN(parseInt(recivedOTP))) {
                    setIncorrectOTP(true);
                    setFormErros("Enter Valid OTP");
                    setLoaderInButton(false);
                    return;
                }
                const data = {
                    session_id: localStorage.getItem("session_id"),
                    otp: recivedOTP,
                };
                const headers = {
                    AuthToken: localStorage.getItem("auth_key"),
                    Otp: localStorage.getItem("otp")
                }
                // console.log(data);

                axios
                    .post(API_BASE_URL + "/verification/otp/status", data, { headers: headers })
                    .then((res) => {
                        // debugger;
                        // otp invalid
                        if (res.data.code === 403) {
                            navigate("/landing-page");
                        }
                        else {
                            if (res.data.verified_status !== "True") {
                                setTryCount(tryCount + 1);
                                if (tryCount >= 3) {
                                    setTryCount(0);
                                    localStorage.setItem("requestTimeout", true);
                                    navigate("/landing-page");
                                } else {
                                    setIncorrectOTP(true);
                                    // debugger;
                                    setFormErros(
                                        "The OTP entered is incorrect. Please try again. You have " +
                                        (retTryCount - tryCount) +
                                        " more attempt."
                                    );
                                    setLoaderInButton(false);
                                    // console.log(retTryCount - tryCount);
                                    if (retTryCount - tryCount === 0) {
                                        setTryCount(0);
                                        localStorage.setItem("requestTimeout", true);
                                        navigate("/landing-page");
                                    }
                                }
                            } else {
                                // localStorage.setItem("tour", "false");
                                // localStorage.setItem("access_token", res.data.access_token);
                                // localStorage.setItem("AuthToken", res.data.AuthToken);
                                // localStorage.setItem("refresh_token", res.data.refresh_token);
                                // localStorage.setItem("user_id", res.data.user_id);
                                // localStorage.setItem("newLogin", true);

                                mobileDetails();
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        } else {
            setIncorrectOTP(true);
            setFormErros("Enter Valid OTP");
            setLoaderInButton(false);
        }
    }

    function setAuthToken(authToken) {
        var date_ = new Date();
        date_.setTime(date_.getTime() + 2 * 24 * 60 * 60 * 1000);
        var expires = "expires=" + date_.toGMTString();
        document.cookie = "authToken = " + authToken + ";##SEPERATOR###" + expires;

        localStorage.setItem("Verification", true);
    }

    function mobileDetails() {
        var keyId = localStorage.getItem("key_id");
        setAuthToken(mobileNumber);
        var mobileUrl = API_BASE_URL + "/mobiledetails";
        var date = new Date();
        var current_date = date.getDate();
        var current_month = date.getMonth() + 1;
        var current_year = date.getFullYear();
        // var hour = date.getHours();
        // var minutes = date.getMinutes();
        // var seconds = date.getSeconds();
        // var time = hour + ":" + minutes + ":" + seconds;
        date = current_date + "-" + current_month + "-" + current_year + ",";
        var postData = {
            browser: client.getBrowser(),
            browserVersion: client.getBrowserVersion(),
            os: client.getOS(),
            osVersion: client.getOSVersion(),
            keyId: keyId,
        };
        const headers = {
            AuthToken: localStorage.getItem("auth_key"),
            Otp: localStorage.getItem("otp")
        }
        // debugger;
        // if (keyId === null || keyId === "null") {
        //     localStorage.setItem("first_login", "missed_call");
        // } else {
        //     localStorage.setItem("first_login", true);
        // }
        // console.log(postData);
        if (headers.AuthToken) {
            axios
                .post(mobileUrl, postData, { headers: headers })
                .then((res) => {
                    // debugger;
                    if (res.data.code === 403) {
                        localStorage.clear();
                        navigate("/landing-page");
                    }
                    else {
                        if (res.data !== "") {
                            localStorage.removeItem("timer");
                            // const data = {
                            //     member_id: localStorage.getItem("memberId"),
                            // };
                            // const headers = {
                            //     AuthToken: localStorage.getItem("auth_key"),
                            //     Otp: localStorage.getItem("otp")
                            // }
                            // axios
                            //     .post(API_BASE_URL + "/get_loyalty_points", data, { headers: headers })
                            //     .then((res) => {
                            //         const loyaltyResponse = res.data.GetGroupLoyaltyPointsResponse;
                            //         if (res.data.code == 403) {
                            //             localStorage.clear();
                            //             navigate("/landing-page");
                            //         }
                            //         else {
                            //             if (parseInt(loyaltyResponse?.RedeemablePointsValue) > pointsToRedeem) {
                            // var user = decryptData(userData, 0);
                            const data = {
                                numberOfVouchers: localStorage.getItem("totalVouchers"),
                                points: (pointsToRedeem * 0.25).toString(),
                                // firstname: user.first_name,
                                // lastname: user.last_name,
                                // email: user.email,
                                sku: localStorage.getItem("selectedBrand")
                            }
                            axios
                                .post(API_BASE_URL + "/place_order", data, { headers: headers })
                                .then((res) => {
                                    if (res.data.code === 403) {
                                        localStorage.clear();
                                        navigate("/landing-page");
                                    }
                                    else {
                                        if (res.data.code !== 400) {
                                            if (res?.data?.cards.length > 1) {
                                                setMultiCard(true)
                                            }
                                            setCardDetail({
                                                cardNumber: res?.data?.cards[0]?.cardNumber,
                                                cardPin: res?.data?.cards[0]?.cardPin,
                                                cardValidity: res?.data?.cards[0]?.validity,
                                                cardamount: res?.data?.cards[0]?.amount
                                            })

                                            const data = {
                                                // member_id: localStorage.getItem("memberId"),
                                                // name: user.first_name,
                                                points: pointsToRedeem.toString(),
                                            };
                                            // const headers = {
                                            //     AuthToken: localStorage.getItem("auth_key"),
                                            //     Otp: localStorage.getItem("otp")
                                            // }
                                            axios
                                                .post(API_BASE_URL + "/redeem_loyalty_points", data, { headers: headers })
                                                .then((res) => {
                                                    if (res.data.code === 403) {
                                                        localStorage.clear();
                                                        navigate("/landing-page");
                                                    }
                                                    else {
                                                        if (res?.data?.RedeemGroupLoyaltyPointsResponse?.Status == 1) {
                                                            console.log("points redeemed");
                                                            setLoaderInButton(false);
                                                            setRedeemSuccess(true);
                                                            localStorage.setItem("otpRedeemed", "success")
                                                        }
                                                        else {
                                                            showToast("Invalid denomination, ttry with another denomination!");
                                                            navigate('/voucher-terms-conditions')
                                                        }
                                                    }
                                                })

                                        }
                                        else {
                                            if (res?.data?.message === "Activation failed.") {
                                                showToast("Activation is failed, Try with another brand!");
                                            }
                                            else if (res?.data?.message === "Invalid denomination") {
                                                showToast("Invalid denomination, Try with another denomination!");
                                            }
                                            else if (res?.data?.message === "Zero denomination") {
                                                showToast("Invalid value, Choose any denomination!");
                                            }
                                            else if (res?.data?.message === "Place Order Failed") {
                                                showToast("Place Order is Failed, Try again with another brand!");
                                            }
                                            else if (res?.data?.message === "Order in Processing") {
                                                showToast("Order Place is in Processing, Check again after some time!")
                                            }
                                            else if (res?.data?.message === "The SKU DISABLEDSTS which you are looking is not available") {
                                                showToast("The SKU DISABLEDSTS which you are looking is not available!")
                                            }
                                            else {
                                                showToast("Currently you are not able to redeem in this brand, try another!");
                                            }
                                            navigate('/voucher-terms-conditions')
                                        }
                                    }
                                })
                            // }
                            // else {
                            //     setRedeemUnsuccess(true);
                            // }
                            // }
                            // })

                            // navigate("/deliver-voucher");
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        else {
            navigate("/landing-page")
        }
    }

    // function onChangeNUmberClickHandler() {
    //     localStorage.removeItem("timer");
    //     navigate("/landing-page");
    // }

    return (
        <div className="screen-wrap">
            {/* Header Section */}
            <Header
                className="app-header"
                headertype={0}
                logoShow={1}
                redirectLink={'/deliver-voucher'}
            />

            <div className="main-wrap has-no-footer position-relative">
                <Container>
                    <div className="app-unlock">
                        <div className="app-unlock__header">
                            <h1 className="app-unlock__title">Order Verification</h1>
                            <p className="app-unlock__desc">
                                We have sent an OTP on your number to
                            </p>
                            <p className="user-mobile-no">
                                (+91) {decryptData(mobileNumber, 1)}{" "}
                                {/* <Button onClick={onChangeNUmberClickHandler} flat>
                                    Change
                                </Button> */}
                            </p>
                        </div>
                        <div className="app-unlock__body">
                            <div className="mobile-no-block">
                                <div className="mobile-no">
                                    <TextInput
                                        id="mobile-no"
                                        label="Enter OTP"
                                        value={recivedOTP}
                                        onChange={handleChange}
                                        maxLength={4}
                                    ></TextInput>
                                </div>
                                {incorrectOTP ? <Error>{formErrors}</Error> : ""}
                            </div>
                            <Button
                                node="button"
                                waves="light"
                                className="btn-primary btn-fluid text-uppercase"
                                onClick={verificationHandler}
                            >
                                {loaderInButton && <div className="spinner-border mr-10"></div>}Verify Now <i className="icon-arrow-right ml-10"></i>
                            </Button>

                            <h6 className="otp-countdown-timer">
                                <strong>{formatTime(time)}</strong> Sec left
                            </h6>
                            <div className="otp-resend">
                                <p>Didn’t receive a code?</p>
                                <Button
                                    node="button"
                                    flat
                                    disabled={diableResend}
                                    onClick={otpResendHandler}
                                >
                                    Resend a new code
                                </Button>
                            </div>
                        </div>
                    </div>


                    {redeemSuccess && <ModalCentered
                        id="modal__exclusive-deal"
                        className="modal__wallet-offer text-center"
                        closeIcon={
                            <a className="modal-close icon-cross icon-cross-dark"
                                onClick={() => navigate('/voucher-terms-conditions')}
                            ></a>

                        }
                        contentBody={
                            <div className="modal-body">
                                <div className="brand-logo">
                                    <img src={localStorage.getItem("selectedPartners")} alt="" />

                                </div>
                                <h2>Your Exclusive Deal</h2>
                                {cardDetail?.cardNumber && <div className="coupon-code-block">
                                    <small>Coupon code</small>
                                    <div className="coupon-code-wrap">
                                        <span className="coupon-code">{cardDetail?.cardNumber}</span>
                                    </div>
                                </div>}
                                <div className="coupon-code-block">
                                    <p>Redeem your offer using the pin for amount of <strong><span className="inr-symbol ml-5">₹</span>{cardDetail?.cardamount}</strong></p>
                                    <div className="coupon-code-wrap">
                                        <span className="coupon-code">{cardDetail?.cardPin}</span>
                                    </div>
                                </div>
                                <small>valid till: </small><small className="validity-text">{formatdate(cardDetail.cardValidity)}</small>
                                <hr />
                                {multiCards && <p>you are having multiple vouchers, Refer Display offer to redeem.</p>}
                                <Link
                                    to={"/voucher-terms-conditions"}
                                    className="tc-link">
                                    Terms and conditions apply <img src={IconExternalLink} alt="" />
                                </Link>
                                <Button
                                    node="button"
                                    waves="light"
                                    className="btn-primary btn-fluid text-uppercase"
                                    onClick={() => navigate("/my-wallet-of-offers")}
                                >Explore Now</Button>
                            </div>
                        }
                    />

                    }
                    {redeemUnsuccess && <ModalCentered
                        id="modal__reward"
                        className="modal_pujo-offer text-center"
                        closeIcon={
                            <a
                                // href="javascript:void(0)"
                                waves="light"
                                className="modal-close icon-cross icon-cross-dark"
                                onClick={() => navigate('/voucher-terms-conditions')}
                            ></a>
                        }
                        contentBody={
                            <div className="modal-body">
                                <h2>Your Exclusive Deal</h2>
                                {/* <div className="coupon-code-block">
                                    <small>Coupon code</small>
                            <div className="coupon-code-wrap">
                                <span className="coupon-code">9000002223896157184</span>
                            </div>
                                </div> */}
                                <p className="offer-description">
                                    {/* Use the Voucher code to get Rs.500 offer. The offer is valid for a Minimum purchase of Rs.5000.Use the MPIN: {cardData.pin} at the cash counter and redeem the coupon. */}
                                    You don't have enough redeemable points.<br />
                                </p>
                                {/* <small className="validity-text">Bonus points earned to be redeemable from 1st Nov’23 to 10th Dec’23</small> */}
                                {/* <small className="redeem-text">If already redeemed the voucher kindly ignore it</small> */}
                                {/* <small className="validity-text">
                            {formatValidDate(cardData?.validity)}
                        </small> */}
                            </div>
                        }
                        footer={
                            <div className="modal-footer">
                                <a href="https://www.shoppersstop.com/term-condition" target="_blank">
                                    <span className="terms-text">Terms & Conditions apply</span>
                                </a>
                                <Button
                                    node="button"
                                    waves="light"
                                    className="btn-primary btn-fluid text-uppercase modal-close"
                                    onClick={() => navigate("/my-wallet-of-offers")}
                                >Explore Now <i className="icon-arrow-right ml-10"></i></Button>
                            </div>
                        }
                    />}
                </Container>
            </div>
        </div>
    );
}
