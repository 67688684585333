import React from "react";
import "materialize-css/dist/css/materialize.min.css";
// import logoBrand from "../assets/img/logo_brand.svg";
import Header from "../components/layout/header";
import { Container } from "react-materialize";

export default function TermsConditions() {
  return (
    <div className="screen-wrap">
      {/* Header Section */}
      <Header
        className="app-header"
        headertype={0}
        logoShow={1}
        redirectLink={'/home'}
      />

      <div className="main-wrap has-no-footer">
        <Container className="w-100 h-100">
          <div className="app-tc-page">
            <h2 className="app-tc__title">Terms & Conditions</h2>
            <p className="app-tc__desc">This document is an electronic record in terms of  Information Technology Act, 2000 & rules thereunder as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This
              electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
            <h4 className="app-tc__sub-title">T&Cs for Shoppers Stop Coupons & Discounts</h4>
            <ul className="app-tc__list">
              <li className="app-tc__list-item">
              The offer is valid only for orders placed on <a href="www.shoppersstop.com">www.shoppersstop.com</a> and on Shopping App
              </li>
              <li className="app-tc__list-item">Maximum discount applicable is Rs.800</li>
              <li className="app-tc__list-item">To avail this discount, please type the coupon code in the ‘Coupon Code’ box provided on the shopping bag page during checkout</li>
              <li className="app-tc__list-item">Coupon not applicable on purchase of more than 5 products in one transaction/order.</li>
              <li className="app-tc__list-item">Coupon will not be applicable during End of Season Sale.</li>
              <li className="app-tc__list-item">The offer is valid only for successful/approved transactions.</li>
              <li className="app-tc__list-item">No two offers can be available simultaneously.</li>
            </ul>
          </div>
        </Container>
      </div>
    </div>
  );
}
