import React from "react";
import { Container } from "react-materialize";
import Header from "../components/layout/header.js";
import Footer from "../components/layout/footer.js";


export default function AvailablePoints() {
    var userBalanceString = localStorage.getItem("userBalance");
    var userBalance = JSON.parse(userBalanceString);
    let primaryPoint = 0;
    let secondaryPoint = 0;
    let citiPoint = 0;
    let hdfcPoint = 0;
    let totalBalance = 0;
    for (const balance of userBalance) {
        const { loyalty_account, balance: accountBalance } = balance;
        
        // Check the loyalty_account and update the corresponding variable
        if (loyalty_account === "FC Points") {
            primaryPoint = parseFloat(accountBalance) || 0;
        } else if (loyalty_account === "Citi Points") {
            citiPoint = parseFloat(accountBalance) || 0;
        } else if (loyalty_account === "FC Points-Secondary") {
            secondaryPoint = parseFloat(accountBalance) || 0;
        } else if (loyalty_account === "HDFC Points") {
            hdfcPoint = parseFloat(accountBalance) || 0;
        }
    }
    totalBalance = primaryPoint + secondaryPoint + citiPoint + hdfcPoint;
    return (
        <>
            <div className="screen-wrap">
                {/* Header Section */}
                <Header
                    className="app-header"
                    headertype={0}
                    logoShow={1}
                    redirectLink={'/home'}
                />
                {/* Main Wrap */}
                <div className="main-wrap">
                    <Container>
                        <div className="card-flush bg-primary mt-35">
                            <div className="card-body">
                                <h5 className="card-title">Total Available Points</h5>
                                <h3 className="card-points">{totalBalance} <span>= {(totalBalance * 0.25).toFixed(2)}</span></h3>
                                <small className="expiry-date">Total points expiring on 2nd Jan 2024</small>
                            </div>
                        </div>
                        <div className="reward-points">
                            <label className="reward-points__label">Primary Points - <span className="reward-points__value">{primaryPoint}</span></label>
                            <p className="reward-points__expiry">Expiring on 2nd Jan 2024</p>
                        </div>
                        <div className="reward-points">
                            <label className="reward-points__label">Secondary Points - <span className="reward-points__value">{secondaryPoint}</span></label>
                            <p className="reward-points__expiry">Expiring on 2nd Jan 2024</p>
                        </div>
                        <div className="reward-points">
                            <label className="reward-points__label">Citi Points - <span className="reward-points__value">{citiPoint}</span></label>
                            <p className="reward-points__expiry">Expiring on 2nd Jan 2024</p>
                        </div>
                        <div className="reward-points">
                            <label className="reward-points__label">HDFC Points - <span className="reward-points__value">{hdfcPoint}</span></label>
                            <p className="reward-points__expiry">Expiring on 2nd Jan 2024</p>
                        </div>
                    </Container>
                </div>
                {/* Footer Section */}
                <Footer />
            </div>
        </>
    )
}