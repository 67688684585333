import React, { useEffect, useState } from "react";
import Header from "../components/layout/header";
import { Container, Button, TextInput } from "react-materialize";
// import LogoMakeMyTrip from "../assets/img/partner-brand_makemytrip.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../config/constant";
import M from 'materialize-css';

export default function VoucherOrderSummary() {
    const navigate = useNavigate();
    // const textInputRef = useRef(null);
    const [imageDisplay] = useState(localStorage.getItem("selectedPartners"))
    const [quantity, setQuantity] = useState(1);
    const [denomination, setDenomination] = useState(localStorage.getItem("redeemAmount"));
    const [totalValue, setTotalValue] = useState(0);
    const [redeemPointsCalculation, setRedeemPointsCalculation] = useState(false);
    // const [denominationArray, setDenominationsArray] = useState(['1', '100', '1000', '2000', '3000', '4000', '5000', '6000', '7000', '8000', '9000', '10000']);
    const [denominationArray, setDenominationsArray] = useState([])
    const availablePoints = localStorage.getItem('points');
    // const [points, setPoints] = useState("");
    // const [pointsValue, setPointsValue] = useState("");
    useEffect(() => {
        if (localStorage.getItem("selectedPartnerText") == "o2spa") {
            setDenominationsArray([100, 500, 1000])
        }
        else if (localStorage.getItem("selectedPartnerText") == "taj") {
            setDenominationsArray([1000, 5000])
        }
        else if (localStorage.getItem("selectedPartnerText") == "makemytrip") {
            setDenominationsArray([500, 1000, 2000, 5000])
        }
        else if (localStorage.getItem("selectedPartnerText") == "zomato") {
            setDenominationsArray([50, 100, 500, 1000])
        }
        else if (localStorage.getItem("selectedPartnerText") == "marriott") {
            setDenominationsArray([1000, 2500, 5000, 7500])
        }
        else if (localStorage.getItem("selectedPartnerText") == "bookmyshow") {
            setDenominationsArray([100, 200, 500, 1000])
        }
        else if (localStorage.getItem("selectedPartnerText") == "uber") {
            setDenominationsArray([50, 100, 500, 1000])
        }
    }, [])

    // useEffect(() => {
    //     const data = {
    //         sku: localStorage.getItem("selectedBrand")
    //     };
    //     const headers = {
    //         AuthToken: localStorage.getItem("auth_key"),
    //         Otp: localStorage.getItem("otp")
    //     }
    //     if (headers.AuthToken) {
    //         axios
    //             .post(API_BASE_URL + "/product_details", data, { headers: headers })
    //             .then((res) => {
    //                 // debugger;
    //                 // localStorage.setItem("mobile_no", mobileNumber);
    //                 if (res.data.code == 403) {
    //                     localStorage.clear();
    //                     navigate("/landing-page");
    //                 }
    //                 else {
    //                     // localStorage.setItem("session_id", res.data.session_id);
    //                     // navigate("/redeemVerification");
    //                     setDenominationsArray(res?.data?.denominations);
    //                 }
    //             })
    //             .catch((err) => {
    //                 alert(err);
    //             });
    //     }
    //     else {
    //         navigate("/landing-page")
    //     }
    // }, [])
    const decreseQuantity = () => {
        if (quantity >= 1) {
            setQuantity(quantity - 1);
        }
    }
    const increseQuantity = () => {
        if((denomination*(quantity + 1)*4) <= availablePoints){
            setQuantity(quantity + 1);
        }
        if((denomination*(quantity + 1)*4) > availablePoints){
            showToast()
        }
        // if(quantity < 4){
        // }
        // if (textInputRef.current) {
        //     textInputRef.current.input.value = quantity + 1;
        //   }
    }
    const showToast = () => {
        M.toast({
            html: "You don't have enough Points to Redeem!",
            // classes: "redeem-points-toast",
            classes: "justify-content-center",
            // displayLength: 10000000,
        });
    };
    const handleDenomination = (e) => {
        if((e.target.value)*4 <= availablePoints){
            setDenomination(e.target.value)
        }else{
            showToast()
        }
    }

    // const handlePoints = (e) => {
    //     setPoints(e.target.value)
    // }

    // const handleQuantity = (e) => {
    //     setQuantity(e.target.value)
    // }

    // const handlePointValue = (e) => {
    //     setPointsValue(e.target.value)
    // }

    const openSummary = () => {
        navigate('/deliver-voucher')
        localStorage.setItem("totalVouchers", quantity);
        localStorage.setItem("totalFirstCitizenPoints", totalValue * 4);
    }
    useEffect(() => {
        if (!isNaN(denomination)) {
            setTotalValue(denomination * quantity);
            setRedeemPointsCalculation(true);
        }
    }, [denomination, quantity])
    return (
        <div className="screen-wrap">
            {/* Header Section */}
            <Header
                className="app-header has-border"
                headertype={0}
                logoShow={1}
                redirectLink={'/redeem-your-points'}
            // href="/redeem-your-points"
            // onclick={() => navigate("/redeem-your-points")}
            />
            {/* Main Section */}
            <div className="main-wrap has-fixed-bottom">
                <Container className="h-100">
                    <div className="voucher-order-summary">
                        <div className="voucher-order-summary__top">
                            <div className="voucher-brand">
                                <img src={imageDisplay} alt="" />
                            </div>
                        </div>
                        <div className="voucher-order-summary__main">
                            <div className="voucher-order-details">
                                <h5>{localStorage.getItem('selectedPartnerText')}</h5>
                                <div className="voucher-order-list">
                                    <div className="voucher-order-list__item">
                                        <label>Quantity:</label>
                                        <div className="product-counter">
                                            <Button node="button" waves="light-danger" className="btn" onClick={decreseQuantity}><i className="icon-minus"></i></Button>
                                            <TextInput
                                                // ref={textInputRef}
                                                type="text"
                                                id="counter"
                                                className="browser-default"
                                                value={quantity}
                                            />
                                            {/* <div>{quantity}</div> */}
                                            <Button node="button" waves="light-danger" className="btn" onClick={increseQuantity}><i className="icon-plus"></i></Button>
                                        </div>
                                    </div>
                                    <div className="voucher-order-list__item">
                                        <label>Denomination:</label>
                                        <div className="form-group m-0">
                                            <select
                                                browserDefault
                                                id="denomination"
                                                placeholder="Select"
                                                className="m-0"
                                                value={denomination}
                                                onChange={handleDenomination}
                                            >

                                                <option value="">
                                                    Select
                                                </option>
                                                {denominationArray?.map((item, index) => (
                                                    <option key={index} value={item}>₹ {item}</option>
                                                ))}
                                                {/* <option value="100">100</option>
                                                <option value="200">200</option>
                                                <option value="500">500</option>
                                                <option value="1000">1000</option> */}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="voucher-order-list__item">
                                        <label>Total Voucher Value</label>
                                        <span className="value text-primary"><span className="inr-symbol">₹</span> {totalValue}</span>
                                    </div>
                                    <div className="voucher-order-list__item">
                                        <label>First Citizen Club Points that <br />will be redeemed</label>
                                        <span className="value text-primary">{totalValue / .25}</span>
                                    </div>
                                </div>
                            </div>
                            {redeemPointsCalculation && <div className="redeem-points-calculator">
                                {/* <div className="redeem-points-calculator__inner">
                                    <div className="operand">
                                        <div className="form-group">
                                            <label htmlFor="operand1">Points</label>
                                            <TextInput
                                                type="text"
                                                id="operand1"
                                                className="browser-default"
                                                value={denomination/0.25}
                                                onChange={handlePoints}
                                            />
                                        </div>
                                    </div>
                                    <span className="operator">X</span>
                                    <div className="operand operand-sm">
                                        <div className="form-group">
                                            <label htmlFor="operand1">Qty</label>
                                            <TextInput
                                                type="text"
                                                id="operand1"
                                                className="browser-default"
                                                value={quantity}
                                                // onChange={handleQuantity}
                                            />
                                        </div>
                                    </div>
                                    <span className="operator">X</span>
                                    <div className="operand">
                                        <div className="form-group">
                                            <label htmlFor="operand1">Value</label>
                                            <TextInput
                                                type="text"
                                                id="operand1"
                                                className="browser-default"
                                                value="0.25"
                                                onChange={handlePointValue}
                                            />
                                        </div>
                                    </div>
                                    <div className="operator operator--equal">=</div>
                                    <div className="operand">
                                        <div className="form-group">
                                            <label htmlFor="operand1">Total Value</label>
                                            <TextInput
                                                type="text"
                                                id="operand1"
                                                className="browser-default"
                                                value={totalValue}
                                                // onChange={handleDenomination}
                                            />
                                        </div>
                                    </div>
                                </div> */}
                            </div>}
                            <div className="note">
                                <div className="note-body">
                                    <h4 className="note-title">Note:</h4>
                                    <p className="note-text">For redemption of vouchers</p>
                                    <p className="note-text">1 point = 0.25paise</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            {/* Fixed Bottom Section */}
            <div className="fixed-bottom-layout">
                <div className="mx-10">
                    <Button node="button" waves="light" className="btn-primary btn-fluid" disabled={!totalValue} onClick={() => openSummary()}>
                        Continue <i className="icon-arrow-right ml-10"></i>
                    </Button>
                </div>
            </div>
        </div>
    )
}