import React from "react";
import {Container } from "react-materialize";
import Header from "../components/layout/header.js";
import Footer from "../components/layout/footer.js";
import TransactionCard from "../components/transaction-card.js";



export default function TransactionHistory() {
    return (
        <>
            <div className="screen-wrap">
                {/* Header Section */}
                <Header
                    className="app-header has-border"
                    headertype={0}
                    logoShow={1}
                    redirectLink={'/home'}
                />
                {/* Main Wrap */}
                <div className="main-wrap">
                    <Container>
                        <div className="card-flush bg-primary mt-35">
                            <div className="card-body">
                                <h5 className="card-title">Total Available Points</h5>
                                <h3 className="card-points">1014 <span>= 100.14</span></h3>
                                <small className="expiry-date">Total points expiring on 2nd Jan 2024</small>
                            </div>
                        </div>
                        <div className="transaction-list">
                            <div className="transaction-list__header">
                                <h5>Last 10 Transactions</h5>
                            </div>
                            <div className="transaction-list__body">
                                <TransactionCard 
                                    expiryDate={"11th January 2023"}
                                    billAmount={15000}
                                    earnedPoints={1500}
                                    redeemedPoints={1000}
                                />
                                <TransactionCard 
                                    expiryDate={"11th January 2023"}
                                    billAmount={17000}
                                    earnedPoints={1200}
                                    redeemedPoints={1000}
                                />
                                <TransactionCard 
                                    expiryDate={"11th January 2023"}
                                    billAmount={5000}
                                    earnedPoints={0}
                                    redeemedPoints={2000}
                                />
                                <TransactionCard 
                                    expiryDate={"11th January 2023"}
                                    billAmount={10000}
                                    earnedPoints={500}
                                    redeemedPoints={1500}
                                />
                                <TransactionCard 
                                    expiryDate={"11th January 2023"}
                                    billAmount={5000}
                                    earnedPoints={100}
                                    redeemedPoints={500}
                                />
                                <TransactionCard 
                                    expiryDate={"11th January 2023"}
                                    billAmount={5000}
                                    earnedPoints={0}
                                    redeemedPoints={2000}
                                />
                                <TransactionCard 
                                    expiryDate={"11th January 2023"}
                                    billAmount={15000}
                                    earnedPoints={1500}
                                    redeemedPoints={1000}
                                />
                                <TransactionCard 
                                    expiryDate={"11th January 2023"}
                                    billAmount={10000}
                                    earnedPoints={500}
                                    redeemedPoints={1500}
                                />
                                <TransactionCard 
                                    expiryDate={"11th January 2023"}
                                    billAmount={5000}
                                    earnedPoints={100}
                                    redeemedPoints={500}
                                />
                                <TransactionCard 
                                    expiryDate={"11th January 2023"}
                                    billAmount={17000}
                                    earnedPoints={1200}
                                    redeemedPoints={1000}
                                />
                            </div>
                        </div>
                    </Container>
                </div>
                {/* Footer Section */}
                <Footer />
            </div>
        </>
    )
}