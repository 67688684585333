import React from "react";
import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";
import ModalCloseIcon from "../assets/img/icons/icon_close.svg";
import { Link } from "react-router-dom";

export default class ModalCta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const options = {
      inDuration: 250,
      outDuration: 250,
      opacity: 0.75,
      dismissible: true,
      startingTop: "4%",
      endingTop: "10%",
    };
    M.Modal.init(this.Modal, options);
  }
  render() {
    return (
      <div
        ref={(Modal) => {
          this.Modal = Modal;
        }}
        id={this.props.id}
        className={`modal bottom-sheet modal__call2action  ${this.props.className}`}
      >
        <div className="notch"></div>
        <div className="modal-content">
          <Link href="" className="modal-close modal-close-icon">
            <img src={ModalCloseIcon} alt="close icon" />
          </Link>
          {this.props.header}
          {this.props.contentBody}
          {this.props.footer}
        </div>
      </div>
    );
  }
}
