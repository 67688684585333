import React from "react";
import Header from "../components/layout/header";
import { Container, Button } from "react-materialize";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../config/constant";
import { decryptData } from "./common-func";

export default function DeliverVoucher() {
    const mobileNumber = localStorage.getItem("mobile_no");
    const navigate = useNavigate();
    const otpVerify = () => {
        const data = {
            // mobile_number: mobileNumber,
        };
        const headers = {
            AuthToken: localStorage.getItem("auth_key"),
            Otp: localStorage.getItem("otp")
        }
        if (headers.AuthToken) {
            // console.log("enter")

            axios
                .post(API_BASE_URL + "/verification/otp", data, { headers: headers })
                .then((res) => {
                    // debugger;
                    // localStorage.setItem("mobile_no", mobileNumber);
                    if (res.data.code == 403) {
                        localStorage.clear();
                        navigate("/landing-page");
                    }
                    else {
                        localStorage.setItem("session_id", res.data.session_id);
                        navigate("/redeemVerification");
                    }
                })
                .catch((err) => {
                    alert(err);
                });
        }
        else {
            navigate("/landing-page")
        }
    }
    const imageDisplay = localStorage.getItem("selectedPartners")
    return (
        <div className="screen-wrap">
            {/* Header Section */}
            <Header
                className="app-header has-border"
                headertype={0}
                logoShow={1}
                redirectLink={'/voucher-order-summary'}
            />
            {/* Main Section */}
            <div className="main-wrap has-fixed-bottom">
                <Container className="h-100">
                    <div className="voucher-order-summary">
                        <div className="voucher-order-summary__top">
                            <div className="voucher-brand">
                                <img src={imageDisplay} alt="" />
                            </div>
                        </div>
                        <div className="voucher-order-summary__main">
                            <div className="voucher-order-details">
                                <h5>{localStorage.getItem('selectedPartnerText')}</h5>
                                <div className="voucher-order-list">
                                    <div className="voucher-order-list__item d-block">
                                        <label className="text-uppercase">Send Voucher details to:</label>
                                        <div className="highlight">
                                            <small>SMS to mobile number</small>
                                            <p><strong>{decryptData(mobileNumber, 1)}</strong></p>
                                        </div>
                                    </div>
                                    <div className="voucher-order-list__item">
                                        <label>Number of vouchers:</label>
                                        <span className="value text-primary">{localStorage.getItem("totalVouchers")}</span>
                                    </div>
                                    <div className="divider"></div>
                                    <div className="voucher-order-list__item">
                                        <label>First Citizen Club Points that <br />will be redeemed</label>
                                        <span className="value text-primary">{localStorage.getItem("totalFirstCitizenPoints")}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="note">
                                <div className="note-body">
                                    <h4 className="note-title">Note:</h4>
                                    <p className="note-text">For redemption of vouchers</p>
                                    <p className="note-text">1 point = 0.25paise</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            {/* Fixed Bottom Section */}
            <div className="fixed-bottom-layout">
                <div className="mx-10">
                    <Button node="button" waves="light" className="btn-primary btn-fluid" onClick={() => otpVerify()}>
                        Confirm Order <i className="icon-arrow-right ml-10"></i>
                    </Button>
                </div>
            </div>
        </div>
    )
}