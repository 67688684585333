import React, { useState, useEffect } from "react";
import { Button } from "react-materialize";
import Header from "../components/layout/header.js";
import Footer from "../components/layout/footer.js";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ModalCentered from "../components/modal-centered.js";
import ImagePujoVeteran from '../assets/img/img_pujo-veteran.svg';
// import PujoBanner1 from '../assets/img/slide/pujo-banner-1.png';
// import PujoBanner2 from '../assets/img/slide/pujo-banner-2.png';
// import PujoBanner3 from '../assets/img/slide/pujo-banner-3.png';
import PujoBanner4 from '../assets/img/slide/pujo-banner-4.jpg';
import { useNavigate } from "react-router-dom";
import Loader from "../components/loader.js";
import IconTrophyLightGray from "../assets/img/icons/icon_trophy-light-gray.svg";
import $ from "jquery";


import { authAxios } from "../config/constant.js";


export default function PujoVeteran() {
    const [keyID] = useState(localStorage.getItem("key_id"));
    const [mobileNumber] = useState(localStorage.getItem("mobile_no"));
    // const [userName, setUserName] = useState(localStorage.getItem("firstname"));
    const [userName, setUserName] = useState("");
    // const [memberId, setMemberID] = useState("");
    const [userTier, setUserTier] = useState("");
    const [loaderPopup, setLoaderPopup] = useState(false);
    const [displayOffer, setDisplayOffer] = useState(false);
    const [redeemOffer, setRedeemOffer] = useState(false);
    // const [cardData, setCardData] = useState({});

    //for profile
    // const [userData] = useState(localStorage.getItem("userData"));
    // const [welcomeModel, setWelcomeModel] = useState(false);
    // const [modelReward, setModelReward] = useState(false);
    // const [appTour, setAppTour] = useState(localStorage.getItem("tour"));
    // const [newLogin, setNewLogin] = useState(localStorage.getItem("newLogin"));
    // const [couponCode, setCouponCode] = useState("");
    // const [couponValidity, setValidity] = useState("");
    // const [couponDescription, setDescription] = useState("");
    // const [storeName, setStoreName] = useState(localStorage.getItem("storeName"));
    // const [userTierClass, setUserTierClass] = useState("");
    // const [scanned] = useState(localStorage.getItem("scanned"));
    // const [welcomePopupClose, setWelcomeClosePopup] = useState(false);
    // const [smsKeyId, setSmskeyID] = useState(localStorage.getItem("key_id"));
    // const [redeemPoints] = useState(localStorage.getItem("points"));
    // const [rewardPopupClosed, setRewardPopupClosed] = useState(false);
    // const [exclusiveOffer, setExclusiveOffer] = useState(false);
    // const [multipleOffers, setMultipleOffers] = useState(false);
    // const [customerData, setCustomerData] = useState({});
    const [textToDisplay, setTextToDisplay] = useState("");
    const [memberNotFound, setMemberNotFound] = useState(false);


    const navigate = useNavigate();

    console.log(displayOffer)

    // open the popup
    // function changeDisplayOffer() {
    //     setDisplayOffer(true);
    // }


    useEffect(() => {
        setUserName(localStorage.getItem("firstname"));
    });

    //  console.log(localStorage.getItem("firstname"))
    //  console.log(userName)



    //call profile api
    useEffect(() => {
        // if (userData == null) {
        // debugger;
        const data = {
            keyID: keyID,
            store_code: "",
        };
        const headers = {
            AuthToken: localStorage.getItem("auth_key"),
            Otp: localStorage.getItem("otp")
        }
        if (headers.AuthToken) {
            authAxios
                .post("/get_profile", data, { headers: headers })
                .then((res) => {
                    // debugger;
                    // console.log(res);
                    if (res.data.code == 403) {
                        localStorage.clear();
                        navigate("/landing-page");
                    }
                    else {
                        if (res?.data?.CustomerProfileResponse?.data?.pujo_type === "Fashion forward") {
                            // console.log("setting text")
                            setTextToDisplay(`<h6>Hey ${res?.data?.CustomerProfileResponse?.data?.user?.first_name}</h6><p>You’re <strong>Fashion Forward</strong> when it comes to dressing up for Pujo!</br>While you’ve shopped for Pujo with us before, it’s time again to put together fatafati looks right from shoshti to bijoya!</br>
                        Start off with this special offer just for you</p>`)
                        }
                        else if (res?.data?.CustomerProfileResponse?.data?.pujo_type === "Fashion expert") {
                            setTextToDisplay(`<h6>Hey ${res?.data?.CustomerProfileResponse?.data?.user?.first_name}</h6><p>You’re a <strong>Fashion Expert!</strong></br>Looks like you’ve shopped with us before</br>But before you go Pandal hopping, make sure you have all your Pujo looks locked in Here’s an offer to get you started</p>`)
                        }
                        else if (res?.data?.CustomerProfileResponse?.data?.pujo_type === "Fashion potential") {
                            setTextToDisplay(`<h6>Hey ${res?.data?.CustomerProfileResponse?.data?.user?.first_name}</h6><p>You’re a <strong>Fashion Expert!</strong></br>We’ve not seen you for a whole year</br>But we know you have a fatafati sense of style</br>Explore our Pujo range with this great offer</p>`)
                        }
                        else if (res?.data?.CustomerProfileResponse?.data?.pujo_type === "Fashion Accessorizer") {
                            setTextToDisplay(`<h6>Hey ${res?.data?.CustomerProfileResponse?.data?.user?.first_name}</h6><p>You’re a <strong>Pujo Accessorizer!</strong></br>For you, it’s all about Pujo, Pandaal and Accessories</br>Now explore our ethnic wear as well and turn the spotlight on you with this great offer</p>`)
                        }
                        else if (res?.data?.CustomerProfileResponse?.data?.pujo_type === "Pujo Ethnic wear pro") {
                            setTextToDisplay(`<h6>Hey ${res?.data?.CustomerProfileResponse?.data?.user?.first_name}</h6><p>You’re a <strong>Pujo Ethnic Wear Pro!</strong></br>There are 5 Days and 5 ways to become the Showstopper again this year!</br>Let us help you get on your Pujo style with this great offer we have for you</p>`)
                        }
                        else if (res?.data?.CustomerProfileResponse?.data?.pujo_type) {
                            setTextToDisplay(`<h6>Hi there <strong>Pujo Fashion Fan!</strong></h6><p>Maa Aschen</br>But are your Pujo ready?</br>Let us help you get your style on this year</br>Mix and match every single day with Shoppers Stop, and make those heads turn at every Pandaal</br>Here is what's in store for you</p>`)
                        }
                        else {
                            setMemberNotFound(true);
                        }
                        // if (res?.data?.CustomerProfileResponse) {
                        //     setCustomerData(res?.data?.CustomerProfileResponse);
                        // }
                        if (res.data.CustomerProfileResponse !== undefined) {
                            if (res.data.CustomerProfileResponse.errorMsg !== "Success") {
                                console.log("Not Success")
                                // localStorage.setItem("userData", res.data);
                                // localStorage.setItem("Member", res.data.CustomerProfileResponse.errorMsg)
                                // if (res.data.store_details !== undefined) {
                                //     const storeData = res.data.store_details[0];

                                //     if (storeData !== undefined && storeData !== null) {
                                //         // setStoreName(storeData.store_name);
                                //         localStorage.setItem("storeName", storeData.store_name);
                                //         localStorage.setItem("store_city", storeData.store_city);
                                //         localStorage.setItem("store_code", storeData.store_code);
                                //     }
                                // }

                                // setWelcomeModel(true);
                            } else {
                                // debugger;
                                var userData = res.data.CustomerProfileResponse.data;
                                if (res.data.store_details !== undefined) {
                                    const storeData = res.data.store_details[0];

                                    if (storeData !== undefined && storeData !== null) {
                                        // setStoreName(storeData.store_name);
                                        localStorage.setItem("storeName", storeData.store_name);
                                        localStorage.setItem("store_city", storeData.store_city);
                                        localStorage.setItem("store_code", storeData.store_code);
                                    }
                                }
                                // console.log(userData.cardName);
                                // if (userData.cardName == undefined) {
                                //     var cName = userData.firstName + " " + userData.lastName;
                                //     // console.log(cName);
                                //     localStorage.setItem("firstname", cName);
                                //     setUserName(cName);
                                // } else {
                                //     localStorage.setItem("firstname", userData.cardName);
                                //     setUserName(userData.cardName);
                                // }
                                // if (userData.member_id === undefined) {
                                //     // localStorage.setItem("memberId", userData.memberNumber);
                                //     setMemberID(userData.memberNumber);
                                // } else {
                                //     // localStorage.setItem("memberId", userData.member_id);
                                //     setMemberID(userData.memberId);
                                // }

                                localStorage.setItem("Fname", userData.user.first_name);
                                localStorage.setItem("Lname", userData.user.last_name);
                                localStorage.setItem("DOB", userData.dateOfBirth);
                                localStorage.setItem("location", userData.city);
                                localStorage.setItem("email", userData.email)

                                // localStorage.setItem("FCNumber", userData.FCNumber);
                                // if (userData.RedeemablePointsValue === null) {
                                //     localStorage.setItem("points", 0);
                                // } else {
                                //     localStorage.setItem("points", userData.RedeemablePointsValue);
                                // }
                                localStorage.setItem("tier_expeiry", userData.tierEndDate);

                                if (userData.tier === "Tier4") {
                                    // localStorage.setItem("tier", "FIRST CITIZEN BLACK");
                                    setUserTier("FIRST CITIZEN BLACK");
                                    // setUserTierClass("tier__black");
                                    // setSmskeyID(keyID);
                                }
                                if (userData.tier === "Tier3") {
                                    // localStorage.setItem("tier", "PLATINUM AURA");
                                    setUserTier("PLATINUM AURA");
                                    // setUserTierClass("tier__platinum");
                                }
                                if (userData.tier === "Tier2") {
                                    // localStorage.setItem("tier", "GOLDEN GLOW");
                                    setUserTier("GOLDEN GLOW");
                                    // setUserTierClass("tier__gold");
                                }
                                if (userData.tier === "Tier1") {
                                    // localStorage.setItem("tier", "SILVER EDGE");
                                    setUserTier("SILVER EDGE");
                                    // setUserTierClass("tier__silver");
                                }
                                if (
                                    userData.tier === "Tier0" ||
                                    userData.tier === null ||
                                    userData.tier === "null"
                                ) {
                                    // setUserTierClass("");
                                }

                                // localStorage.setItem("userData", res.data);

                                // setWelcomeModel(true);
                                // couponScan(userData.memberId, userData.tier, userData.cardName);
                            }
                        } else {
                            if (res.data.errorMsg === "All are same tier") {
                                $(".call2action_multiple-membership").addClass("show");
                                $(".call2action-overlay").addClass("show");
                            }
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        else {
            navigate("/landing-page")
        }
        // }
    }, []);

    // call the puja api 

    // $(".call2action_reward-end").addClass("show");
    // $(".call2action-overlay").addClass("show");

    function PujoVeteranCoupon() {
        // debugger;
        const data = {
            store_code: "",
            keyID: keyID,
            name: userName,
            // memberID: memberId,
            tier: userTier,
        };
        const headers = {
            AuthToken: localStorage.getItem("auth_key"),
            Otp: localStorage.getItem("otp")
        }
        // setLoaderPopup(true);
        if (headers.AuthToken) {
            authAxios
                .post("/customer_coupon", data, { headers: headers })
                .then((res) => {
                    // debugger;
                    console.log(res);
                    if (res.data.code == 403) {
                        localStorage.clear();
                        navigate("/landing-page");
                    }
                    else {
                        setLoaderPopup(false);
                        //                    setCardData(res.data[0]);
                        if (res.data.Message == "Coupon already received") {
                            // debugger;
                            setDisplayOffer(true);
                        }
                        else {
                            setDisplayOffer(true);
                        }
                        // if (res.data.Message == "Coupon already received") {
                        // // console.log("welcomePopupClose", welcomePopupClose);
                        // if (welcomePopupClose) {
                        //     $(".call2action_reward-end").addClass("show");
                        //     $(".call2action-overlay").addClass("show");
                        // }
                        // localStorage.removeItem("key_id");
                        // localStorage.setItem("scanned", true);
                        // } else if(res.data.Message == "No Coupon Found"){
                        // if (welcomePopupClose) {
                        //     $(".call2action_coupon_notfound").addClass("show");
                        //     $(".call2action-overlay").addClass("show");
                        // }
                        // localStorage.removeItem("key_id");
                        // localStorage.setItem("scanned", true);
                        // }else {
                        // // console.log("code", res.data[0].code);
                        // // console.log("validity", res.data[0].validity);
                        // // console.log("description", res.data[0].description);
                        // if (res.data.length > 1) {
                        //     setMultipleOffers(true);
                        // }
                        // if (res.data[0].pin == "N/A") {
                        //     setCouponCode(res.data[0].code);
                        //     setValidity(res.data[0].validity);
                        //     setDescription(res.data[0].description);
                        //     setExclusiveOffer(true);
                        //     localStorage.removeItem("key_id");
                        //     localStorage.setItem("scanned", true);
                        //     setKeyID(null);
                        // } else {
                        //     setCouponCode(res.data[0].code);
                        //     setValidity(res.data[0].validity);
                        //     setDescription(res.data[0].description);

                        //     setModelReward(true);

                        //     localStorage.removeItem("key_id");
                        //     localStorage.setItem("scanned", true);
                        //     localStorage.setItem("newLogin", false);
                        //     // setNewLogin("false");

                        //     setKeyID(null);
                        // }
                        // }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        else {
            navigate("/landing-page")
        }
    }

    // call the puja function on opening the popup
    // useEffect(() => {
    //     if (displayOffer) {
    //         PujoVeteranCoupon()
    //     }
    // }, [displayOffer])

    //date formatting
    // function formatDate(inputDate) {
    //     const date = new Date(inputDate);
    //     const options = { year: 'numeric', month: 'long', day: 'numeric' };
    //     const formattedDate = date.toLocaleDateString('en-US', options);
    //     return formattedDate;
    // }


    return (
        <>
            <div className="screen-wrap">
                {/* Header Section */}
                <Header
                    className="app-header"
                    headertype={1}
                    topbar={0}
                />
                {/* Main Wrap */}
                <div className="main-wrap">
                    <div className="pujo-veteran-wrap">
                        <div className="pujo-veteran__container">
                            <div className="pujo-veteran__img">
                                <img src={ImagePujoVeteran} alt="Pujo Veteran" />
                            </div>
                            <div className="pujo-veteran__content">
                                <div className="offer-details text-center">
                                    {/* <h6>Hi {localStorage.getItem("firstname")},</h6>
                                    <p>You shopped with us during Pujo last year. Looks like you’re a <strong>PUJO VETERAN</strong>. Here’s a special offer for you this year.</p> */}
                                    {textToDisplay && <div dangerouslySetInnerHTML={{ __html: textToDisplay }}></div>}
                                    <Button
                                        node="button"
                                        waves="light"
                                        className="btn-primary btn-fluid text-uppercase" onClick={PujoVeteranCoupon}
                                    >Display Offer <i className="icon-arrow-right ml-10"></i></Button>
                                </div>
                                {/* Start: Banner List */}
                                <div className="banner-list">
                                    <div className="banner-list__head">
                                        <h6 className="banner-list__title">Watch Pujo Video</h6>
                                    </div>
                                    <div className="banner-list__body">
                                        {/* <video width="100%" height="100%" controls>
                                            <source src="https://youtu.be/ofe0V8vFkx8?si=maS_ZmByMS5AcuCB" type="video/ogg" />
                                        </video> */}
                                        <iframe
                                            width="100%"
                                            height="100%"
                                            src="https://www.youtube.com/embed/4hHUOLXTC-A?si=9krZJaYp6bRdAwTS"
                                            title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                </div>

                                <div className="banner-list">
                                    <div className="banner-list__head">
                                        <h6 className="banner-list__title">Check your Fashion looks</h6>
                                    </div>
                                    <div className="banner-list__body px-0">
                                        {/* <OwlCarousel
                                            className="pujo-veteran-carousel owl-theme"
                                            items={1.15}
                                            touchDrag={true}
                                            mouseDrag={true}
                                            nav={false}
                                            loop={false}
                                            margin={10}
                                            dots={false}
                                            video={true}
                                            autoplay={false}
                                            lazyLoad={true}
                                            center={true}
                                            swipeable={true}
                                        >
                                            <div className="item-video first-item">
                                                <iframe 
                                                    width="100%" 
                                                    height="100%" 
                                                    src="https://www.youtube.com/embed/zUGT30GddOc?si=9o2tvuXfdf7rTgVU" 
                                                    title="YouTube Shorts Video 1"
                                                    frameborder="0" 
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                            <div className="item-video">
                                                <iframe 
                                                    width="100%" 
                                                    height="100%" 
                                                    src="https://www.youtube.com/embed/veMu3VPq3LU?si=WvRnax5dtBNNmmQ1" 
                                                    title="YouTube Shorts Video 2"
                                                    frameborder="0" 
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                            <div className="item-video">
                                                <iframe 
                                                    width="100%" 
                                                    height="100%" 
                                                    src="https://www.youtube.com/embed/0TfCBf_0qUg?si=HElSabXu1Kv4ICAK" 
                                                    title="YouTube Shorts Video 3"
                                                    frameborder="0" 
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                            <div className="item-video">
                                                <iframe 
                                                    width="100%" 
                                                    height="100%" 
                                                    src="https://www.youtube.com/embed/LsZRZ3KwHl8?si=DSeES54UZVaQtASt" 
                                                    title="YouTube Shorts Video 4"
                                                    frameborder="0" 
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                            <div className="item-video">
                                                <iframe 
                                                    width="100%" 
                                                    height="100%" 
                                                    src="https://www.youtube.com/embed/8Ajog2Ev0Zg?si=3zm0mDc42wl30U2L" 
                                                    title="YouTube Shorts Video 5"
                                                    frameborder="0" 
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                            <div className="item-video">
                                                <iframe 
                                                    width="100%" 
                                                    height="100%" 
                                                    src="https://www.youtube.com/embed/HBR8gnb4Ieo?si=IQbIUk_J4MmKap5E" 
                                                    title="YouTube Shorts Video 6"
                                                    frameborder="0" 
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                            <div className="item-video"></div>
                                        </OwlCarousel> */}
                                        <div className="horizontal-slider hide-scrollbar">
                                            <div className="hr-slider-item">
                                                <iframe
                                                    width="280"
                                                    height="490"
                                                    src="https://www.youtube.com/embed/zUGT30GddOc"
                                                    title="It’s time to look radiant in your glorious festive avatar as we get ready to welcome Maa!"
                                                    frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                            <div className="hr-slider-item">
                                                <iframe
                                                    width="280"
                                                    height="490"
                                                    src="https://www.youtube.com/embed/veMu3VPq3LU"
                                                    title="Make it a Pujo to remember as you spend time with your family and friends… #SaajerUtsave"
                                                    frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                            <div className="hr-slider-item">
                                                <iframe
                                                    width="280"
                                                    height="490"
                                                    src="https://www.youtube.com/embed/0TfCBf_0qUg"
                                                    title="The rich and scintillating colour of Maa’s sindoor… now a beautiful part of your Pujo look. ❤️"
                                                    frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                            <div className="hr-slider-item">
                                                <iframe
                                                    width="280"
                                                    height="490"
                                                    src="https://www.youtube.com/embed/LsZRZ3KwHl8"
                                                    title="Fusion wear never looked this good! Be Pujo-ready in style! #SaajerUtsave 💙✨"
                                                    frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                            <div className="hr-slider-item">
                                                <iframe
                                                    width="280"
                                                    height="490"
                                                    src="https://www.youtube.com/embed/8Ajog2Ev0Zg"
                                                    title="Rooted in beautiful traditions, it’s time to get Pujo ready with Shoppers Stop #SaajerUtsave"
                                                    frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                            <div className="hr-slider-item">
                                                <iframe
                                                    width="280"
                                                    height="490"
                                                    src="https://www.youtube.com/embed/HBR8gnb4Ieo"
                                                    title="Vibrant tones and flattering silhouettes… This Durga Pujo, embrace elegance and style #SaajerUtsave"
                                                    frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="banner-list">
                                    <div className="banner-list__head">
                                        <h6 className="banner-list__title">Exclusive offer</h6>
                                    </div>
                                    <div className="banner-list__body px-0">
                                        <OwlCarousel
                                            className="pujo-veteran-carousel owl-theme"
                                            items={1}
                                            nav={false}
                                            rtl={true}
                                            margin={10}
                                            dots={false}
                                            stagePadding={20}
                                            autoplayTimeout={3500}
                                            autoplayHoverPause={true}
                                        >
                                            <div className="item first-item">
                                                <a href="javascript:void(0)">
                                                    <figure>
                                                        <img src={PujoBanner4} className="figure-img" alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            {/* <div className="item">
                                                <a href="javascript:void(0)">
                                                    <figure>
                                                        <img src={PujoBanner2} className="figure-img" />
                                                    </figure>
                                                </a>
                                            </div> */}
                                        </OwlCarousel>
                                    </div>
                                </div>
                                {/* End: Banner List */}
                            </div>
                            <div className="blur-effect-box"></div>
                        </div>
                    </div>
                </div>
                {/* Footer Section */}
                <Footer />
            </div>
            {redeemOffer && <ModalCentered
                id="modal__user-enroll"
                className="modal__user-enroll text-center"
                closeIcon={
                    <a
                        // href="javascript:void(0)" 
                        waves="light" className="modal-close icon-cross icon-cross-dark"
                        onClick={() => setRedeemOffer(false)}></a>
                }
                contentBody={
                    <div className="modal-body">
                        {/* <img src={IconScanning} alt="icon-scanning" /> */}
                        <h4>Ooops...!</h4>
                        <p>You have already earned an offer today. Please try again tomorrow to get more offers.</p>
                    </div>
                }
                footer={
                    <div className="modal-footer">
                        <Button
                            node="button"
                            waves="light"
                            className="btn-primary modal-close"
                            onClick={() => setRedeemOffer(false)}>Got It
                        </Button>
                        {/* <small>Contact shop members if you want to know more about enrolling.</small> */}
                    </div>
                }
            />}
            {displayOffer && <ModalCentered
                id="modal__reward"
                className="modal_pujo-offer text-center"
                closeIcon={
                    <a
                        // href="javascript:void(0)"
                        waves="light"
                        className="modal-close icon-cross icon-cross-dark"
                        onClick={() => setDisplayOffer(false)}
                    ></a>
                }
                contentBody={
                    <div className="modal-body">
                        <h2>Your Exclusive Deal</h2>
                        <div className="coupon-code-block">
                            {/* <small>Coupon code</small>
                            <div className="coupon-code-wrap">
                                <span className="coupon-code">{cardData?.code}</span>
                            </div> */}
                        </div>
                        <p className="offer-description">
                            {/* Use the Voucher code to get Rs.500 offer. The offer is valid for a Minimum purchase of Rs.5000.Use the MPIN: {cardData.pin} at the cash counter and redeem the coupon. */}
                            Get Rs.800 worth bonus points added to your account on a shopping of Rs.8000 in store.<br />
                        </p>
                        <small className="validity-text">Bonus points earned to be redeemable from 1st Nov’23 to 10th Dec’23</small>
                        {/* <small className="redeem-text">If already redeemed the voucher kindly ignore it</small> */}
                        {/* <small className="validity-text">
                            {formatValidDate(cardData?.validity)}
                        </small> */}
                    </div>
                }
                footer={
                    <div className="modal-footer">
                        <a href="https://www.shoppersstop.com/term-condition" target="_blank">
                            <span className="terms-text">Terms & Conditions apply</span>
                        </a>
                        <Button
                            node="button"
                            waves="light"
                            className="btn-primary btn-fluid text-uppercase modal-close"
                            onClick={() => navigate("/my-wallet-of-offers")}
                        >Explore Now <i className="icon-arrow-right ml-10"></i></Button>
                    </div>
                }
            />}
            {/*Member not found modal*/}
            {memberNotFound && <ModalCentered
                id="modal__user-enroll"
                className="modal__user-enroll text-center"
                closeIcon={
                    <a
                        // href="javascript:void(0)" 
                        waves="light" className="modal-close icon-cross icon-cross-dark"
                        onClick={() => setMemberNotFound(false)}></a>
                }
                contentBody={
                    <div className="modal-body">
                        {/* <img src={IconScanning} alt="icon-scanning" /> */}
                        <h4>Hi There, </h4>
                        <p>You are not a Shoppers Stop First Citizen Club member. Use your mobile number & register to avail great benefits and offers</p><br></br>
                        <a href="https://www.shoppersstop.com/firstCitizenNonMember" target="_blank">First Citizen Card Loyalty Program at Shoppers Stop</a>
                    </div>
                }
                footer={
                    <div className="modal-footer">
                        {/* <Button
                            node="button"
                            waves="light"
                            className="btn-primary modal-close"
                            onClick={() => setRedeemOffer(false)}>Got It
                        </Button> */}
                        {/* <small>Contact shop members if you want to know more about enrolling.</small> */}
                    </div>
                }
            />}
            {/* Reward Loader */}
            {loaderPopup ? (
                <Loader
                    id="modal__reward"
                    className="modal__reward text-center"
                    contentBody={
                        <div className="progresser-loader">
                            <img src={IconTrophyLightGray} alt="icon trophy" />
                            <div className="progresser">
                                <span className="progresser-bar"></span>
                            </div>
                            <p className="progresser-text">Fetching your offer</p>
                        </div>
                    }
                />
            ) : (
                ""
            )}
        </>
    )
}