import React from "react";
import "materialize-css/dist/css/materialize.min.css";
import Splash from "./pages/splash";
import LandingPage from "./pages/landing-page";
import Verification from "./pages/verification";
import Home from "./pages/home";
import MyWalletOfOffers from "./pages/my-wallet-of-offers";
import Promotions from "./pages/promotions";
import LoyaltyPoints from "./pages/loyalty-points";
import NewLaunches from "./pages/new-launches";
import MobileApps from "./pages/mobile-apps";
import Scanner from "./pages/qr-scanner";
import CustomerCare from "./pages/customer-care";
import Profile from "./pages/profile";
import TermsConditions from "./pages/terms-conditions";
import Notifications from "./pages/notifications";
import FirstCitizenEnrollment from "./pages/first-citizen-enrollment";
import ComingSoon from "./pages/coming-soon";
import PujoVeteran from "./pages/pujo-veteran";
import enrollFs from "./pages/first-citizen-enrollment"
import ProfileUpdate from "./pages/profile-update";
import { Route, Routes, Link } from "react-router-dom";
import PublicRoute from "./routes/public.route";
import AppRoute from "./routes/app.route";
import "./App.scss";
import $ from "jquery";
import AvailablePoints from "./pages/available-points";
import TransactionHistory from "./pages/transaction-history";
import BuyPartnerBrands from "./pages/buy-partner-brands";
import VoucherOrderSummary from "./pages/voucher-order-summary";
import DeliverVoucher from "./pages/deliver-voucher";
import VoucherTermsConditions from "./pages/voucher-terms-conditions";
import RedeemYourPoints from "./pages/redeem-your-points";
import RedeemOtpVerification from "./pages/redeem-otp-verification";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Splash />} />
        <Route path="/:id" element={<Splash />} />

        <Route
          path="/landing-page"
          element={
            <PublicRoute>
              <LandingPage />
            </PublicRoute>
          }
        />
        <Route
          path="/verification"
          element={
            <PublicRoute>
              <Verification />
            </PublicRoute>
          }
        />
        <Route
          path="/redeemVerification"
          element={
            <AppRoute>
              <RedeemOtpVerification />
            </AppRoute>
          }
        />
        <Route
          path="/home"
          element={
            // <Home />
            <AppRoute>
              <Home />
            </AppRoute>
          }
        />
        <Route
          path="/my-wallet-of-offers"
          element={
            <AppRoute>
              <MyWalletOfOffers />
            </AppRoute>
          }
        />
        <Route
          path="/promotions"
          element={
            <AppRoute>
              <Promotions />
            </AppRoute>
          }
        />
        <Route
          path="/loyalty-points"
          element={
            <AppRoute>
              <LoyaltyPoints />
            </AppRoute>
          }
        />
        <Route
          path="/new-launches"
          element={
            <AppRoute>
              <NewLaunches />
            </AppRoute>
          }
        />
        <Route
          path="/mobile-apps"
          element={
            <AppRoute>
              <MobileApps />
            </AppRoute>
          }
        />
        <Route
          path="/qr-scanner"
          element={
            <AppRoute>
              <Scanner />
            </AppRoute>
          }
        />
        <Route
          path="/customer-care"
          element={
            <AppRoute>
              <CustomerCare />
            </AppRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <AppRoute>
              <Profile />
            </AppRoute>
          }
        />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route
          path="/first-citizen-enrollment"
          element={<FirstCitizenEnrollment />}
        />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/pujo-veteran" element={<PujoVeteran />} />
        <Route path="/profile-update" element={<ProfileUpdate/>} />
        <Route path="/transactions" element={<TransactionHistory/>} />
        <Route path="/available-points" element={<AvailablePoints/>} />
        <Route path="/buy-partner-brands" element={<BuyPartnerBrands/>} />
        <Route path="/voucher-order-summary" element={<VoucherOrderSummary/>} />
        <Route path="/deliver-voucher" element={<DeliverVoucher/>} />
        <Route path="/redeem-your-points" element={<RedeemYourPoints/>} />
        <Route path="/voucher-terms-conditions" element={<VoucherTermsConditions/>} />
        <Route path="/coming-soon" element={<ComingSoon/>} />
        {/* <Route path="/first-citizen-enrollment" element={<enrollFs/>} /> */}
        <Route path="*" element={<h1>404 Page Not Found</h1>} />
      </Routes>
    </>
  );
}

export default App;
