import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "materialize-css/dist/css/materialize.min.css";
import Header from "../components/layout/header.js";
import Footer from "../components/layout/footer.js";
// import { Container } from "react-materialize";
// import BrandOfferCard from "../components/brand-offer-card.js";
// import BrandImg1 from "../assets/img/brand-img1.png";
// import BrandImg2 from "../assets/img/brand-img2.png";
// import BrandImg3 from "../assets/img/brand-img3.png";
// import BrandImg4 from "../assets/img/brand-img4.png";
// import LogoStop from "../assets/img/logo_stop.png";
// import LogoLife from "../assets/img/logo_life.png";
// import LogoArcelia from "../assets/img/logo_arcelia.png";
// import LogoKashish from "../assets/img/logo_kashish.png";

export default function CustomerCare() {

  const navigate = useNavigate();
  useEffect(() => {
    const headers = {
      AuthToken: localStorage.getItem("auth_key"),
      Otp: localStorage.getItem("otp")
    }
    if (headers.AuthToken) {
      console.log("enter")
    }
    else {
      navigate("/landing-page")
    }
  });

  return (
    <div className="screen-wrap">
      {/* Header Section */}
      <Header className="app-header" headertype={1} />
      {/* Main Content */}
      <div className="main-wrap">
        <div className="d-table w-100 h-100">
          <div className="d-table-cell h-100 align-middle">
            <div className="coming-soon-block">
              <h6 className="coming-soon-text">
                Coming <br />
                Soon
              </h6>
            </div>
          </div>
        </div>
        {/* <Container>
          <div className="heading-section">
            <h2 className="heading-title">New Launches 🚀 </h2>
            <p className="heading-desc">
              Discover new trends with our exclusive brands.
            </p>
          </div>
          <BrandOfferCard
            overlayColor="overlay-light-gray"
            backgroundImage={BrandImg1}
            overlayImage={LogoStop}
            cardTitle="STOP"
            cardText="One of Shopper’s Stop’s strongest brands, STOP is a reflection"
          />
          <BrandOfferCard
            overlayColor="overlay-light-green"
            backgroundImage={BrandImg2}
            overlayImage={LogoLife}
            cardTitle="Life"
            cardText="One of Shopper’s Stop’s strongest brands, STOP is a reflection"
          />
          <BrandOfferCard
            overlayColor="overlay-light-purple"
            backgroundImage={BrandImg3}
            overlayImage={LogoArcelia}
            cardTitle="Arcelia"
            cardText="One of Shopper’s Stop’s strongest brands, STOP is a reflection"
          />
          <BrandOfferCard
            overlayColor="overlay-light-yellow"
            backgroundImage={BrandImg4}
            overlayImage={LogoKashish}
            cardTitle="Kashish"
            cardText="One of Shopper’s Stop’s strongest brands, STOP is a reflection"
          />
        </Container> */}
      </div>
      {/* Footer Section */}
      <Footer />
    </div>
  );
}
