import React, { useEffect, useState } from "react";
import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { Navbar, Button } from "react-materialize";
import logoFirstCitizenClub from "../../assets/img/logo_ss-first-citizen-club.svg";
import $ from "jquery";

export default function Header(props) {
  const navigate = useNavigate();

  const [userName] = useState(localStorage.getItem("Fname"));
  // const [userName, setUserName] = useState("");
  // const [redeemPoints, setredeemPoints] = useState("");
  const [redeemPoints] = useState(localStorage.getItem("points"));
  // const [tier] = useState(localStorage.getItem("tier"));

  useEffect(() => {
    $(".sidenav-divider").parent().addClass("has-divider");
  });

  function Logout() {
    localStorage.clear();
    navigate("/landing-page");
  }

//   useEffect(()=> {
//     setUserName(localStorage.getItem("Fname")); 
//     setredeemPoints(localStorage.getItem("points"))
//  });
 M.Sidenav._sidenavs.length=0;
  return (
    <>
        <Navbar
          brand={
            <div>
              <Link className="app-header__brand" to={"/home"}>
                <img src={logoFirstCitizenClub} alt="" />
              </Link>
              {props.topbar ? (
              <div className="app-header__topbar">
                <div className="app-header__topbar-item">
                  <div className="app-header__topbar-icon">
                    <i className="icon__notification waves-effect waves-light waves-ripple"></i>
                  </div>
                  {/* <span className="notification-badge">2</span> */}
                </div>
                <div className="app-header__topbar-item">
                  <div className="app-header__topbar-icon">
                    <i className="icon__cart waves-effect waves-light waves-ripple"></i>
                  </div>
                </div>
              </div> ) : ''
              }
            </div>
          }
          id="side-nav"
          className={props.className}
          menuIcon={
            props.headertype ? (
              <i className="icon__menu waves-effect waves-light-dark waves-ripple"></i>
            ) : (
              <Link to={props.redirectLink}><i className="icon__arrow-back waves-effect waves-light-dark waves-ripple"></i></Link>
            )
          }
          options={{
            draggable: true,
            edge: "left",
            inDuration: 250,
            onCloseEnd: null,
            onCloseStart: null,
            onOpenEnd: null,
            onOpenStart: null,
            outDuration: 200,
            preventScrolling: true,
          }}
          
        >
          <div className="menu-banner">
            <div className="d-table w-100 h-100">
              <div className="d-table-cell align-middle h-100">
              <Link className="app-header__brand" to={"/home"}>
                <img className="brand-logo" src={logoFirstCitizenClub} alt="" />
              </Link>
                {/* <img className="brand-logo" src={logoFirstCitizenClub} alt="" /> */}
                {/* {console.log("pu", userName)} */}
                {userName !== null ? (
                  <div className="user-info">
                    <h3 className="__user-name">{userName}</h3>
                    {redeemPoints !== null ? (
                      <div className="user-loyalty-detail">
                        <i className="icon-loyalty"></i>
                        <div className="loyalty-content">
                          <h3 className="loyalty-points">{`${redeemPoints}`}</h3>
                          <span>{``}</span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <NavLink
            to={"/home"}
            className={"waves-effect waves-light waves-ripple"}
            activeClassName={"active"}
          >
            <i className="icon-menu__home"></i>Home
          </NavLink>
          <NavLink
            to={"/my-wallet-of-offers"}
            className={"waves-effect waves-light waves-ripple"}
            activeClassName={"active"}
          >
            <i className="icon-menu__offers"></i>Display Offers
          </NavLink>
          <NavLink
            to={"/promotions"}
            className={"waves-effect waves-light waves-ripple"}
            activeClassName={"active"}
          >
            <i className="icon-menu__promotions"></i>Promotions
          </NavLink>

          <NavLink
            to={"/loyalty-points"}
            className={"waves-effect waves-light waves-ripple"}
            activeClassName={"active"}
          >
            <i className="icon-menu__loyalty"></i>Loyalty Points
          </NavLink>

          <NavLink
            to={"/new-launches"}
            className={"waves-effect waves-light waves-ripple"}
            activeClassName={"active"}
          >
            <i className="icon-menu__launches"></i>New Launches
          </NavLink>
          <NavLink
            to={"/mobile-apps"}
            className={"waves-effect waves-light waves-ripple"}
            activeClassName={"active"}
          >
            <i className="icon-menu__apps"></i>Mobile Apps
          </NavLink>
          {/* <NavLink
            to={"/qr-scanner"}
            className={"waves-effect waves-light waves-ripple"}
            activeClassName={"active"}
          >
            <i className="icon-menu__scanner"></i>QR Code Scanner
          </NavLink> */}
          <NavLink
            to={"/customer-care"}
            className={"waves-effect waves-light waves-ripple"}
            activeClassName={"active"}
          >
            <i className="icon-menu__customer-care"></i>Customer Care
          </NavLink>
          <a
            href="https://www.shoppersstop.com/term-condition" 
            target="_blank"
            className={"waves-effect waves-light waves-ripple"}
            activeClassName={"active"}
            rel="noreferrer"
          >
            <i className="icon-menu__doc"></i>Terms & Conditions
          </a>
          <span className="sidenav-divider"></span>
          {/* <NavLink
            to={"/profile"}
            className={(isActive) =>
              "waves-effect waves-light waves-ripple" +
              (isActive ? "active" : "")
            } 
          >
            <i className="icon-menu__profile"></i>Profile
          </NavLink> */}
          <Button waves="light" className="btn-flat" onClick={Logout}>
            <i className="icon-menu__logout"></i>Log Out
          </Button>
        </Navbar>
    </>
  );
}
