import React, { useEffect, useState } from "react";
import Header from "../components/layout/header";
import { Container, Button } from "react-materialize";
// import LogoO2Spa from "../assets/img/partner-brand_o2spa.png";
// import LogoShoppersStop from "../assets/img/partner-brand_shoppersstop.png";
// import LogoMakeMyTrip from "../assets/img/partner-brand_makemytrip.png";
// import LogoZomato from "../assets/img/partner-brand_zomato.png";
// import LogoOberoi from "../assets/img/partner-brand_oberoi.png";
// import LogoBookMyShow from "../assets/img/partner-brand_bookmyshow.png";
// import LogoPvrCinemas from "../assets/img/partner-brand_pvr.png";
import IconExternalLink from "../assets/img/icons/icon_external-link.svg";
import { Link, useNavigate } from "react-router-dom";
import M from 'materialize-css';
import axios from "axios";
import { API_BASE_URL } from "../config/constant";

export default function RedeemYourPoints() {
    localStorage.setItem("currentPage",'/redeem-your-points')
    const navigate = useNavigate();
    const [alertDisplay, setAlertDisplay] = useState(false);
    const totalPoint = localStorage.getItem('points');

    const [imageDisplay] = useState(localStorage.getItem("selectedPartners"))
    // const [denominations, setDenominations] = useState(['1', '100', '1000', '2000', '3000', '4000', '5000', '6000', '7000', '8000', '9000', '10000']);
    const [denominations, setDenominations] = useState([])
    const [activeItem, setActiveItem] = useState(0);
    // console.log(localStorage.getItem("selectedPartnerText"))

    useEffect(() => {
        if (localStorage.getItem("selectedPartnerText") == "o2spa") {
            setDenominations([500, 1000])
        }
        else if (localStorage.getItem("selectedPartnerText") == "taj") {
            setDenominations([1000, 5000])
        }
        else if (localStorage.getItem("selectedPartnerText") == "makemytrip") {
            setDenominations([500, 1000, 2000, 5000])
        }
        else if (localStorage.getItem("selectedPartnerText") == "zomato") {
            setDenominations([100, 500, 1000])
        }
        else if (localStorage.getItem("selectedPartnerText") == "marriott") {
            setDenominations([1000, 2500, 5000, 7500])
        }
        else if (localStorage.getItem("selectedPartnerText") == "bookmyshow") {
            setDenominations([100, 200, 500,1000])
        }
        else if (localStorage.getItem("selectedPartnerText") == "uber") {
            setDenominations([50, 100, 500, 1000])
        }
    }, [])

    useEffect(() => {
        if (denominations?.length) {
            localStorage.setItem("redeemAmount", parseInt(denominations[0]))
            if (localStorage.getItem('points') < denominations[0]*4) {
                setAlertDisplay(true)
            }
        }
    }, [denominations])
    // useEffect(() => {
    //     if (!alertDisplay) {
    //         setActiveItem(0)
    //     }
    //     // else{
    //     //     setActiveItem(null)
    //     // }
    // }, [alertDisplay])

    useEffect(() => {
        const data = {
            sku: localStorage.getItem("selectedPartnerText")
        };
        const headers = {
            AuthToken: localStorage.getItem("auth_key"),
            Otp: localStorage.getItem("otp")
        }
        if (headers.AuthToken) {
            axios
                .post(API_BASE_URL + "/product_details", data, { headers: headers })
                .then((res) => {
                    console.log(res)
                    // debugger;
                    // localStorage.setItem("mobile_no", mobileNumber);
                    if (res.data.code == 403) {
                        localStorage.clear();
                        navigate("/landing-page");
                    }
                    else {
                        // localStorage.setItem("session_id", res.data.session_id);
                        // navigate("/redeemVerification");
                        // setDenominations(res?.data?.denominations);
                    }
                })
                .catch((err) => {
                    alert(err);
                });
        }
        else {
            navigate("/landing-page")
        }
    }, [])
    const openVoucher = () => {
        navigate('/voucher-order-summary')
    }
    const handleListItemClick = (item, index) => {
        // if (item === 1 && totalPoint >= 400) {
        //     setActiveItem(itemNumber);
        // }
        // else if (item === 2 && totalPoint >= 2000) {
        //     setActiveItem(itemNumber);
        // }
        // else if (item === 3 && totalPoint >= 4000) {
        //     setActiveItem(itemNumber);
        // }
        // else if (item === 4 && totalPoint >= 8000) {
        //     setActiveItem(itemNumber);
        // }
        if (parseInt(totalPoint) >= parseInt(item) * 4) {
            localStorage.setItem("redeemAmount", parseInt(item))
            setActiveItem(index)
            setAlertDisplay(false)
        }
        else {
            // showToast();//call this function only when the user doesn't have points to redeem
            setActiveItem(index)
            setAlertDisplay(true)
        }
    };
    // useEffect(() => {
    //     M.AutoInit();
    // }, []);
    // {/* Toast Function */}
    const showToast = () => {
        M.toast({
            html: "You don't have enough Points to Redeem!",
            // classes: "redeem-points-toast",
            classes: "justify-content-center",
            // displayLength: 10000000,
        });
    };
    return (
        <div className="screen-wrap">
            {/* Header Section */}
            <Header
                className="app-header has-border"
                headertype={0}
                logoShow={1}
                redirectLink={'/buy-partner-brands'}
            />
            {/* Main Section */}
            <div className="main-wrap has-fixed-bottom">
                <Container className="h-100">
                    <div className="brand-voucher">
                        <div className="brand-voucher__img">
                            <img src={imageDisplay} alt="logo" />
                        </div>
                        {alertDisplay && <div className="alert alert-danger">
                            <div className="d-flex align-items-start">
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" className="d-block" fill="currentColor"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 11c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z" /></svg>
                                </i>
                                <div className="ml-10">
                                    <p>Your points : <strong>{Math.floor(totalPoint)}</strong></p>
                                    <hr className="my-5" />
                                    <p>You don't have enough Points to Redeem. Keep shopping to get more points.</p>
                                </div>
                            </div>
                        </div>}
                        <div className="brand-voucher__list">
                            <ul>
                                {denominations && denominations.map((item, index) => (
                                    <li className={`waves-effect waves-light-blue ${activeItem === index ? 'active' : ''
                                        }`}
                                        onClick={() => handleListItemClick(item, index)}><span className="inr-symbol">₹</span> <strong>{item}</strong>({item * 4} Points)</li>
                                ))}
                                {/* <li className={`waves-effect waves-light-blue ${activeItem === 1 ? 'active' : ''
                                    }`}
                                    onClick={() => handleListItemClick(1)}><span className="inr-symbol">₹</span> <strong>100</strong>(400 Points)</li>
                                <li className={`waves-effect waves-light-blue ${activeItem === 2 ? 'active' : ''
                                    }`}
                                    onClick={() => handleListItemClick(2)}><span className="inr-symbol">₹</span> <strong>500</strong>(2000 Points)</li>
                                <li className={`waves-effect waves-light-blue ${activeItem === 3 ? 'active' : ''
                                    }`}
                                    onClick={() => handleListItemClick(3)}><span className="inr-symbol">₹</span> <strong>1000</strong>(4000 Points)</li>
                                <li className={`waves-effect waves-light-blue ${activeItem === 4 ? 'active' : ''
                                    }`}
                                    onClick={() => handleListItemClick(4)}><span className="inr-symbol">₹</span> <strong>2000</strong>(8000 Points)</li> */}
                            </ul>
                        </div>
                        <Link
                            to={"/voucher-terms-conditions"}
                            className="tc-link"
                        >
                            Terms and conditions apply <img src={IconExternalLink} alt="" />
                        </Link>
                    </div>
                </Container>
            </div>
            {/* Fixed Bottom Section */}
            <div className="fixed-bottom-layout">
                <div className="mx-10">
                    <Button node="button" waves="light" className="btn-primary btn-fluid" onClick={() => openVoucher()} disabled={alertDisplay}>
                        Continue <i className="icon-arrow-right ml-10"></i>
                    </Button>
                </div>
            </div>
        </div>
    )
}