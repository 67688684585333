import React, { useState, useEffect } from "react";
import { Button } from "react-materialize";
import IconUserCheck from "../assets/img/icons/icon_user-check.svg";
import { Link, Navigate, useNavigate } from "react-router-dom";

export default function CenteredMsg() {
    const navigate = useNavigate();
    return (
        <div className="centered-msg">
            <div className="centered-msg__icon">
                <img src={IconUserCheck} alt="" />
            </div>
            <h3 className="centered-msg__title">Success!</h3>
            <p className="centered-msg__desc">Your Profile has been updated.</p>
            <Button node="button" className="btn-primary btn-fluid" onClick={() => navigate('/home')}>Back to Home</Button>
        </div>
    )
}
