import React, { Component } from "react";
import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Button } from "react-materialize";
// import { Redirect } from "react-router";
// import { Link, useNavigate } from "react-router-dom";

import $ from "jquery";
const appTourSlide = [
  {
    cN: "bg-gradient",
    img: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 26 26"
      >
        <g
          id="Group_2900"
          data-name="Group 2900"
          transform="translate(-35 -16)"
        >
          <g
            id="Group_2109"
            data-name="Group 2109"
            transform="translate(37 18)"
          >
            <path
              id="Path_1918"
              data-name="Path 1918"
              d="M3.1,6.4A1.039,1.039,0,0,1,2,5.3V3.1A1.039,1.039,0,0,1,3.1,2H5.3A1.039,1.039,0,0,1,6.4,3.1,1.039,1.039,0,0,1,5.3,4.2H4.2V5.3A1.039,1.039,0,0,1,3.1,6.4ZM24,5.3V3.1A1.039,1.039,0,0,0,22.9,2H20.7a1.039,1.039,0,0,0-1.1,1.1,1.039,1.039,0,0,0,1.1,1.1h1.1V5.3a1.1,1.1,0,0,0,2.2,0ZM6.4,22.9a1.039,1.039,0,0,0-1.1-1.1H4.2V20.7a1.039,1.039,0,0,0-1.1-1.1A1.039,1.039,0,0,0,2,20.7v2.2A1.039,1.039,0,0,0,3.1,24H5.3A1.039,1.039,0,0,0,6.4,22.9Zm17.6,0V20.7a1.1,1.1,0,0,0-2.2,0v1.1H20.7a1.1,1.1,0,0,0,0,2.2h2.2A1.039,1.039,0,0,0,24,22.9Z"
              transform="translate(-2 -2)"
              opacity="0.3"
            />
            <g
              id="Group_2110"
              data-name="Group 2110"
              transform="translate(3.3 3.3)"
            >
              <g
                id="Group_2111"
                data-name="Group 2111"
                transform="translate(0 0)"
              >
                <path
                  id="Path_1920"
                  data-name="Path 1920"
                  d="M4.711,9.844H8.133A1.716,1.716,0,0,0,9.844,8.133V4.711A1.716,1.716,0,0,0,8.133,3H4.711A1.716,1.716,0,0,0,3,4.711V8.133A1.716,1.716,0,0,0,4.711,9.844Zm0-5.133H8.133V8.133H4.711Z"
                  transform="translate(-3 -3)"
                />
                <path
                  id="Path_1921"
                  data-name="Path 1921"
                  d="M4.711,19.844H8.133a1.716,1.716,0,0,0,1.711-1.711V14.711A1.716,1.716,0,0,0,8.133,13H4.711A1.716,1.716,0,0,0,3,14.711v3.422A1.716,1.716,0,0,0,4.711,19.844Zm0-5.133H8.133v3.422H4.711Z"
                  transform="translate(-3 -4.444)"
                />
                <path
                  id="Path_1922"
                  data-name="Path 1922"
                  d="M13,4.711V8.133a1.716,1.716,0,0,0,1.711,1.711h3.422a1.716,1.716,0,0,0,1.711-1.711V4.711A1.716,1.716,0,0,0,18.133,3H14.711A1.716,1.716,0,0,0,13,4.711Zm5.133,3.422H14.711V4.711h3.422Z"
                  transform="translate(-4.444 -3)"
                />
                <path
                  id="Path_1923"
                  data-name="Path 1923"
                  d="M20.711,20.283v-.856A.424.424,0,0,0,20.283,19h-.856a.424.424,0,0,0-.428.428v.856a.424.424,0,0,0,.428.428h.856A.424.424,0,0,0,20.711,20.283Z"
                  transform="translate(-5.311 -5.311)"
                />
                <path
                  id="Path_1924"
                  data-name="Path 1924"
                  d="M13,13.428v.856a.424.424,0,0,0,.428.428h.856a.424.424,0,0,0,.428-.428v-.856A.424.424,0,0,0,14.283,13h-.856A.424.424,0,0,0,13,13.428Z"
                  transform="translate(-4.444 -4.444)"
                />
                <path
                  id="Path_1925"
                  data-name="Path 1925"
                  d="M16.283,15h-.856a.424.424,0,0,0-.428.428v.856a.424.424,0,0,0,.428.428h.856a.424.424,0,0,0,.428-.428v-.856A.424.424,0,0,0,16.283,15Z"
                  transform="translate(-4.733 -4.733)"
                />
                <path
                  id="Path_1926"
                  data-name="Path 1926"
                  d="M13,17.428v.856a.424.424,0,0,0,.428.428h.856a.424.424,0,0,0,.428-.428v-.856A.424.424,0,0,0,14.283,17h-.856A.424.424,0,0,0,13,17.428Z"
                  transform="translate(-4.444 -5.022)"
                />
                <path
                  id="Path_1927"
                  data-name="Path 1927"
                  d="M15.428,20.711h.856a.424.424,0,0,0,.428-.428v-.856A.424.424,0,0,0,16.283,19h-.856a.424.424,0,0,0-.428.428v.856A.424.424,0,0,0,15.428,20.711Z"
                  transform="translate(-4.733 -5.311)"
                />
                <path
                  id="Path_1928"
                  data-name="Path 1928"
                  d="M17.428,18.711h.856a.424.424,0,0,0,.428-.428v-.856A.424.424,0,0,0,18.283,17h-.856a.424.424,0,0,0-.428.428v.856A.424.424,0,0,0,17.428,18.711Z"
                  transform="translate(-5.022 -5.022)"
                />
                <path
                  id="Path_1929"
                  data-name="Path 1929"
                  d="M18.283,13h-.856a.424.424,0,0,0-.428.428v.856a.424.424,0,0,0,.428.428h.856a.424.424,0,0,0,.428-.428v-.856A.424.424,0,0,0,18.283,13Z"
                  transform="translate(-5.022 -4.444)"
                />
                <path
                  id="Path_1930"
                  data-name="Path 1930"
                  d="M19.428,16.711h.856a.424.424,0,0,0,.428-.428v-.856A.424.424,0,0,0,20.283,15h-.856a.424.424,0,0,0-.428.428v.856A.424.424,0,0,0,19.428,16.711Z"
                  transform="translate(-5.311 -4.733)"
                />
              </g>
            </g>
          </g>
          <rect
            id="Rectangle_606"
            data-name="Rectangle 606"
            width="26"
            height="26"
            transform="translate(35 16)"
            fill="none"
          />
        </g>
      </svg>
    ),
    heading: "QR Code",
    desc: "With Shoppers Stop pulsse, unlock your benefits by scanning/tapping at the instore QR codes.",
  },
  {
    cN: "bg-blue",
    img: (
      <svg
        id="discount_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g id="Group_2086" data-name="Group 2086" transform="translate(0 0)">
          <rect
            id="Rectangle_588"
            data-name="Rectangle 588"
            width="24"
            height="24"
            fill="none"
          />
        </g>
        <g id="Group_2088" data-name="Group 2088" transform="translate(3 0)">
          <g id="Group_2087" data-name="Group 2087">
            <path
              id="Path_1883"
              data-name="Path 1883"
              d="M12.79,21,3,11.21v2a1.966,1.966,0,0,0,.59,1.41l7.79,7.79a2,2,0,0,0,2.83,0l6.21-6.21a2,2,0,0,0,0-2.83Z"
              transform="translate(-3 0)"
            />
            <path
              id="Path_1884"
              data-name="Path 1884"
              d="M11.38,17.41a1.98,1.98,0,0,0,2.82,0l6.21-6.21a2,2,0,0,0,0-2.83L12.62.58A2,2,0,0,0,11.21,0H5A2.006,2.006,0,0,0,3,2V8.21a1.966,1.966,0,0,0,.59,1.41ZM5,2h6.21L19,9.79,12.79,16,5,8.21Z"
              transform="translate(-3 0)"
            />
            <circle
              id="Ellipse_47"
              data-name="Ellipse 47"
              cx="1.25"
              cy="1.25"
              r="1.25"
              transform="translate(3 3)"
            />
          </g>
        </g>
      </svg>
    ),
    heading: "My Wallet of Offers",
    desc: " With Shoppers Stop pulsse, unlock your benefits by scanning/tapping at the instore QR codes.",
  },
  {
    cN: "bg-success",
    img: (
      <svg
        id="loyalty_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          id="Path_1885"
          data-name="Path 1885"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <path
          id="Path_1886"
          data-name="Path 1886"
          d="M21.41,11.58l-9-9A1.988,1.988,0,0,0,11,2H4A2.006,2.006,0,0,0,2,4v7a2,2,0,0,0,.59,1.42l9,9A1.987,1.987,0,0,0,13,22a1.955,1.955,0,0,0,1.41-.59l7-7A1.955,1.955,0,0,0,22,13,2.02,2.02,0,0,0,21.41,11.58ZM13,20.01,4,11V4h7V3.99l9,9Z"
          transform="translate(0 0)"
        />
        <circle
          id="Ellipse_48"
          data-name="Ellipse 48"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(5 5)"
        />
        <path
          id="Path_1887"
          data-name="Path 1887"
          d="M8.9,12.55A2.057,2.057,0,0,0,9.5,14L13,17.5,16.5,14a2.051,2.051,0,0,0-2.9-2.9l-.6.6-.6-.59a2.025,2.025,0,0,0-1.45-.61A2.053,2.053,0,0,0,8.9,12.55Z"
          transform="translate(0)"
        />
      </svg>
    ),
    heading: "Loyalty Points",
    desc: "  With Shoppers Stop pulsse, unlock your benefits by scanning/tapping at the instore QR codes.",
  },
  {
    cN: "bg-purple",
    img: (
      <svg
        id="rocket_launch_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g id="Group_2089" data-name="Group 2089">
          <rect
            id="Rectangle_589"
            data-name="Rectangle 589"
            width="24"
            height="24"
            fill="none"
          />
          <rect
            id="Rectangle_590"
            data-name="Rectangle 590"
            width="24"
            height="24"
            fill="none"
          />
        </g>
        <g
          id="Group_2091"
          data-name="Group 2091"
          transform="translate(2.222 2.479)"
        >
          <g id="Group_2090" data-name="Group 2090">
            <path
              id="Path_1888"
              data-name="Path 1888"
              d="M9.19,6.35a23.957,23.957,0,0,0-3.57,5.89l-2.26-.97a1,1,0,0,1-.31-1.63L6.06,6.63a2.013,2.013,0,0,1,1.81-.55l1.32.27Zm1.49,10.16a.978.978,0,0,0,1.12.2,19.992,19.992,0,0,0,5.26-3.42c4.59-4.59,4.63-8.33,4.36-9.93a.979.979,0,0,0-.79-.79c-1.6-.27-5.34-.23-9.93,4.36a20.252,20.252,0,0,0-3.42,5.26.991.991,0,0,0,.2,1.12Zm6.97-1.7a23.957,23.957,0,0,1-5.89,3.57l.97,2.26a1,1,0,0,0,1.63.31l3.01-3.01a2.013,2.013,0,0,0,.55-1.81l-.27-1.32Zm-8.71,2.6a3,3,0,0,1-.82,2.71c-.77.77-3.16,1.34-4.71,1.64a1,1,0,0,1-1.17-1.17c.3-1.55.86-3.94,1.64-4.71a3,3,0,0,1,5.06,1.53ZM13,9a2,2,0,1,1,2,2A2.006,2.006,0,0,1,13,9Z"
              transform="translate(-2.222 -2.479)"
            />
          </g>
        </g>
      </svg>
    ),
    heading: "New Launches",
    desc: " With Shoppers Stop pulsse, unlock your benefits by scanning/tapping at the instore QR codes.",
  },
  {
    cN: "bg-danger",
    img: (
      <svg
        id="campaign_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g id="Group_2085" data-name="Group 2085">
          <rect
            id="Rectangle_587"
            data-name="Rectangle 587"
            width="24"
            height="24"
            fill="none"
          />
        </g>
        <g
          id="Group_2114"
          data-name="Group 2114"
          transform="translate(0 2.875)"
        >
          <path
            id="Path_1876"
            data-name="Path 1876"
            d="M18,12.2h0a1.2,1.2,0,0,0,1.2,1.2h2.4a1.2,1.2,0,0,0,1.2-1.2h0A1.2,1.2,0,0,0,21.6,11H19.2A1.2,1.2,0,0,0,18,12.2Z"
            transform="translate(1.2 -3.069)"
          />
          <path
            id="Path_1877"
            data-name="Path 1877"
            d="M16.63,16.905a1.159,1.159,0,0,0,.24,1.644c.636.468,1.308.972,1.944,1.452a1.173,1.173,0,0,0,1.656-.24c0-.012.012-.012.012-.024a1.173,1.173,0,0,0-.24-1.656c-.636-.48-1.308-.984-1.932-1.452a1.191,1.191,0,0,0-1.668.252A.042.042,0,0,1,16.63,16.905Z"
            transform="translate(0.878 -1.99)"
          />
          <path
            id="Path_1878"
            data-name="Path 1878"
            d="M20.491,4.894c0-.012-.012-.012-.012-.024a1.176,1.176,0,0,0-1.656-.24c-.636.48-1.32.984-1.944,1.464a1.174,1.174,0,0,0-.228,1.656c0,.012.012.012.012.024a1.175,1.175,0,0,0,1.656.24c.636-.468,1.308-.984,1.944-1.464A1.193,1.193,0,0,0,20.491,4.894Z"
            transform="translate(0.881 -4.391)"
          />
          <path
            id="Path_1879"
            data-name="Path 1879"
            d="M9.2,9.6H4.4A2.407,2.407,0,0,0,2,12v2.4a2.407,2.407,0,0,0,2.4,2.4H5.6v3.6a1.2,1.2,0,0,0,1.2,1.2h0A1.2,1.2,0,0,0,8,20.4V16.8H9.2l6,3.6V6Z"
            transform="translate(-2 -4.069)"
          />
          <path
            id="Path_1880"
            data-name="Path 1880"
            d="M15.8,12.67A5.376,5.376,0,0,0,14,8.65v8.028A5.331,5.331,0,0,0,15.8,12.67Z"
            transform="translate(0.4 -3.539)"
          />
        </g>
      </svg>
    ),
    heading: "Brand Promotion",
    desc: "With Shoppers Stop pulsse, unlock your benefits by scanning/tapping at the instore QR codes.",
  },
];

export default class ModalAppTour extends Component {
  componentDidMount() {
    // const navigate = useNavigate();
    const options = {
      inDuration: 250,
      outDuration: 250,
      opacity: 0.75,
      dismissible: true,
      startingTop: "4%",
      endingTop: "0",
    };
    M.Modal.init(this.Modal, options);

    /* */
    $(".owl-next").addClass("waves-effect waves-light");
    // function apptourFunction() {
    //   $(".app-tour-carousel.owl-item.item.last-item")
    //     .parent()
    //     .addClass("last-item");
    //   if ($("#lastItem").hasClass("active")) {
    //     console.log("lastitem");
    //     $(".owl-dots, .owl-next, .owl-prev").addClass("hide");
    //     $(".app-tour__skip").hide();
    //     $(".app-tour__getstarted").show();
    //   } else {
    //     $(".app-tour__getstarted").hide();
    //   }
    // }

    // apptourFunction();
    // setInterval(apptourFunction, 100);
  }

  Skipmodel = (e) => {
    e.preventDefault();
    this.props.skipFunction();
  };
  getstartedmodel = (e) => {
    e.preventDefault();

    this.props.getStartedFunction();
  };

  onNxtClick = (e) => {
    if (e.page.index === 4) {
      $(".owl-dots, .owl-next, .owl-prev").addClass("hide");
      $(".app-tour__skip").hide();
      $(".app-tour__getstarted").show();
    } else {
      $(".app-tour__getstarted").hide();
    }
  };
  render() {
    return (
      <>
        <div
          ref={(Modal) => {
            this.Modal = Modal;
          }}
          id="modal__app-tour"
          className="modal modal__centered modal__app-tour"
        >
          <div className="d-flex align-items-center w-100 h-100">
            <div className="modal-content">
              <Button
                className="app-tour__skip"
                flat
                waves="light"
                onClick={this.Skipmodel}
              >
                Skip
              </Button>
              <Button
                node="button"
                className="app-tour__getstarted btn-dark btn-circle"
                waves="light"
                // style={{ display: "none" }}
                onClick={this.getstartedmodel}
              >
                Get Started <i className="icon-arrow-right ml-10"></i>
              </Button>
              <OwlCarousel
                className="app-tour-carousel owl-theme"
                navText={[
                  "",
                  '<span class="nav__text">Next</span><i class="icon-arrow-right ml-10"></i>',
                ]}
                items={1}
                nav={true}
                loop={false}
                margin={0}
                dots={true}
                autoplay={false}
                autoplayTimeout={3500}
                autoplayHoverPause={true}
                onChanged={this.onNxtClick}
              >
                {appTourSlide.map((slide, idx) => {
                  // console.log("apptoour", idx);
                  return (
                    <div className={`item app-tour__item ${slide.cN}`}>
                      <div className="app-tour__icon">{slide.img}</div>
                      <div className="app-tour__content">
                        <h3 className="app-tour__title text-white">
                          {slide.heading}
                        </h3>
                        <p className="app-tour__desc text-white">
                          {slide.desc}
                        </p>
                      </div>
                    </div>
                  );
                })}
                {/* <div className="item app-tour__item bg-gradient first-item">
                  <div className="app-tour__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                    >
                      <g
                        id="Group_2900"
                        data-name="Group 2900"
                        transform="translate(-35 -16)"
                      >
                        <g
                          id="Group_2109"
                          data-name="Group 2109"
                          transform="translate(37 18)"
                        >
                          <path
                            id="Path_1918"
                            data-name="Path 1918"
                            d="M3.1,6.4A1.039,1.039,0,0,1,2,5.3V3.1A1.039,1.039,0,0,1,3.1,2H5.3A1.039,1.039,0,0,1,6.4,3.1,1.039,1.039,0,0,1,5.3,4.2H4.2V5.3A1.039,1.039,0,0,1,3.1,6.4ZM24,5.3V3.1A1.039,1.039,0,0,0,22.9,2H20.7a1.039,1.039,0,0,0-1.1,1.1,1.039,1.039,0,0,0,1.1,1.1h1.1V5.3a1.1,1.1,0,0,0,2.2,0ZM6.4,22.9a1.039,1.039,0,0,0-1.1-1.1H4.2V20.7a1.039,1.039,0,0,0-1.1-1.1A1.039,1.039,0,0,0,2,20.7v2.2A1.039,1.039,0,0,0,3.1,24H5.3A1.039,1.039,0,0,0,6.4,22.9Zm17.6,0V20.7a1.1,1.1,0,0,0-2.2,0v1.1H20.7a1.1,1.1,0,0,0,0,2.2h2.2A1.039,1.039,0,0,0,24,22.9Z"
                            transform="translate(-2 -2)"
                            opacity="0.3"
                          />
                          <g
                            id="Group_2110"
                            data-name="Group 2110"
                            transform="translate(3.3 3.3)"
                          >
                            <g
                              id="Group_2111"
                              data-name="Group 2111"
                              transform="translate(0 0)"
                            >
                              <path
                                id="Path_1920"
                                data-name="Path 1920"
                                d="M4.711,9.844H8.133A1.716,1.716,0,0,0,9.844,8.133V4.711A1.716,1.716,0,0,0,8.133,3H4.711A1.716,1.716,0,0,0,3,4.711V8.133A1.716,1.716,0,0,0,4.711,9.844Zm0-5.133H8.133V8.133H4.711Z"
                                transform="translate(-3 -3)"
                              />
                              <path
                                id="Path_1921"
                                data-name="Path 1921"
                                d="M4.711,19.844H8.133a1.716,1.716,0,0,0,1.711-1.711V14.711A1.716,1.716,0,0,0,8.133,13H4.711A1.716,1.716,0,0,0,3,14.711v3.422A1.716,1.716,0,0,0,4.711,19.844Zm0-5.133H8.133v3.422H4.711Z"
                                transform="translate(-3 -4.444)"
                              />
                              <path
                                id="Path_1922"
                                data-name="Path 1922"
                                d="M13,4.711V8.133a1.716,1.716,0,0,0,1.711,1.711h3.422a1.716,1.716,0,0,0,1.711-1.711V4.711A1.716,1.716,0,0,0,18.133,3H14.711A1.716,1.716,0,0,0,13,4.711Zm5.133,3.422H14.711V4.711h3.422Z"
                                transform="translate(-4.444 -3)"
                              />
                              <path
                                id="Path_1923"
                                data-name="Path 1923"
                                d="M20.711,20.283v-.856A.424.424,0,0,0,20.283,19h-.856a.424.424,0,0,0-.428.428v.856a.424.424,0,0,0,.428.428h.856A.424.424,0,0,0,20.711,20.283Z"
                                transform="translate(-5.311 -5.311)"
                              />
                              <path
                                id="Path_1924"
                                data-name="Path 1924"
                                d="M13,13.428v.856a.424.424,0,0,0,.428.428h.856a.424.424,0,0,0,.428-.428v-.856A.424.424,0,0,0,14.283,13h-.856A.424.424,0,0,0,13,13.428Z"
                                transform="translate(-4.444 -4.444)"
                              />
                              <path
                                id="Path_1925"
                                data-name="Path 1925"
                                d="M16.283,15h-.856a.424.424,0,0,0-.428.428v.856a.424.424,0,0,0,.428.428h.856a.424.424,0,0,0,.428-.428v-.856A.424.424,0,0,0,16.283,15Z"
                                transform="translate(-4.733 -4.733)"
                              />
                              <path
                                id="Path_1926"
                                data-name="Path 1926"
                                d="M13,17.428v.856a.424.424,0,0,0,.428.428h.856a.424.424,0,0,0,.428-.428v-.856A.424.424,0,0,0,14.283,17h-.856A.424.424,0,0,0,13,17.428Z"
                                transform="translate(-4.444 -5.022)"
                              />
                              <path
                                id="Path_1927"
                                data-name="Path 1927"
                                d="M15.428,20.711h.856a.424.424,0,0,0,.428-.428v-.856A.424.424,0,0,0,16.283,19h-.856a.424.424,0,0,0-.428.428v.856A.424.424,0,0,0,15.428,20.711Z"
                                transform="translate(-4.733 -5.311)"
                              />
                              <path
                                id="Path_1928"
                                data-name="Path 1928"
                                d="M17.428,18.711h.856a.424.424,0,0,0,.428-.428v-.856A.424.424,0,0,0,18.283,17h-.856a.424.424,0,0,0-.428.428v.856A.424.424,0,0,0,17.428,18.711Z"
                                transform="translate(-5.022 -5.022)"
                              />
                              <path
                                id="Path_1929"
                                data-name="Path 1929"
                                d="M18.283,13h-.856a.424.424,0,0,0-.428.428v.856a.424.424,0,0,0,.428.428h.856a.424.424,0,0,0,.428-.428v-.856A.424.424,0,0,0,18.283,13Z"
                                transform="translate(-5.022 -4.444)"
                              />
                              <path
                                id="Path_1930"
                                data-name="Path 1930"
                                d="M19.428,16.711h.856a.424.424,0,0,0,.428-.428v-.856A.424.424,0,0,0,20.283,15h-.856a.424.424,0,0,0-.428.428v.856A.424.424,0,0,0,19.428,16.711Z"
                                transform="translate(-5.311 -4.733)"
                              />
                            </g>
                          </g>
                        </g>
                        <rect
                          id="Rectangle_606"
                          data-name="Rectangle 606"
                          width="26"
                          height="26"
                          transform="translate(35 16)"
                          fill="none"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="app-tour__content">
                    <h3 className="app-tour__title text-white">QR Code</h3>
                    <p className="app-tour__desc text-white">
                      With Shoppers Stop pulsse, unlock your benefits by
                      scanning/tapping at the instore QR codes.
                    </p>
                  </div>
                </div>
                <div className="item app-tour__item bg-blue">
                  <div className="app-tour__icon">
                    <svg
                      id="discount_black_24dp"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g
                        id="Group_2086"
                        data-name="Group 2086"
                        transform="translate(0 0)"
                      >
                        <rect
                          id="Rectangle_588"
                          data-name="Rectangle 588"
                          width="24"
                          height="24"
                          fill="none"
                        />
                      </g>
                      <g
                        id="Group_2088"
                        data-name="Group 2088"
                        transform="translate(3 0)"
                      >
                        <g id="Group_2087" data-name="Group 2087">
                          <path
                            id="Path_1883"
                            data-name="Path 1883"
                            d="M12.79,21,3,11.21v2a1.966,1.966,0,0,0,.59,1.41l7.79,7.79a2,2,0,0,0,2.83,0l6.21-6.21a2,2,0,0,0,0-2.83Z"
                            transform="translate(-3 0)"
                          />
                          <path
                            id="Path_1884"
                            data-name="Path 1884"
                            d="M11.38,17.41a1.98,1.98,0,0,0,2.82,0l6.21-6.21a2,2,0,0,0,0-2.83L12.62.58A2,2,0,0,0,11.21,0H5A2.006,2.006,0,0,0,3,2V8.21a1.966,1.966,0,0,0,.59,1.41ZM5,2h6.21L19,9.79,12.79,16,5,8.21Z"
                            transform="translate(-3 0)"
                          />
                          <circle
                            id="Ellipse_47"
                            data-name="Ellipse 47"
                            cx="1.25"
                            cy="1.25"
                            r="1.25"
                            transform="translate(3 3)"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className="app-tour__content">
                    <h3 className="app-tour__title text-white">
                      My Wallet of Offers
                    </h3>
                    <p className="app-tour__desc text-white">
                      With Shoppers Stop pulsse, unlock your benefits by
                      scanning/tapping at the instore QR codes.
                    </p>
                  </div>
                </div>
                <div className="item app-tour__item bg-success">
                  <div className="app-tour__icon">
                    <svg
                      id="loyalty_black_24dp"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        id="Path_1885"
                        data-name="Path 1885"
                        d="M0,0H24V24H0Z"
                        fill="none"
                      />
                      <path
                        id="Path_1886"
                        data-name="Path 1886"
                        d="M21.41,11.58l-9-9A1.988,1.988,0,0,0,11,2H4A2.006,2.006,0,0,0,2,4v7a2,2,0,0,0,.59,1.42l9,9A1.987,1.987,0,0,0,13,22a1.955,1.955,0,0,0,1.41-.59l7-7A1.955,1.955,0,0,0,22,13,2.02,2.02,0,0,0,21.41,11.58ZM13,20.01,4,11V4h7V3.99l9,9Z"
                        transform="translate(0 0)"
                      />
                      <circle
                        id="Ellipse_48"
                        data-name="Ellipse 48"
                        cx="1.5"
                        cy="1.5"
                        r="1.5"
                        transform="translate(5 5)"
                      />
                      <path
                        id="Path_1887"
                        data-name="Path 1887"
                        d="M8.9,12.55A2.057,2.057,0,0,0,9.5,14L13,17.5,16.5,14a2.051,2.051,0,0,0-2.9-2.9l-.6.6-.6-.59a2.025,2.025,0,0,0-1.45-.61A2.053,2.053,0,0,0,8.9,12.55Z"
                        transform="translate(0)"
                      />
                    </svg>
                  </div>
                  <div className="app-tour__content">
                    <h3 className="app-tour__title text-white">
                      Loyalty Points
                    </h3>
                    <p className="app-tour__desc text-white">
                      With Shoppers Stop pulsse, unlock your benefits by
                      scanning/tapping at the instore QR codes.
                    </p>
                  </div>
                </div>
                <div className="item app-tour__item bg-purple">
                  <div className="app-tour__icon">
                    <svg
                      id="rocket_launch_black_24dp"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g id="Group_2089" data-name="Group 2089">
                        <rect
                          id="Rectangle_589"
                          data-name="Rectangle 589"
                          width="24"
                          height="24"
                          fill="none"
                        />
                        <rect
                          id="Rectangle_590"
                          data-name="Rectangle 590"
                          width="24"
                          height="24"
                          fill="none"
                        />
                      </g>
                      <g
                        id="Group_2091"
                        data-name="Group 2091"
                        transform="translate(2.222 2.479)"
                      >
                        <g id="Group_2090" data-name="Group 2090">
                          <path
                            id="Path_1888"
                            data-name="Path 1888"
                            d="M9.19,6.35a23.957,23.957,0,0,0-3.57,5.89l-2.26-.97a1,1,0,0,1-.31-1.63L6.06,6.63a2.013,2.013,0,0,1,1.81-.55l1.32.27Zm1.49,10.16a.978.978,0,0,0,1.12.2,19.992,19.992,0,0,0,5.26-3.42c4.59-4.59,4.63-8.33,4.36-9.93a.979.979,0,0,0-.79-.79c-1.6-.27-5.34-.23-9.93,4.36a20.252,20.252,0,0,0-3.42,5.26.991.991,0,0,0,.2,1.12Zm6.97-1.7a23.957,23.957,0,0,1-5.89,3.57l.97,2.26a1,1,0,0,0,1.63.31l3.01-3.01a2.013,2.013,0,0,0,.55-1.81l-.27-1.32Zm-8.71,2.6a3,3,0,0,1-.82,2.71c-.77.77-3.16,1.34-4.71,1.64a1,1,0,0,1-1.17-1.17c.3-1.55.86-3.94,1.64-4.71a3,3,0,0,1,5.06,1.53ZM13,9a2,2,0,1,1,2,2A2.006,2.006,0,0,1,13,9Z"
                            transform="translate(-2.222 -2.479)"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className="app-tour__content">
                    <h3 className="app-tour__title text-white">New Launches</h3>
                    <p className="app-tour__desc text-white">
                      With Shoppers Stop pulsse, unlock your benefits by
                      scanning/tapping at the instore QR codes.
                    </p>
                  </div>
                </div>
                <div className="item app-tour__item bg-danger last-item">
                  <div className="app-tour__icon">
                    <svg
                      id="campaign_black_24dp"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g id="Group_2085" data-name="Group 2085">
                        <rect
                          id="Rectangle_587"
                          data-name="Rectangle 587"
                          width="24"
                          height="24"
                          fill="none"
                        />
                      </g>
                      <g
                        id="Group_2114"
                        data-name="Group 2114"
                        transform="translate(0 2.875)"
                      >
                        <path
                          id="Path_1876"
                          data-name="Path 1876"
                          d="M18,12.2h0a1.2,1.2,0,0,0,1.2,1.2h2.4a1.2,1.2,0,0,0,1.2-1.2h0A1.2,1.2,0,0,0,21.6,11H19.2A1.2,1.2,0,0,0,18,12.2Z"
                          transform="translate(1.2 -3.069)"
                        />
                        <path
                          id="Path_1877"
                          data-name="Path 1877"
                          d="M16.63,16.905a1.159,1.159,0,0,0,.24,1.644c.636.468,1.308.972,1.944,1.452a1.173,1.173,0,0,0,1.656-.24c0-.012.012-.012.012-.024a1.173,1.173,0,0,0-.24-1.656c-.636-.48-1.308-.984-1.932-1.452a1.191,1.191,0,0,0-1.668.252A.042.042,0,0,1,16.63,16.905Z"
                          transform="translate(0.878 -1.99)"
                        />
                        <path
                          id="Path_1878"
                          data-name="Path 1878"
                          d="M20.491,4.894c0-.012-.012-.012-.012-.024a1.176,1.176,0,0,0-1.656-.24c-.636.48-1.32.984-1.944,1.464a1.174,1.174,0,0,0-.228,1.656c0,.012.012.012.012.024a1.175,1.175,0,0,0,1.656.24c.636-.468,1.308-.984,1.944-1.464A1.193,1.193,0,0,0,20.491,4.894Z"
                          transform="translate(0.881 -4.391)"
                        />
                        <path
                          id="Path_1879"
                          data-name="Path 1879"
                          d="M9.2,9.6H4.4A2.407,2.407,0,0,0,2,12v2.4a2.407,2.407,0,0,0,2.4,2.4H5.6v3.6a1.2,1.2,0,0,0,1.2,1.2h0A1.2,1.2,0,0,0,8,20.4V16.8H9.2l6,3.6V6Z"
                          transform="translate(-2 -4.069)"
                        />
                        <path
                          id="Path_1880"
                          data-name="Path 1880"
                          d="M15.8,12.67A5.376,5.376,0,0,0,14,8.65v8.028A5.331,5.331,0,0,0,15.8,12.67Z"
                          transform="translate(0.4 -3.539)"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="app-tour__content">
                    <h3 className="app-tour__title text-white">
                      Brand Promotion
                    </h3>
                    <p className="app-tour__desc text-white">
                      With Shoppers Stop pulsse, unlock your benefits by
                      scanning/tapping at the instore QR codes.
                    </p>
                  </div>
                </div> */}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </>
    );
  }
}
