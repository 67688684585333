import React from "react";
import "materialize-css/dist/css/materialize.min.css";
import Header from "../components/layout/header";
import { Container } from "react-materialize";
import LoyaltyRewardCard from "../components/loyalty-points-card";

export default function Notifications() {
  return (
    <div className="screen-wrap">
      {/* Header Section */}
      <Header
        className="app-header"
        headertype={0}
        logoShow={1}
        redirectLink={'/home'}
      />

      <div className="main-wrap has-no-footer">
        <Container className="w-100 h-100">
          <div className="heading-section">
            <h2 className="heading-title">Notifications</h2>
          </div>
          <LoyaltyRewardCard
            LoyaltyPointsIcon="icon-card__tour"
            LoyaltyPointsTitle="You earned 54 points"
            LoyaltyPointsText="For visiting the “Take a Tour” section"
            LoyaltyPoints="+54"
          />
          <LoyaltyRewardCard
            LoyaltyPointsIcon="icon-card__parking"
            LoyaltyPointsTitle="You earned 150 points"
            LoyaltyPointsText="As your Parking Benefit"
            LoyaltyPoints="+150"
          />
          <hr className="my-20 border-dashed" />
        </Container>
      </div>
    </div>
  );
}
