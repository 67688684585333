import React from "react";
import "materialize-css/dist/css/materialize.min.css";

export default function LoyaltyRewardCard(props) {
  return (
    <div className="loyalty-points-card">
      <div className="icon-box">
        <div
          className={`loyalty-points__icon ${props.LoyaltyPointsIcon}`}
        ></div>
      </div>
      <div className="card-stacked">
        <div className="card-content">
          <h4 className="card-title">{props.LoyaltyPointsTitle}</h4>
          <p className="card-text">{props.LoyaltyPointsText}</p>
        </div>
        <div className="loyalty-points__wrapper">
          <span className="loyalty-points">{props.LoyaltyPoints}</span>
        </div>
      </div>
    </div>
  );
}
