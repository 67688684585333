import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "materialize-css/dist/css/materialize.min.css";
import Header from "../components/layout/header.js";
import Footer from "../components/layout/footer.js";
import IphoneMockup from "../assets/img/iphone-mockup.png";
import AndroidMockup from "../assets/img/android-mockup.png";
import AppStoreBadge from "../assets/img/app-store-badge.svg";
import GooglePlayBadge from "../assets/img/google-play-badge.svg";
import { Row, Col } from "react-materialize";

export default function MobileApp() {
  const navigate = useNavigate();
  useEffect(() => {
    const headers = {
      AuthToken: localStorage.getItem("auth_key"),
      Otp: localStorage.getItem("otp")
    }
    if (headers.AuthToken) {
      console.log("enter")
    }
    else {
      navigate("/landing-page")
    }
  });

  return (
    <div className="screen-wrap">
      {/* Header Section */}
      <Header className="app-header" headertype={1} />
      {/* Main Content */}
      <div className="main-wrap">
        <div className="d-table w-100 h-100">
          <div className="d-table-cell align-middle w-100 h-100">
            <div className="mobile-appstore-container">
              <div className="heading-section text-center p-0">
                <h2 className="heading-title mb-5">Mobile App 📲</h2>
                <p className="heading-desc">
                  Get the app from your favourite store.
                </p>
              </div>
              <div className="mobile-appstore-grid">
                <Row>
                  <Col s={6}>
                    <div className="mobile-appstore-item">
                      <img
                        className="mobile-mockup"
                        src={IphoneMockup}
                        alt="iphone-mockup"
                      />
                      <h6 className="mobile-appstore-title">IOS App</h6>
                      <a target="_blank" href="https://apps.apple.com/in/app/shoppers-stop-fashion-shopping/id1047171444" className="mobile-appstore-btn waves-effect waves-light waves-ripple">
                        <img src={AppStoreBadge} alt="app store badge" />
                      </a>
                    </div>
                  </Col>
                  <Col s={6}>
                    <div className="mobile-appstore-item">
                      <img
                        className="mobile-mockup"
                        src={AndroidMockup}
                        alt="android-mockup"
                      />
                      <h6 className="mobile-appstore-title">Android App</h6>
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=shoppersstop.shoppersstop&hl=en_IN&gl=US" className="mobile-appstore-btn waves-effect waves-light waves-ripple">
                        <img src={GooglePlayBadge} alt="google play badge" />
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Section */}
      <Footer />
    </div>
  );
}
