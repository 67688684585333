import React, { useState, useEffect } from "react";
import "materialize-css/dist/css/materialize.min.css";
import Header from "../components/layout/header.js";
import {
  Container,
  Row,
  Col,
  TextInput,
  Button,
} from "react-materialize";
// import UserProfileCard from "../components/user-profile-card.js";
import IconProfileCheck from "../assets/img/icons/icon_profile-check.svg";
// import { useNavigate } from "react-router-dom";
import CenteredMsg from "../components/centered-msg.js";
import $ from "jquery";
import { authAxios } from "../config/constant";
import Error from "../components/error";
import { useNavigate } from "react-router-dom";
import { upload } from "@testing-library/user-event/dist/upload.js";


export default function ProfileUpdate() {
  const [child2Modal, setChild2Modal] = useState(false);
  const [child2Button, setChild2Button] = useState(true);
  const [mobileNumber] = useState(localStorage.getItem("mobile_no"));
  // const [UpdateFirstName] = useState(localStorage.getItem("UpdateFirstName"));
  // const [UpdateLastName] = useState(localStorage.getItem("UpdateLastName"));
  // const [UpdateEmail] = useState(localStorage.getItem("UpdateEmail"));
  // const [UpdateMemberSince] = useState(localStorage.getItem("UpdateMemberSince"));
  // const [memberId] = useState(localStorage.getItem("memberId"));
  const [update] = useState(JSON.parse(localStorage.getItem("update")));
  const [gender, setGender] = useState(update.gender);
  const [anniversary, setAnniversary] = useState("");
  const [incomeslab, setIncomeSlab] = useState("");
  const [spousename, setSpouseName] = useState("");
  const [spousedob, setSpouseDob] = useState("");
  const [noofchild, setNoOfChild] = useState("");
  const [child1name, setChild1Name] = useState("");
  const [child1dob, setChild1Dob] = useState("");
  const [child2name, setChild2Name] = useState("");
  const [child2dob, setChild2Dob] = useState("");
  // const [hasError, setHasError] = useState(false);
  const [genderError, setGenderError] = useState("");
  const [centeredMessage, setCenteredMessage] = useState(false);
  const [containerBody, setContainerBody] = useState(true);
  const [addChildEnable, setAddChildEnable] = useState(true);
  const [loaderPopup, setLoaderPopup] = useState(false);
  const navigate = useNavigate();
  const [childOneError, setChildOneError] = useState("");
  const [childTwoError, setChildTwoError] = useState("");

  function UpdateProfileRedirect() {


    if (!gender) {
      setGenderError("Please Select a gender");
    }
    else if(!childOneError && !childTwoError){
      const payload = {
        // memberId: memberId,
        gender: gender,
        UpdateFirstName: update.UpdateFirstName,
        UpdateLastName: update.UpdateLastName,
        UpdateEmail: update?.UpdateEmail ? update.UpdateEmail : localStorage.getItem("UpdateEmail"),
        UpdateMemberSince: update.UpdateMemberSince,
        anniversary: anniversary,
        incomeslab: incomeslab,
        spousename: spousename,
        spousedob: spousedob,
        noofchild: noofchild,
        child1name: child1name,
        child1dob: child1dob,
        child2name: child2name,
        child2dob: child2dob
      };
      const headers = {
        AuthToken: localStorage.getItem("auth_key"),
        Otp: localStorage.getItem("otp")
      }
      if (headers.AuthToken) {
        setLoaderPopup(true);
        authAxios
          .post("/update_profile", payload, { headers: headers })
          .then((res) => {
            // debugger;
            console.log(res.data);
            if (res.data.code == 403) {
              localStorage.clear();
              navigate("/landing-page");
            }
            else {
              if (res.data.demographicSaveResponse_v1.errorMsg == "null") {
                // debugger;
                localStorage.removeItem("update");
                localStorage.removeItem("UpdateEmail");
                setCenteredMessage(true);
                setLoaderPopup(false);
                setContainerBody(false);
              }
              // else{

              // }
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
      else {
        navigate("/landing-page")
      }
    }
    // }, []);
  }
  useEffect(() => {
    if (update.gender === "M") {
      setGender(1)
    }
    else if (update.gender === "F") {
      setGender(2)
    }
  }, [])
  useEffect(() => {
    if (child1name && child1dob) {
      setAddChildEnable(false)
    } else {
      setAddChildEnable(true)
    }
  }, [child1name, child1dob])

  const handleGender = (e) => {
    setGender(e.target.value);
    if (e.target.value) {
      setGenderError("")
    }
  }
  const nameValidation = (name) => {
    var regName = /^[a-zA-Z ]+$/;
    if ((name).match(regName) || name === '') {
      return true;
    }
    else {
      return false;
    }
  }
  const handleAnniversary = (e) => {
    setAnniversary(e.target.value);
  }
  const handleIncomeSlab = (e) => {
    setIncomeSlab(e.target.value);
  }
  const handleSpouseName = (e) => {
    if (nameValidation(e.target.value)) {
      setSpouseName(e.target.value);
    }
  }
  const handleSpouseDob = (e) => {
    const currentDate = new Date()
    const date = new Date(e.target.value);
    if (currentDate > date) {
      setSpouseDob(e.target.value);
    }
  }
  const handleNoOfChild = (e) => {
    setNoOfChild(e.target.value);
  }
  const handleChild1Name = (e) => {
    if (nameValidation(e.target.value)) {
      setChild1Name(e.target.value);
    }
    if(e.target.value){
      setChildOneError("")
    }
    if(!e.target.value && child1dob){
      setChildOneError("Please fill the name");
    }
  }
  const handleChild1Dob = (e) => {
    const currentDate = new Date()
    const date = new Date(e.target.value);
    if (currentDate > date) {
      setChild1Dob(e.target.value);
    }
    if(!child1name){
      setChildOneError("Please fill the name");
    }
  }
  const handleChild2Name = (e) => {
    if (nameValidation(e.target.value)) {
      setChild2Name(e.target.value);
    }
    if(e.target.value){
      setChildTwoError("")
    }
    if(!e.target.value && child2dob){
      setChildTwoError("Please fill the name");
    }
  }
  const handleChild2Dob = (e) => {
    const currentDate = new Date()
    const date = new Date(e.target.value);
    if (currentDate > date) {
      setChild2Dob(e.target.value);
    }
    if(!child2name){
      setChildTwoError("Please fill the name");
    }
  }

  $("#div_child2").addClass("hide")

  return (
    <div className="screen-wrap">
      {/* Header Section */}
      <Header className="app-header has-border"
        headertype={0}
        logoShow={1}
        redirectLink={'/home'} />
      {/* Main Content */}
      <div className="main-wrap has-no-footer profile-page">
        {containerBody && <Container>
          <div className="profile-header --is-sticky">
            <div className="profile-header__title">
              <i>
                <img src={IconProfileCheck} alt="" />
              </i> Update Profile
            </div>
          </div>
          <Row>
            <Col s={12}>
              <div className="form-group">
                <label for="gender">
                  Gender<span>*</span>
                </label>
                <select
                  browserDefault
                  id="gender"
                  placeholder="Select"
                  options={{
                    classes: "",
                    dropdownOptions: {
                      alignment: "left",
                      autoTrigger: true,
                      closeOnClick: true,
                      constrainWidth: true,
                      coverTrigger: true,
                      hover: false,
                      inDuration: 150,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      outDuration: 250,
                    },
                  }}
                  value={gender}
                  onChange={handleGender}
                >
                  <option disabled value="">
                    Select
                  </option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                </select>
                <Error>{genderError}</Error>
              </div>
              {/* <div className="form-group">
                <label for="email">
                  Email ID
                </label>
                <TextInput
                  id="email"
                  className="browser-default"
                  placeholder="Enter your email id"
                  value={email}
                  onChange={handleEmail}
                />
              </div> */}
            </Col>
            <Col s={6}>
              <div className="form-group">
                <label for="lastname">
                  Anniversary
                </label>
                <TextInput
                  type="date"
                  id="firstname"
                  className="browser-default"
                  // defaultValue=""
                  value={anniversary}
                  onChange={handleAnniversary}
                />
                {/* <Select
                  browserDefault
                  id="gender"
                  placeholder="Select"
                  options={{
                    classes: "",
                    dropdownOptions: {
                      alignment: "left",
                      autoTrigger: true,
                      closeOnClick: true,
                      constrainWidth: true,
                      coverTrigger: true,
                      hover: false,
                      inDuration: 150,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      outDuration: 250,
                    },
                  }}
                  value=""
                >
                  <option disabled value="">
                    Select
                  </option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                </Select> */}
              </div>
            </Col>
            <Col s={6}>
              <div className="form-group">
                <label for="lastname">
                  Income Slab
                </label>
                <select
                  browserDefault
                  id="gender"
                  placeholder="Select"
                  options={{
                    classes: "",
                    dropdownOptions: {
                      alignment: "left",
                      autoTrigger: true,
                      closeOnClick: true,
                      constrainWidth: true,
                      coverTrigger: true,
                      hover: false,
                      inDuration: 150,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      outDuration: 250,
                    },
                  }}
                  value={incomeslab}
                  onChange={handleIncomeSlab}
                >
                  <option disabled value="">
                    Annual Income
                  </option>
                  <option value="1">Below 10L</option>
                  <option value="2">10-30L</option>
                  <option value="3">30-50L</option>
                  <option value="4">50-80L</option>
                  <option value="5">Above 80L</option>
                </select>
              </div>
            </Col>
            <Col s={12}>
              <div className="form-group">
                <label for="firstname">
                  Spouse’s Name
                </label>
                <TextInput
                  id="firstname"
                  className="browser-default"
                  placeholder="Enter Spouse’s Name"
                  value={spousename}
                  onChange={handleSpouseName}
                />
              </div>
            </Col>
            <Col s={6}>
              <div className="form-group">
                <label for="lastname">
                  Spouse’s Birthday
                </label>
                <TextInput
                  type="date"
                  id="firstname"
                  className="browser-default"
                  defaultValue=""
                  value={spousedob}
                  onChange={handleSpouseDob}
                />
                {/* <Select
                  browserDefault
                  id="gender"
                  placeholder="Select"
                  options={{
                    classes: "",
                    dropdownOptions: {
                      alignment: "left",
                      autoTrigger: true,
                      closeOnClick: true,
                      constrainWidth: true,
                      coverTrigger: true,
                      hover: false,
                      inDuration: 150,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      outDuration: 250,
                    },
                  }}
                  value=""
                >
                  <option disabled value="">
                    Select
                  </option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                </Select> */}
              </div>
            </Col>
            <Col s={6}>
              <div className="form-group">
                <label for="lastname">
                  No of children
                </label>
                <select
                  browserDefault
                  id="gender"
                  placeholder="Select"
                  options={{
                    classes: "",
                    dropdownOptions: {
                      alignment: "left",
                      autoTrigger: true,
                      closeOnClick: true,
                      constrainWidth: true,
                      coverTrigger: true,
                      hover: false,
                      inDuration: 150,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      outDuration: 250,
                    },
                  }}
                  value={noofchild}
                  onChange={handleNoOfChild}
                >
                  <option disabled value="">
                    Select
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select>
              </div>
            </Col>
            <div className="form-group">
              <Col s={12}>
                <div className="form-group">
                  <label for="firstname">
                    Child 1 Name
                  </label>
                  <TextInput
                    id="firstname"
                    className="browser-default"
                    placeholder="Enter Child 1 Name"
                    value={child1name}
                    onChange={handleChild1Name}
                  />
                  <Error>{childOneError}</Error>
                </div>
              </Col>
              <Col s={6}>
                <div className="form-group">
                  <label for="lastname">
                    Child 1 Birthday
                  </label>
                  <TextInput
                    type="date"
                    id="firstname"
                    className="browser-default"
                    defaultValue=""
                    value={child1dob}
                    onChange={handleChild1Dob}
                  />
                  {/* <Select
                    browserDefault
                    id="gender"
                    placeholder="Select"
                    options={{
                      classes: "",
                      dropdownOptions: {
                        alignment: "left",
                        autoTrigger: true,
                        closeOnClick: true,
                        constrainWidth: true,
                        coverTrigger: true,
                        hover: false,
                        inDuration: 150,
                        onCloseEnd: null,
                        onCloseStart: null,
                        onOpenEnd: null,
                        onOpenStart: null,
                        outDuration: 250,
                      },
                    }}
                    value=""
                  >
                    <option disabled value="">
                      Select
                    </option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                  </Select> */}
                </div>
              </Col>
            </div>
            {child2Modal && <div className="form-group">
              <Col s={12}>
                <div className="form-group">
                  <label for="firstname">
                    Child 2 Name
                  </label>
                  <TextInput
                    id="firstname"
                    className="browser-default"
                    placeholder="Enter Child 2 Name"
                    value={child2name}
                    onChange={handleChild2Name}
                  />
                  <Error>{childTwoError}</Error>
                </div>
              </Col>
              <Col s={6}>
                <div className="form-group">
                  <label for="lastname">
                    Child 2 Birthday
                  </label>
                  <TextInput
                    type="date"
                    id="firstname"
                    className="browser-default"
                    defaultValue=""
                    value={child2dob}
                    onChange={handleChild2Dob}
                  />
                  {/* <Select
                    browserDefault
                    id="gender"
                    placeholder="Select"
                    options={{
                      classes: "",
                      dropdownOptions: {
                        alignment: "left",
                        autoTrigger: true,
                        closeOnClick: true,
                        constrainWidth: true,
                        coverTrigger: true,
                        hover: false,
                        inDuration: 150,
                        onCloseEnd: null,
                        onCloseStart: null,
                        onOpenEnd: null,
                        onOpenStart: null,
                        outDuration: 250,
                      },
                    }}
                    value=""
                  >
                    <option disabled value="">
                      Select
                    </option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                  </Select> */}
                </div>
              </Col>
            </div>}
          </Row>
          {/* Success Message */}

          {child2Button && <Button node="button" className="btn-primary btn-fluid fw-500 mb-20 text-transform-none justify-content-between"
            disabled={addChildEnable}
            onClick={() => {
              setChild2Modal(true);
              setChild2Button(false)
            }}>
            Add Another Child’s Details <i className="icon-add-circle ml-10"></i>
          </Button>}
          <Button node="button" className="btn-primary btn-fluid mb-20" onClick={UpdateProfileRedirect}>
            Update
            {/* <i className="icon-arrow-right ml-10"></i> */}
          </Button>
          <div className="user-guide">
            <ul>
              <li>Fields Marked In * Are Mandatory</li>
              <li>Fields <strong>Highlighted</strong> Cannot Be Edited</li>
              <li>Fields In Update Profile Box Can Be Editable</li>
              <li>Fields In Profile Box To Be Displayed</li>
            </ul>
          </div>
        </Container>}
        {centeredMessage && <CenteredMsg />}

        {loaderPopup ? (<div id="loading" class="loadme-overlay">
          <div class="loadme-circular"></div>
          <p class="loadme-text" id="st"></p>
        </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
