import React, { useEffect } from "react";
import Header from "../components/layout/header";
import { Row, Col, Container } from "react-materialize";
import LogoO2Spa from "../assets/img/partner-brand_o2spa.png";
// import LogoShoppersstop from "../assets/img/partner-brand_shoppersstop.png";
import LogoMakeMyTrip from "../assets/img/partner-brand_makemytrip.png";
import LogoZomato from "../assets/img/partner-brand_zomato.png";
// import LogoOberoi from "../assets/img/partner-brand_oberoi.png";
import LogoMarriott from "../assets/img/partner-barnd_marriott.png";
import LogoBookMyShow from "../assets/img/partner-brand_bookmyshow.png";
// import LogoPvrCinemas from "../assets/img/partner-brand_pvr.png";
import LogoTaj from "../assets/img/partner-brand_taj.png";
import LogoUber from "../assets/img/partner-brand_uber.png";
import { useNavigate } from "react-router-dom";

export default function BuyPartnerBrands() {
    const navigate = useNavigate();
    const openSpaPage = () => {
        localStorage.setItem("selectedPartners", LogoO2Spa);
        localStorage.setItem('selectedPartnerText', 'o2spa')
        localStorage.setItem('selectedBrand', 'CNPIN')
        navigate("/redeem-your-points")
    }
    const openTaj = () => {
        localStorage.setItem("selectedPartners", LogoTaj);
        localStorage.setItem('selectedPartnerText', 'taj')
        localStorage.setItem('selectedBrand', 'VOUCHERCODE')
        navigate("/redeem-your-points")
    }
    const openMakeMyTrip = () => {
        localStorage.setItem("selectedPartners", LogoMakeMyTrip);
        localStorage.setItem('selectedPartnerText', 'makemytrip')
        localStorage.setItem('selectedBrand', 'UBEFLOW')
        navigate("/redeem-your-points")
    }
    const openZomato = () => {
        localStorage.setItem("selectedPartners", LogoZomato);
        localStorage.setItem('selectedPartnerText', 'zomato')
        localStorage.setItem('selectedBrand', 'CLAIMCODE')
        navigate("/redeem-your-points")
    }
    // const openOberoi = () => {
    //     localStorage.setItem("selectedPartners", LogoOberoi);
    //     localStorage.setItem('selectedPartnerText', 'Oberoi')
    //     localStorage.setItem('selectedBrand', 'PROCESSINGSTS')
    //     navigate("/redeem-your-points")
    // }
    const openMarriott = () => {
        localStorage.setItem("selectedPartners", LogoMarriott);
        localStorage.setItem('selectedPartnerText', 'marriott')
        localStorage.setItem('selectedBrand', 'DISABLEDSTS')
        navigate("/redeem-your-points")
    }
    const openBookMyShow = () => {
        localStorage.setItem("selectedPartners", LogoBookMyShow);
        localStorage.setItem('selectedPartnerText', 'bookmyshow')
        localStorage.setItem('selectedBrand', 'testsuccess001')
        navigate("/redeem-your-points")
    }
    const openUber = () => {
        localStorage.setItem("selectedPartners", LogoUber);
        localStorage.setItem('selectedPartnerText', 'uber')
        localStorage.setItem('selectedBrand', 'APITESTTIMFAIL')
        navigate("/redeem-your-points")
    }

    useEffect(() => {
        const headers = {
            AuthToken: localStorage.getItem("auth_key"),
            Otp: localStorage.getItem("otp")
        }
        if (headers.AuthToken) {
            console.log("enter")
        }
        else {
            navigate("/landing-page")
        }
    });
    return (
        <div className="screen-wrap">
            {/* Header Section */}
            <Header
                className="app-header has-border"
                headertype={0}
                logoShow={1}
                redirectLink={'/home'}
            />
            <div className="main-wrap has-no-footer">
                <Container>
                    <div className="text-center my-30">
                        <h4 className="section-title text-center text-uppercase p-0">Buy Partner Brands</h4>
                    </div>
                    <div className="partner-brands-grid">
                        <Row>
                            <Col s={6} onClick={() => openSpaPage()}>
                                <div className="partner-brand-card waves-effect waves-light-primary d-block">
                                    <div className="card-body">
                                        <img src={LogoO2Spa} alt="" />
                                    </div>
                                </div>
                            </Col>
                            <Col s={6} onClick={() => openTaj()}>
                                <div className="partner-brand-card waves-effect waves-light-primary d-block">
                                    <div className="card-body">
                                        <img src={LogoTaj} alt="" />
                                    </div>
                                </div>
                            </Col>
                            <Col s={6} onClick={() => openMakeMyTrip()}>
                                <div className="partner-brand-card waves-effect waves-light-primary d-block">
                                    <div className="card-body">
                                        <img src={LogoMakeMyTrip} alt="" />
                                    </div>
                                </div>
                            </Col>
                            <Col s={6} onClick={() => openZomato()}>
                                <div className="partner-brand-card waves-effect waves-light-primary d-block">
                                    <div className="card-body">
                                        <img src={LogoZomato} alt="" />
                                    </div>
                                </div>
                            </Col>
                            {/* <Col s={6} onClick={() => openOberoi()}>
                                <div className="partner-brand-card waves-effect waves-light-primary d-block">
                                    <div className="card-body">
                                        <img src={LogoOberoi} alt="" />
                                    </div>
                                </div>
                            </Col> */}
                            <Col s={6} onClick={() => openMarriott()}>
                                <div className="partner-brand-card waves-effect waves-light-primary d-block">
                                    <div className="card-body">
                                        <img src={LogoMarriott} alt="" />
                                    </div>
                                </div>
                            </Col>
                            <Col s={6} onClick={() => openBookMyShow()}>
                                <div className="partner-brand-card waves-effect waves-light-primary d-block">
                                    <div className="card-body">
                                        <img src={LogoBookMyShow} alt="" />
                                    </div>
                                </div>
                            </Col>
                            <Col s={6} onClick={() => openUber()}>
                                <div className="partner-brand-card waves-effect waves-light-primary d-block">
                                    <div className="card-body">
                                        <img src={LogoUber} alt="" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </div>
    )
}